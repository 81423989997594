/**
 * Created by vladislav on 4/2/2024
 */

var AdsManager = function (options) {
    this.manualPermissions = options.manualPermissions;

    this._isStickyPlaying = false;
    this._isStickyEnabled = false;

    this.isAdBlockEnabled = false;

    connector.platform.on("pause", this._updateRefreshInterval.bind(this));
    connector.platform.on("resume", this._updateRefreshInterval.bind(this));

    connector.platform.on("pause", this._updateCacheInterval.bind(this));
    connector.platform.on("resume", this._updateCacheInterval.bind(this));

    connector.ads.on("changeStatus", function () {
        if (connector.ads.isConnected()) {
            this.checkCacheQueue();
            this.checkAdBlock();
        }

        this._updateCacheInterval();

        if (this._isStickyEnabled) {
            this._refreshStickyInner();
        }
    }.bind(this));

    connector._countryManager.on("changed", function () {
        this.whenIdle(function () {
            connector.ads._refreshPlugin();
        });
    }.bind(this));

    connector.ads.on("pluginChanged", function () {
        this._isStickyPlaying = false;
    }.bind(this));
};

AdsManager.prototype.getAdStatus = function (type) {
    if (type === AdsPlugin.REWARDED) {
        return this.getRewardedStatus();
    }

    if (type === AdsPlugin.INTERSTITIAL) {
        return this.getInterstitialStatus();
    }

    if (type === AdsPlugin.STICKY) {
        return this.getStickyStatus();
    }
};

AdsManager.prototype.getRewardedStatus = function () {
    var plugin = connector.ads.getPlugin();

    if (!plugin.isConnected()) {
        return ERRORS.NOT_CONNECTED;
    }

    if (!plugin.isRewardedSupported()) {
        return ERRORS.SHOW_REWARDED.NOT_SUPPORTED;
    }

    if (!plugin.isAdCached(AdsPlugin.REWARDED)) {
        return ERRORS.SHOW_REWARDED.NO_ADS;
    }

    if (!plugin.isRewardedConfigured()) {
        return ERRORS.SHOW_REWARDED.NOT_CONFIGURED;
    }

    return connector.CODE_SUCCEED;
};

AdsManager.prototype.getInterstitialStatus = function () {
    var plugin = connector.ads.getPlugin();

    if (!plugin.isConnected()) {
        return ERRORS.NOT_CONNECTED;
    }

    if (!plugin.isInterstitialSupported()) {
        return ERRORS.SHOW_INTERSTITIAL.NOT_SUPPORTED;
    }

    if (!plugin.isAdCached(AdsPlugin.INTERSTITIAL)) {
        return ERRORS.SHOW_INTERSTITIAL.NO_ADS;
    }

    if (!plugin.isInterstitialConfigured()) {
        return ERRORS.SHOW_INTERSTITIAL.NOT_CONFIGURED;
    }

    return connector.CODE_SUCCEED;
};

AdsManager.prototype.getStickyStatus = function () {
    var plugin = connector.ads.getPlugin();

    if (!plugin.isConnected()) {
        return ERRORS.NOT_CONNECTED;
    }

    if (!plugin.isStickySupported()) {
        return ERRORS.SHOW_STICKY.NOT_SUPPORTED;
    }

    if (!plugin.isStickyConfigured()) {
        return ERRORS.SHOW_STICKY.NOT_CONFIGURED;
    }

    if (!plugin.isStickyAvailable()) {
        return ERRORS.SHOW_STICKY.NO_STICKY;
    }

    return connector.CODE_SUCCEED;
};

AdsManager.prototype.showRewarded = function (callback) {
    this.playAd(AdsPlugin.REWARDED, function (code) {
        if (code && code !== ERRORS.SHOW_REWARDED.CLOSED_BY_USER) {
            connector.ads.getPlugin().bus.trigger("error:showRewarded", code);
        }

        callback(code);
    });
};

AdsManager.prototype.showInterstitial = function (callback) {
    this.playAd(AdsPlugin.INTERSTITIAL, function (code) {
        if (code && code !== ERRORS.SHOW_INTERSTITIAL.CLOSED_BY_USER) {
            connector.ads.getPlugin().bus.trigger("error:showInterstitial", code);
        }

        callback(code);
    });
};

AdsManager.prototype.isStickyPlaying = function () {
    return this._isStickyPlaying;
};

AdsManager.prototype.enableSticky = function (callback) {
    if (this._isStickyEnabled) {
        callback();

        return;
    }

    this._isStickyEnabled = true;
    this._updateRefreshInterval();

    this.showSticky(callback);
};

AdsManager.prototype.disableSticky = function (callback) {
    if (!this._isStickyEnabled) {
        callback();

        return;
    }

    this._isStickyEnabled = false;
    this._updateRefreshInterval();

    this.closeSticky(callback);
};

AdsManager.prototype.showSticky = function (callback) {
    var plugin = connector.ads.getPlugin();

    var onError = function (code) {
        plugin.bus.trigger("error:showSticky", code);

        callback(code);
    };

    var code = this.getAdStatus(AdsPlugin.STICKY);
    if (code) {
        onError(code);

        return;
    }

    plugin.showSticky(function (code, height) {
        if (code) {
            onError(code);

            return;
        }

        this._isStickyPlaying = true;

        plugin.bus.trigger("sticky:start", height);

        callback(code, height);
    }.bind(this));
};

AdsManager.prototype._refreshStickyInner = function (callback) {
    if (this._refreshingSticky) {
        callback && callback(connector.CODE_FAILED);

        return;
    }

    this._refreshingSticky = true;

    var plugin = connector.ads.getPlugin();

    var onDone = function (code) {
        this._refreshingSticky = false;

        if (!this._isStickyEnabled) {
            callback && callback(code);

            this.closeSticky();
            return;
        }

        if (code) {
            plugin.bus.trigger("error:refreshSticky", code);
        }

        plugin.bus.trigger("sticky:refresh");

        callback && callback(code);
    }.bind(this);

    if (this._isStickyPlaying && !connector.ads.isStickyAvailable) {
        this.closeSticky(onDone);
        return;
    }

    if (!this._isStickyPlaying && connector.ads.isStickyAvailable) {
        this.showSticky(onDone);
        return;
    }

    if (plugin.refreshSticky) {
        plugin.refreshSticky(function (code) {
            if (code) {
                this.closeSticky();
            }

            onDone(connector.CODE_SUCCEED);
        }.bind(this));
        return;
    }

    onDone(connector.CODE_SUCCEED);
};

AdsManager.prototype._updateRefreshInterval = function () {
    if (!this._isStickyEnabled || connector.platform.isPaused) {
        if (this._refreshInterval !== undefined) {
            clearInterval(this._refreshInterval);
            this._refreshInterval = undefined;
        }
    } else if (this._refreshInterval === undefined) {
        this._refreshInterval = setInterval(this._refreshStickyInner.bind(this), AdsManager.REFRESH_STICKY_INTERVAL);
    }
};

AdsManager.prototype._updateCacheInterval = function () {
    if (!connector.ads.isConnected() || connector.platform.isPaused) {
        if (this.cacheInterval !== undefined) {
            clearInterval(this.cacheInterval);
            this.cacheInterval = undefined;
        }
    } else if (this.cacheInterval === undefined) {
        this.cacheInterval = setInterval(this.checkCacheQueue.bind(this), AdsPlugin.CHECK_CACHE_INTERVAL);
    }
};

AdsManager.prototype.refreshSticky = function (callback) {
    this._refreshStickyInner(callback);
};

AdsManager.prototype.closeSticky = function (callback) {
    var plugin = connector.ads.getPlugin();

    if (!this._isStickyPlaying) {
        callback && callback(connector.CODE_SUCCEED);
        return;
    }

    var onError = function (code) {
        plugin.bus.trigger("error:closeSticky", code);

        callback && callback(code);
    };

    plugin.closeSticky(function (code) {
        if (code) {
            onError(code);

            return;
        }

        this._isStickyPlaying = false;

        plugin.bus.trigger("sticky:close");

        callback && callback(code);
    }.bind(this));
};

AdsManager.prototype.onStickyRestored = function (bannerHeight) {
    this._isStickyPlaying = true;

    var plugin = connector.ads.getPlugin();

    plugin.bus.trigger("sticky:start", bannerHeight);
};

AdsManager.prototype.onStickyClosedByUser = function () {
    this._isStickyPlaying = false;

    var plugin = connector.ads.getPlugin();

    plugin.bus.trigger("sticky:close");
};

AdsManager.prototype.isAdSupported = function (type) {
    if (type === AdsPlugin.REWARDED) {
        return connector.ads.isRewardedSupported;
    }

    if (type === AdsPlugin.INTERSTITIAL) {
        return connector.ads.isInterstitialSupported;
    }

    if (type === AdsPlugin.STICKY) {
        return connector.ads.isStickySupported;
    }
};

AdsManager.prototype.checkAdBlock = function (callback) {
    var plugin = connector.ads.getPlugin();

    var onDone = function (enabled) {
        this.isAdBlockEnabled = enabled;

        callback && callback(enabled);
    }.bind(this);

    if (connector.info.isNative || connector.info.isInApp) {
        onDone(false);
        return;
    }

    if (plugin.checkAdBlock) {
        if (plugin.isConnected()) {
            plugin.checkAdBlock(onDone);
        } else {
            onDone(false);
        }

        return;
    }

    var classList = ["adsbox", "advertisement", "pub_300x250", "pub_300x250m", "pub_728x90", "text-ad", "textAd", "text_ad", "text_ads", "text-ads", "text-ad-links"];

    var div = document.createElement("div");
    div.innerHTML = "&nbsp;";
    div.className = classList.join(" ");
    div.id = "text_ad";
    div.style.padding = "1px 0 0 0";
    document.body.appendChild(div);
    setTimeout(function () {
        onDone(div.offsetHeight === 0);

        if (div.parentElement) {
            div.parentElement.removeChild(div);
        }
    }, 10);
};

AdsManager.prototype.whenIdle = function (callback) {
    var plugin = connector.ads.getPlugin();

    if (!plugin) {
        callback();
        return;
    }

    if (plugin.isIdle()) {
        callback();
    } else {
        var listener = plugin.bus.on("changeVideoState", function () {
            if (plugin.isIdle()) {
                callback();

                listener.clear();
            }
        });
    }
};

AdsManager.prototype.checkCacheQueue = function () {
    if (connector.ads.isConnected()) {
        var plugin = connector.ads.getPlugin();

        if (plugin.cantLoadInParallel) {
            AdsPlugin.TYPES.slice().reverse().reduce(function (next, name) {
                return function () {
                    plugin.cacheAd(name, next);
                };
            }, function () {})();
        } else {
            AdsPlugin.TYPES.forEach(function (name) {
                plugin.cacheAd(name);
            });
        }
    }
};

AdsManager.prototype.askPermission = function (callback) {
    var plugin = connector.ads.getPlugin();

    plugin.askPermission(function () {
        connector.ads.connect();

        if (callback) {
            callback();
        }
    });
};

AdsManager.prototype.playAd = function (name, callback) {
    callback = callback || function () {};

    var code = this.getAdStatus(name);
    if (code) {
        callback(code);
        return;
    }

    var plugin = connector.ads.getPlugin();

    plugin.bus.trigger(name + ":loading", plugin.pluginName);

    plugin.cacheAd(name, function (code) {
        plugin.bus.trigger(name + ":loaded", code === connector.CODE_SUCCEED, plugin.pluginName);

        if (code !== connector.CODE_SUCCEED) {
            if (name === AdsPlugin.REWARDED) {
                callback(ERRORS.SHOW_REWARDED.NO_ADS);
            } else {
                callback(ERRORS.SHOW_INTERSTITIAL.NO_ADS);
            }
            return;
        }

        plugin.playAd(name, function (code) {
            AdsPlugin.TYPES.forEach(function (name) {
                plugin.cacheAd(name);
            });

            callback(code);
        });

        plugin.cacheAd(name);
    });
};

AdsManager.REFRESH_STICKY_INTERVAL = connector.utils.parseInterval("60 seconds");