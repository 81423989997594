/**
 * Created by mac on 4/8/18
 */

var NoAdsPlugin = function () {
    AdsPlugin.call(this, connector.NONE);
};

NoAdsPlugin.prototype = Object.create(AdsPlugin.prototype);
NoAdsPlugin.prototype.constructor = NoAdsPlugin;

NoAdsPlugin.prototype._connect = function (callback) {
    callback(connector.STATUS_DISABLED);
};

NoAdsPlugin.prototype.isRewardedSupported = function () {
    return false;
};

NoAdsPlugin.prototype.isInterstitialSupported = function () {
    return false;
};

NoAdsPlugin.prototype.isStickySupported = function () {
    return false;
};