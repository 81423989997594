/**
 * Created by spepa on 25.05.2023
 */

var TestPlatform = function () {
    Platform.call(this, connector.TEST);

    connector.social.needWindowForLogin = true;

    this.needsCountry = true;
};

TestPlatform.prototype = Object.create(Platform.prototype);
TestPlatform.prototype.constructor = TestPlatform;

TestPlatform.prototype.loadData = function () {
    return new Promise(function (resolve) {
        setTimeout(function () {
            var data = localStorage.getItem("testStorage");

            if (data) {
                data = JSON.parse(data);
            }

            resolve(data);
        }, 3000);
    });
};

TestPlatform.prototype.setData = function () {
    return new Promise(function (resolve) {
        localStorage.setItem("testStorage", JSON.stringify(connector._platformStorage.data));

        resolve();
    });
};

TestPlatform.prototype.gameplayStart = function () {
    console.log("Game play start");
};

TestPlatform.prototype.gameplayStop = function () {
    console.log("Game play stop");
};

TestPlatform.prototype.getLocalStoragePrefix = function () {
    return "_test";
};