/**
 * Created by andrey on 06.06.2023.
 */

var CountryManager = function () {
    ConnectorEventEmitter.call(this);

    this.country = CountryManager.UNKONWN;

    if (connector.platform.getPlugin().needsCountry) {
        this.load();

        this.update();

        if (connector.platform.oneOf(connector.CLEVERAPPS, connector.MSSTART, connector.ANDROID)) {
            this.updateIpCode();
        }
    }
};

CountryManager.prototype = Object.create(ConnectorEventEmitter.prototype);
CountryManager.prototype.constructor = CountryManager;

CountryManager.prototype.updateIpCode = function () {
    ConnectorRestClient.get("/country/getbyip", {}, function (response) {
        var countryCode = response && response.countryCode;

        this.setIpCode(countryCode);
    }.bind(this));
};

CountryManager.prototype.setCurrency = function (currency) {
    if (currency && this.currency !== currency) {
        this.currency = currency;
        this.save();

        this.update();
    }
};

CountryManager.prototype.setIpCode = function (ipCode) {
    if (ipCode && this.ipCode !== ipCode) {
        this.ipCode = ipCode;
        this.save();

        this.update();
    }
};

CountryManager.prototype.forceCountry = function (country) {
    if (this.forcedCountry !== country) {
        this.forcedCountry = country;

        this.update();
    }
};

CountryManager.prototype.update = function () {
    var code = this.calculateCode();

    console.log("[connector] update country: " + code + ", current: " + this.country);

    if (this.country === code) {
        return;
    }

    this.country = code;

    this.trigger("changed");
};

CountryManager.prototype.calculateCode = function () {
    if (this.forcedCountry) {
        return this.forcedCountry;
    }

    if (this.currency && this.currency === CountryManager.RUB) {
        return CountryManager.RU;
    }

    if (this.ipCode) {
        return this.ipCode;
    }

    if (this.currency) {
        return CountryManager.OTHER;
    }

    return CountryManager.UNKONWN;
};

CountryManager.prototype.load = function () {
    var info = connector.dataLoader.load(SimpleDataLoader.TYPES.PAYMENTS_COUNTRY) || {};
    this.currency = info.currency;
    this.ipCode = info.ipCode;
};

CountryManager.prototype.save = function () {
    connector.dataLoader.save(SimpleDataLoader.TYPES.PAYMENTS_COUNTRY, {
        currency: this.currency,
        ipCode: this.ipCode
    });
};

CountryManager.RUB = "RUB";

CountryManager.RU = "RU";
CountryManager.OTHER = "OTHER";
CountryManager.UNKONWN = "UNKNOWN";