/**
 * Created by razial on 05.08.2024.
 */

var HtmlLocalStorage = function () {
    var localStorage;
    try {
        localStorage = window.localStorage;
        localStorage.setItem("storage", "");
        localStorage.removeItem("storage");
    } catch (e) {
        var warn = function () {
            console.warn("Warning: localStorage isn't enabled. Please confirm browser cookie or privacy option");
        };
        localStorage = {
            getItem: warn,
            setItem: warn,
            removeItem: warn,
            clear: warn
        };
    }

    this.localStorage = localStorage;
};

HtmlLocalStorage.prototype.getItem = function (key) {
    var stored = this.localStorage.getItem(key);

    if (connector.platform.oneOf(connector.WECHAT) && stored === "") {
        return undefined;
    }

    return stored;
};

HtmlLocalStorage.prototype.setItem = function (key, value) {
    this.localStorage.setItem(key, value);
};

HtmlLocalStorage.prototype.removeItem = function (key) {
    this.localStorage.removeItem(key);
};

HtmlLocalStorage.prototype.getData = function (prefix) {
    var data = {};

    try {
        Object.keys(this.localStorage).forEach(function (key) {
            if (key.indexOf(prefix) === 0) {
                data[key] = this.getItem(key);
            }
        }.bind(this));
    } catch (e) {
        console.error("DataLoader.getData cant read all data", e);
    }

    return data;
};

HtmlLocalStorage.prototype.clearData = function (prefix) {
    try {
        for (var storedKey in this.localStorage) {
            if (storedKey.indexOf(prefix) === 0) {
                console.log("[connector] clearing local storage key", storedKey);
                this.localStorage.removeItem(storedKey);
            }
        }
    } catch (e) {
        console.error("[connector] error clearing local storage", e);
    }
};