/**
 * Created by slava on 4/21/17.
 */

var FacebookPayments = function () {
    RestPayments.call(this, connector.FACEBOOK, {
        sdk: SdkManager.SDK_FACEBOOK_CANVAS
    });
};

FacebookPayments.prototype = Object.create(RestPayments.prototype);
FacebookPayments.prototype.constructor = FacebookPayments;

FacebookPayments.UserCanceled = 1383010;
FacebookPayments.SuccessStatuses = ["completed", "active"];

FacebookPayments.prototype.getProductId = function (product) {
    return product.id.facebook;
};

FacebookPayments.prototype.getProductUrl = function (product) {
    var lang = connector.info.language;
    var langPath = (lang + "/");
    var basePath = langPath + (connector.config.debugMode === 0 ? "production/" : "");

    var filename = "res/fbproducts/" + basePath + product.productId + ".html";

    return connector.platform.getExternalUrl(filename);
};

FacebookPayments.prototype.purchaseOpen = function (product, callback) {
    var options = {
        product: this.getProductUrl(product),
        type: product.type
    };

    if (product.type === "subscription") {
        options.action = "create_subscription";
    }

    plugin.FacebookAgent.getInstance().canvas.pay(options, function (errorCode, result) {
        if (errorCode === FacebookPayments.UserCanceled) {
            console.log("Payment " + product.itemId + " error " + result.error_message);
            callback(ERRORS.PURCHASE.CANCEL);
        } else if (result && FacebookPayments.SuccessStatuses.indexOf(result.status) === -1) {
            console.log("Payment " + product.itemId + " status: ", result.status, (result.error_message ? ", error: " + result.error_message : ""));
            callback(ERRORS.PURCHASE.UNKNOWN);
        } else {
            console.log("Payment " + product.itemId + " success", result);

            var purchase = this.createPurchase(result.payment_id, product);

            callback(connector.CODE_SUCCEED, purchase);
        }
    }.bind(this));
};

FacebookPayments.isAppropriate = function () {
    return !connector.info.isNative;
};
