/**
 * Created by Vladislav on 08.09.2024.
 */

var PlatformStorage = function () {
    this.isReady = false;
    this.isLoading = false;
    this.data = undefined;

    this.bus = new ConnectorEventEmitter();
};

PlatformStorage.prototype.start = function () {
    if (this.isSupported()) {
        this.initializeInterval = setInterval(this.initialize.bind(this), 5000);

        connector.platform.whenConnected(this.initialize.bind(this));
    }
};

PlatformStorage.prototype.whenReady = function (callback) {
    if (this.isReady) {
        callback();
    } else {
        this.bus.once("ready", callback);
    }
};

PlatformStorage.prototype.isSupported = function () {
    return connector.platform.oneOf(connector.YANDEX, connector.VK, connector.OK, connector.INSTANT, connector.TEST);
};

PlatformStorage.prototype.initialize = function () {
    if (this.isReady || this.isLoading || !connector.platform.isConnected()) {
        return;
    }

    this.load().then(function (ready) {
        this.isReady = ready;

        if (ready) {
            clearInterval(this.initializeInterval);

            this.bus.trigger("ready");
        }
    }.bind(this));
};

PlatformStorage.prototype.load = function () {
    if (this.isLoading) {
        return this.loadingPromise;
    }

    this.isLoading = true;

    if (!connector.platform.isConnected()) {
        this.isLoading = false;
        this.bus.trigger("error:load", ERRORS.NOT_CONNECTED);
        return Promise.resolve(false);
    }

    this.loadingPromise = new Promise(function (resolve) {
        connector.platform.getPlugin().loadData().then(function (data) {
            this.data = data || {};
            this.isLoading = false;

            resolve(true);
        }.bind(this)).catch(function (error) {
            this.isLoading = false;

            console.log("[connector] Error retrieving platform data", error);

            this.bus.trigger("error:load", ERRORS.STORAGE.LOAD.UNKNOWN);

            resolve(false);
        }.bind(this));
    }.bind(this));

    return this.loadingPromise;
};

PlatformStorage.prototype.set = function (key, data) {
    var plugin = connector.platform.getPlugin();

    if (!this.isReady) {
        this.bus.trigger("error:set", ERRORS.STORAGE.NOT_READY);
        return Promise.resolve(false);
    }

    if (data === undefined) {
        data = null;
    }

    this.data[key] = data;

    return new Promise(function (resolve) {
        plugin.setData(key, data).then(function () {
            resolve(true);
        }).catch(function (error) {
            console.log("[connector] Error setting data", error);

            this.bus.trigger("error:set", ERRORS.STORAGE.SET.UNKNOWN);

            resolve(false);
        }.bind(this));
    }.bind(this));
};

PlatformStorage.prototype.get = function (key) {
    if (!this.isReady) {
        this.bus.trigger("error:get", ERRORS.STORAGE.NOT_READY);
        return;
    }

    return this.data[key];
};

PlatformStorage.prototype.getKeys = function () {
    if (!this.isReady) {
        this.bus.trigger("error:getKeys", ERRORS.STORAGE.NOT_READY);
        return;
    }

    return Object.keys(this.data);
};

// await connector.storage.ready;
//
// connector.storage.set(key, {prop: "val"});
// connector.storage.get(key);
// const data = await connector.storage.load();
