/**
 * Created by slava on 23.03.17.
 */

var BaseFbSocial = function (name, options) {
    Social.call(this, name, options);
};

BaseFbSocial.prototype = Object.create(Social.prototype);
BaseFbSocial.prototype.constructor = BaseFbSocial;

BaseFbSocial.prototype.pagingLoading = function (api, callback, page, next, res) {
    if (!res) {
        res = [];
    }

    if (!next) {
        next = "";
    }

    if (!page) {
        page = 0;
    }

    if (page >= BaseFbSocial.MAX_PAGE) {
        callback(connector.CODE_SUCCEED, res);
        return;
    }

    this.api(api + "&limit=100" + next, function (code, response) {
        if (code === connector.CODE_SUCCEED) {
            if (response.data) {
                res = res.concat(response.data);
            }
            if (response.paging && response.paging.cursors && response.paging.next && response.paging.cursors.after) {
                this.pagingLoading(api, callback, page + 1, "&after=" + response.paging.cursors.after, res);
            } else {
                callback(connector.CODE_SUCCEED, res);
            }
        } else if (res.length) {
            callback(connector.CODE_SUCCEED, res);
        } else {
            callback(code, res);
        }
    }.bind(this));
};

BaseFbSocial.prototype.getShareDialogLink = function (data) {
    var shareLink = connector.config.payApiURL + "/share";

    shareLink += "?name=" + encodeURIComponent(data.name)
        + "&description=" + encodeURIComponent(data.text)
        + "&picture=" + encodeURIComponent(data.image);

    return shareLink;
};

BaseFbSocial.prototype._listFriends = function (callback) {
    this.pagingLoading("/me/friends?fields=first_name,id,picture.type(large)", callback);
};

BaseFbSocial.prototype._aboutMe = function (callback) {
    this.aboutUser("me", callback); 
};

BaseFbSocial.prototype._aboutUser = function (userId, callback) {
    var fields = "currency,first_name,name,picture.type(large)";

    if (userId === "me") {
        fields += ",email";
    }

    this.api("/" + userId + "/?fields=" + fields, function (code, response) {
        if (code === connector.CODE_SUCCEED) {
            callback(connector.CODE_SUCCEED, BaseFbSocial.formatUserData(response));
        } else {
            console.log("Error: " + code);
            console.log(response);

            callback(connector.CODE_FAILED);

            if (BaseFbSocial.USERS_BLACK_LIST_ERROR_CODES.indexOf(code) >= 0) {
                var data = { code: code, data: response };
                ConnectorRestClient.post(
                    "/users/blacklist/add/" + userId, 
                    data,
                    function () {
                        console.log("Success add to black list:" + userId);
                    },
                    function () {
                        console.log("Failure add to black list:" + userId);
                    }
                );
            }
        }
    });
};

BaseFbSocial.prototype.deleteAllRequests = function () {
    this.api("me/apprequests", function (code, response) {
        if (code === connector.CODE_SUCCEED) {
            if (response.data) {
                for (var i = 0; i < response.data.length; i++) {
                    console.log("START DELETE REQUEST", response.data[i].id);
                    this.api(response.data[i].id, "delete", function (code, response) {
                        console.log("DELETE REQUEST", code, response);
                    });
                }
            }
        } else {
            console.log("Error: " + code);
            console.log(response);
        }
    }.bind(this));
};

BaseFbSocial.prototype.api = function () {

};

BaseFbSocial.formatUserData = function (userData) {
    return {
        id: userData.id,
        name: userData.name,
        firstName: userData.first_name,
        avatar: userData.picture && userData.picture.data && userData.picture.data.url,
        currency: userData.currency && userData.currency.user_currency
    };
};

BaseFbSocial.MAX_PAGE = 20;
BaseFbSocial.USERS_BLACK_LIST_ERROR_CODES = [100];
BaseFbSocial.PERMISSIONS = "public_profile,gaming_profile,gaming_user_picture";
