/**
 * Created by andrey on 30.10.2020.
 */

var AdsPlugin = function (pluginName, options) {
    options = options || {};

    BasePlugin.call(this, "ads", pluginName, options);

    this.cantLoadInParallel = options.cantLoadInParallel;
    this.cantLoadAndPlayInParallel = options.cantLoadAndPlayInParallel;
    this.customImpressionEvent = options.customImpressionEvent;
    this.expirationPeriod = options.expirationPeriod;

    this.cached = {};
    this.cachedTime = {};
    this.cachedErrorTime = {};

    this.playCallbacks = {};

    this.loaderState = {};
    this.videoState = {};

    this.finishTime = {};

    this.permissionAsked = false;

    AdsPlugin.TYPES.forEach(function (name) {
        this.loaderState[name] = AdsPlugin.LOADER_STATE.IDLE;
        this.videoState[name] = AdsPlugin.VIDEO_STATE.IDLE;
    }.bind(this));
};

AdsPlugin.prototype = Object.create(BasePlugin.prototype);
AdsPlugin.prototype.constructor = AdsPlugin;

AdsPlugin.prototype.canConnect = function () {
    return this.permissionAsked;
};

AdsPlugin.prototype.askPermission = function (callback) {
    if (this.permissionAsked) {
        callback();
    } else {
        this._askPermission(function () {
            this.permissionAsked = true;
            callback();
        }.bind(this));
    }
};

AdsPlugin.prototype._askPermission = function (callback) {
    callback();
};

AdsPlugin.prototype.onCreated = function () {
    if (!connector._adsManager.manualPermissions) {
        connector._adsManager.askPermission();
    }
};

AdsPlugin.prototype.setLoaderState = function (name, state) {
    console.log(this.pluginName, "setLoaderState", name, state);
    this.loaderState[name] = state;

    this.bus.trigger(name + ":refresh");
};

AdsPlugin.prototype.setVideoState = function (name, state) {
    console.log(this.pluginName, "setVideoState", name, state);
    this.videoState[name] = state;
    this.bus.trigger("changeVideoState", name, state);
};

AdsPlugin.prototype.whichIsLoading = function () {
    for (var name in this.loaderState) {
        if (this.isAdLoading(name)) {
            return name;
        }
    }
};

AdsPlugin.prototype.whichIsPlaying = function () {
    for (var name in this.videoState) {
        if (this.videoState[name] === AdsPlugin.VIDEO_STATE.PLAYING) {
            return name;
        }
    }
};

AdsPlugin.prototype.isPlaying = function (type) {
    return this.videoState[type] === AdsPlugin.VIDEO_STATE.PLAYING;
};

AdsPlugin.prototype._connect = function (callback) {
    callback(connector.STATUS_CONNECTED);
};

AdsPlugin.prototype.isStickyAvailable = function () {
    return true;
};

AdsPlugin.prototype.isRewardedSupported = function () {
    return true;
};

AdsPlugin.prototype.isInterstitialSupported = function () {
    return true;
};

AdsPlugin.prototype.isStickySupported = function () {
    return false;
};

AdsPlugin.prototype.isRewardedConfigured = function () {
    return true;
};

AdsPlugin.prototype.isInterstitialConfigured = function () {
    return true;
};

AdsPlugin.prototype.isStickyConfigured = function () {
    return true;
};

AdsPlugin.prototype.isAdLoading = function (name) {
    return this.loaderState[name] === AdsPlugin.LOADER_STATE.LOADING;
};

AdsPlugin.prototype.isAdCached = function (name) {
    return this.loaderState[name] === AdsPlugin.LOADER_STATE.READY;
};

AdsPlugin.prototype.isAdRunning = function (name) {
    return this.videoState[name] === AdsPlugin.VIDEO_STATE.PLAYING;
};

AdsPlugin.prototype.isIdle = function () {
    return AdsPlugin.TYPES.every(function (name) {
        return !this.isAdRunning(name);
    }, this);
};

AdsPlugin.prototype.canCache = function (name) {
    if (!this.isConnected() || !connector._adsManager.isAdSupported(name)) {
        return false;
    }

    if (this.cantLoadInParallel && this.whichIsLoading()) {
        return false;
    }

    var finishTime = this.finishTime[name] || 0;
    if (this.cantLoadAndPlayInParallel && (this.isAdRunning(name) || finishTime + AdsPlugin.LOAD_AFTER_PLAY_TIME > Date.now())) {
        return false;
    }

    var cachedErrorTime = this.cachedErrorTime[name] || 0;
    if (cachedErrorTime + AdsPlugin.LOAD_AFTER_ERROR_TIME > Date.now()) {
        return false;
    }

    return true;
};

AdsPlugin.prototype.cacheAd = function (name, callback) {
    callback = callback || function () {};

    if (!this.canCache(name)) {
        callback(connector.CODE_FAILED);
        return;
    }

    if (this.isAdCached(name) && !this.isAdExpired(name)) {
        callback(connector.CODE_SUCCEED);
        return;
    }

    this.cacheAdInner(name, callback);
};

AdsPlugin.prototype.cacheAdInner = function (name, callback) {
    this.setLoaderState(name, AdsPlugin.LOADER_STATE.LOADING);

    this.bus.trigger(name + ":caching", this.pluginName);

    this._cacheAd(name, connector.utils.waitNoMore(AdsPlugin.TRY_LOADING_TIMEOUT, function (code, loadedAd) {
        this.bus.trigger(name + ":cached", code, this.pluginName);

        if (code === connector.CODE_SUCCEED) {
            this.cached[name] = loadedAd;
            this.cachedTime[name] = Date.now();
            this.setLoaderState(name, AdsPlugin.LOADER_STATE.READY);
        } else {
            this.cachedErrorTime[name] = Date.now();
            this.setLoaderState(name, AdsPlugin.LOADER_STATE.IDLE);
        }

        callback(code);
    }.bind(this)));
};

AdsPlugin.prototype._cacheAd = function (name, callback) {
    callback(connector.CODE_SUCCEED, true);
};

AdsPlugin.prototype.isAdExpired = function () {
    return false;
};

AdsPlugin.prototype.consumeCachedAd = function (name) {
    var cachedAd = this.cached[name];

    this.cached[name] = undefined;
    this.cachedTime[name] = undefined;
    this.cachedErrorTime[name] = undefined;

    return cachedAd;
};

AdsPlugin.prototype.whenCached = function (name, callback) {
    if (this.isAdCached(name)) {
        callback(connector.CODE_SUCCEED);
    } else if (this.isAdLoading(name)) {
        this.bus.once(name + ":cached", callback);
    } else {
        this.cacheAdInner(name, callback);
    }
};

AdsPlugin.prototype.playAd = function (name, callback) {
    callback = callback || function () {};

    var expired = this.cachedTime[name] + this.expirationPeriod <= Date.now();
    var cachedAd = this.consumeCachedAd(name);

    this.setLoaderState(name, AdsPlugin.LOADER_STATE.IDLE);
    this.setVideoState(name, AdsPlugin.VIDEO_STATE.PLAYING);

    this.bus.trigger(name + ":start", this.pluginName);
    if (expired) {
        this.bus.trigger(name + ":start:expired", this.pluginName);
    }

    this.playCallbacks[name] = connector.utils.once(function (code) {
        this.playCallbacks[name] = undefined;
        this.finishTime[name] = Date.now();

        this.setVideoState(name, AdsPlugin.VIDEO_STATE.IDLE);

        var success = code === connector.CODE_SUCCEED;

        this.bus.trigger(name + ":close", success, this.pluginName);
        if (name === AdsPlugin.REWARDED && success) {
            this.bus.trigger("rewarded:reward", this.pluginName);
        }

        if (expired) {
            this.bus.trigger(name + ":close:expired", this.pluginName);
        }

        callback(code);
    }.bind(this));

    if (this._testNoCallback) {
        delete this._testNoCallback;

        return;
    }

    var playCallback = connector.utils.once(function (code) {
        if (!this.customImpressionEvent && code === connector.CODE_SUCCEED) {
            this.onImpressionEvent(name);
        }

        if (this.playCallbacks[name]) {
            this.playCallbacks[name](code);
        }
    }.bind(this));

    this._playAd(name, cachedAd, function (code) {
        if ([ERRORS.SHOW_REWARDED.AD_EXPIRED,
            ERRORS.SHOW_REWARDED.UNKNOWN,
            ERRORS.SHOW_REWARDED.NO_ADS,
            ERRORS.SHOW_INTERSTITIAL.UNKNOWN,
            ERRORS.SHOW_INTERSTITIAL.NO_ADS
        ].includes(code)) {
            var errorShortName = (code + "").replace("error", "e")
                .replace("rewarded", "r")
                .replace("interstitial", "i");

            console.log("[connector] Ad error, trying again", code);
            this.whenCached(name, function (code) {
                console.log("[connector] Ad cached after error result:", code === connector.CODE_SUCCEED);

                var cacheErrorShortName = (code + "").replace("ads_loading_", "");

                this.bus.trigger("stream:logs", "ad_retry_c_" + errorShortName + "__" + cacheErrorShortName);

                if (code === connector.CODE_SUCCEED) {
                    var cachedAd = this.consumeCachedAd(name);

                    this.setLoaderState(name, AdsPlugin.LOADER_STATE.IDLE);
                    this.setVideoState(name, AdsPlugin.VIDEO_STATE.PLAYING);

                    this._playAd(name, cachedAd, function (code) {
                        var playErrorShortName = (code + "").replace("error", "e")
                            .replace("rewarded", "r")
                            .replace("interstitial", "i");

                        this.bus.trigger("stream:logs", "ad_retry_p_" + errorShortName + "__" + playErrorShortName);

                        playCallback(code);
                    }.bind(this));
                } else if (name === AdsPlugin.REWARDED) {
                    playCallback(ERRORS.SHOW_REWARDED.NO_ADS);
                } else {
                    playCallback(ERRORS.SHOW_INTERSTITIAL.NO_ADS);
                }
            }.bind(this));
        } else {
            playCallback(code);
        }
    }.bind(this));
};

AdsPlugin.prototype._playAd = function (name, ad, callback) {
    callback(connector.CODE_SUCCEED);
};

AdsPlugin.prototype.finishAd = function (name, code) {
    if (this.playCallbacks[name]) {
        this.playCallbacks[name](code);
    }
};

AdsPlugin.prototype.onImpressionEvent = function (name, impressionCost) {
    if (impressionCost === undefined) {
        impressionCost = this.getECPM(name) / 1000;
    }

    if (impressionCost) {
        this.bus.trigger(name + ":impression", impressionCost, this.pluginName);
    }
};

AdsPlugin.prototype.getECPM = function (name) {
    if (connector.config.debugMode) {
        if (!connector.ads.eCPM[this.pluginName] || !connector.ads.eCPM.rewarded[this.pluginName]) {
            throw "No eCPM for " + this.pluginName;
        }
    }

    if (name === AdsPlugin.REWARDED) {
        return connector.ads.eCPM.rewarded[this.pluginName] || 1;
    }

    return connector.ads.eCPM[this.pluginName] || 1;
};

AdsPlugin.LOADER_STATE = {
    IDLE: "idle",
    LOADING: "loading",
    READY: "ready"
};

AdsPlugin.VIDEO_STATE = {
    IDLE: "idle",
    PLAYING: "playing"
};

AdsPlugin.REWARDED = "rewarded";
AdsPlugin.INTERSTITIAL = "interstitial";
AdsPlugin.STICKY = "sticky";
AdsPlugin.TYPES = [AdsPlugin.INTERSTITIAL, AdsPlugin.REWARDED];

AdsPlugin.ERROR_LOADING_UNKNOWN = "ads_loading_unknown";
AdsPlugin.ERROR_LOADING_FAILED = "ads_loading_failed";
AdsPlugin.ERROR_LOADING_NO_ADS = "ads_loading_no_ads";
AdsPlugin.ERROR_LOADING_ADBLOCK = "ads_loading_adblock";
AdsPlugin.ERROR_LOADING_ALREADY_LOADING = "ads_loading_already_loading";

AdsPlugin.TRY_LOADING_TIMEOUT = connector.utils.parseInterval("10 seconds");
AdsPlugin.CHECK_CACHE_INTERVAL = connector.utils.parseInterval("30 seconds");
AdsPlugin.LOAD_AFTER_PLAY_TIME = connector.utils.parseInterval("15 seconds");
AdsPlugin.LOAD_AFTER_ERROR_TIME = connector.utils.parseInterval("2 minutes");
