/**
 * Created by vladislav on 9/6/2022
 */

var FotoStrana = function () {
    Platform.call(this, connector.FOTOSTRANA, {
        sdk: SdkManager.SDK_FOTOSTRANA
    });
};

FotoStrana.prototype = Object.create(Platform.prototype);
FotoStrana.prototype.constructor = FotoStrana;

FotoStrana.prototype.getLocalStoragePrefix = function () {
    return "_FS";
};

FotoStrana.prototype.listSuggestedLanguages = function () {
    return [connector.LANGUAGE_RUSSIAN];
};
