/**
 * Created by slava on 4/5/17.
 */

var TestSocial = function () {
    Social.call(this, connector.TEST);
};

TestSocial.prototype = Object.create(Social.prototype);
TestSocial.prototype.constructor = TestSocial;

TestSocial.prototype.isSupportsNativeShare = function () {
    return true;
};

TestSocial.prototype.isSupportsNativeInvite = function () {
    return true;
};

TestSocial.prototype.isSupportsNativeRequest = function () {
    return true;
};

TestSocial.prototype.updateSession = function (id) {
    var expire;
    if (typeof DateShift !== "undefined") {
        expire = new DateShift.Date();
    } else {
        expire = new Date();
    }
    expire.setMonth(expire.getMonth() + 3);

    this.id = id;
    this.token = "123";
    this.expires = expire.getTime();

    document.cookie = "tsid=" + this.id + "; path=/; expires=" + expire.toUTCString();
};

TestSocial.prototype.removeSession = function () {
    this.id = undefined;
    this.token = undefined;
    this.expires = undefined;

    document.cookie = "tsid=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

TestSocial.prototype.loadSession = function () {
    var match = document.cookie && document.cookie.match(/tsid=([^;]+)/);
    if (match) {
        this.updateSession(match[1]);
    }
};

TestSocial.prototype._connect = function (callback) {
    this.id = undefined;
    this.expires = undefined;
    this.token = undefined;

    setTimeout(function () {
        this.loadSession();
        callback(connector.STATUS_CONNECTED);
    }.bind(this), 1000);
};

TestSocial.prototype._loadTestImage = function (url, callback) {
    var image = new Image();
    document.body.appendChild(image);
    image.style.zIndex = "10";
    image.style.margin = "auto auto";

    var close = function () {
        document.body.removeChild(image);
    };

    image.onload = function () {
        setTimeout(function () {
            callback(close);
        }, 100);
    };
    image.src = url;
};

TestSocial.prototype.share = function (data, callback) {
    this._loadTestImage(data.image, function (close) {
        if (confirm("Do you to post " + JSON.stringify(data) + "?")) {
            close();

            callback(connector.CODE_SUCCEED);
        } else {
            close();

            callback(connector.CODE_FAILED);
        }
    });
};

TestSocial.prototype.isExpired = function () {
    var now;
    if (typeof DateShift !== "undefined") {
        now = new DateShift.Date();
    } else {
        now = Date.now();
    }

    return !this.expires || this.expires < now;
};

TestSocial.prototype.isLoggedIn = function () {
    return Boolean(this.id);
};

TestSocial.prototype._getUserID = function () {
    return this.id;
};

TestSocial.prototype.getAccessToken = function () {
    return this.isExpired() ? undefined : this.token;
};

TestSocial.prototype._login = function (callback) {
    new FakeLoginWindow(TestSocial.USERS, function (id) {
        if (id) {
            this.updateSession(id);
        } else {
            this.removeSession();
        }

        callback();
    }.bind(this));
};

TestSocial.prototype.logout = function () {
    this.removeSession();
};

TestSocial.prototype.invite = function (data, callback) {
    this._loadTestImage(data.image, function (close) {
        if (confirm("Invite " + JSON.stringify(data))) {
            close();

            callback(connector.CODE_SUCCEED);
        } else {
            close();

            callback(connector.CODE_FAILED);
        }
    });
};

TestSocial.prototype.request = function (data, callback) {
    delete data.element;

    callback = callback || function () {};

    this._loadTestImage(data.image, function (close) {
        if (confirm("Do you to post " + JSON.stringify(data) + "?")) {
            close();

            callback(connector.CODE_SUCCEED);
        } else {
            close();

            callback(connector.CODE_FAILED);
        }
    });
};

TestSocial.prototype._listFriends = function (callback) {
    setTimeout(function () {
        callback(connector.CODE_SUCCEED, Object.values(TestSocial.USERS));
    }, 500);
};

TestSocial.prototype._listInvitableFriends = function (callback) {
    setTimeout(function () {
        callback(connector.CODE_SUCCEED, Object.values(TestSocial.INVITABLE_USERS));
    }, 500);
};

TestSocial.prototype._aboutMe = function (callback) {
    setTimeout(function () {
        callback(connector.CODE_SUCCEED, {
            name: "FakeFB User",
            avatar: "https://graph.facebook.com/100001828353521/picture?height=200&width=200",
            id: connector.platform.getUserID()
        });
    }, 500);
};

TestSocial.isAppropriate = function () {
    return connector.platform.oneOf(connector.TEST);
};

TestSocial.USERS = {
    alpha: {
        name: "Alpha",
        avatar: "https://graph.facebook.com/41803173/picture?height=200&width=200"
    },
    beta: {
        name: "Beta",
        avatar: "https://graph.facebook.com/692982514/picture?height=200&width=200"
    },
    gamma: {
        name: "Gamma",
        avatar: "https://graph.facebook.com/100028444590540/picture?height=200&width=200"
    }
};

Object.keys(TestSocial.USERS).forEach(function (id) {
    TestSocial.USERS[id].id = id;
});

TestSocial.INVITABLE_USERS = {
    invitable_1: {
        name: "Invitable1",
        avatar: "https://graph.facebook.com/100001008129789/picture?height=200&width=200"
    },
    invitable_2: {
        name: "Invitable2",
        avatar: "https://graph.facebook.com/100011706990070/picture?height=200&width=200"
    },
    invitable_3: {
        name: "Invitable3",
        avatar: "https://graph.facebook.com/100001575006940/picture?height=200&width=200"
    },
    invitable_4: {
        name: "Invitable4",
        avatar: "https://graph.facebook.com/100000318466656/picture?height=200&width=200"
    }
};

Object.keys(TestSocial.INVITABLE_USERS).forEach(function (id) {
    TestSocial.INVITABLE_USERS[id].id = id;
});
