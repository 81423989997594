/**
 * Created by vladislav on 9/14/2022
 */

var MicrosoftFacebook = function () {
    Social.call(this, connector.MICROSOFT);
};

MicrosoftFacebook.prototype = Object.create(Social.prototype);
MicrosoftFacebook.prototype.constructor = MicrosoftFacebook;

MicrosoftFacebook.prototype._connect = function (callback) {
    this.loadUser();
    callback(connector.STATUS_CONNECTED);
};

MicrosoftFacebook.prototype.saveUser = function () {
    connector.dataLoader.save(SimpleDataLoader.TYPES.MS_SOCIAL, {
        user: this.user
    });
};

MicrosoftFacebook.prototype.loadUser = function () {
    var data = connector.dataLoader.load(SimpleDataLoader.TYPES.MS_SOCIAL);
    if (data && data.user) {
        this.user = data.user;

        if (this.user.first_name) {
            this.user.firstName = this.user.first_name;
            delete this.user.first_name;
        }

        if (this.user.picture && this.user.picture.data && this.user.picture.data.url) {
            this.user.avatar = this.user.picture.data.url;
            delete this.user.picture;
        }
    }
};

MicrosoftFacebook.prototype._login = function (callback) {
    var fbLoginCallback = function () {
        callback();

        if (this.isLoggedIn()) {
            this.saveUser();
        }
    }.bind(this);

    connector.platform.callNative("social.FBLogin", {
        FBAppId: connector.config.instant.appId
    }, function (code, response) {
        if (code !== connector.CODE_SUCCEED) {
            fbLoginCallback();
            return;
        }

        var authUrl = response && response.authUrl;

        var params = new URLSearchParams(authUrl.substring(authUrl.indexOf("#") + 1));

        if (!params.has("access_token")) {
            fbLoginCallback();
            return;
        }
        this.accessToken = params.get("access_token");

        this.initPlayer(fbLoginCallback);
    }.bind(this));
};

MicrosoftFacebook.prototype.initPlayer = function (callback) {
    var success = function (data) {
        if (data && data.id) {
            this.user = MicrosoftFacebook.formatUserData(data);
            callback(connector.CODE_SUCCEED);
        } else {
            callback(connector.CODE_FAILED);
        }
    }.bind(this);

    var fail = function () {
        callback(connector.CODE_FAILED);
    };

    this.api("me?fields=instant_game_player_id,first_name,name,picture", success, fail);
};

MicrosoftFacebook.prototype.api = function (path, onSuccess, onFail) {
    var host = "https://graph.fb.gg/";
    if (path.indexOf("?") === -1) {
        path += "?";
    }
    var url = host + path + "&access_token=" + this.accessToken;

    ConnectorRestClient.get(url, {}, function (response) {
        onSuccess(response);
    }, function () {
        onFail && onFail();
    });
};

MicrosoftFacebook.prototype._aboutMe = function (callback) {
    if (this.user) {
        callback(connector.CODE_SUCCEED, this.user);
    } else {
        callback(connector.CODE_FAILED);
    }
};

MicrosoftFacebook.prototype.getAccessToken = function () {
    return "microsoft";
};

MicrosoftFacebook.prototype.isLoggedIn = function () {
    return this.user && this.user.id !== undefined;
};

MicrosoftFacebook.prototype._getUserID = function () {
    return this.user && this.user.id;
};

MicrosoftFacebook.formatUserData = function (data) {
    return {
        id: "" + data.instant_game_player_id,
        name: data.name,
        firstName: data.first_name,
        avatar: data.url
    };
};

MicrosoftFacebook.isAvailable = function () {
    return connector.platform.oneOf(connector.MICROSOFT) && connector.config.facebookLogin;
};