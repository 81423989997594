/**
 * Created by slava on 4/21/17.
 */

var MyMailRuSocial = function () {
    Social.call(this, connector.MM, {
        sdk: SdkManager.SDK_MYMAILRU
    });
};

MyMailRuSocial.prototype = Object.create(Social.prototype);
MyMailRuSocial.prototype.constructor = MyMailRuSocial;

MyMailRuSocial.prototype.isSupportsNativeShare = function () {
    return connector.info.os !== connector.OS_IOS;
};

MyMailRuSocial.prototype.isSupportsNativeInvite = function () {
    return connector.info.os !== connector.OS_IOS;
};

MyMailRuSocial.prototype.isSupportsNativeRequest = function () {
    return connector.info.os !== connector.OS_IOS;
};

MyMailRuSocial.prototype._connect = function (callback) {
    if (connector.info.os === connector.OS_IOS) {
        callback(connector.STATUS_CONNECTED);
        return;
    }

    this.listeners = {
        friendsInvitation: function () {
        },
        friendsRequest: function () {
        },
        streamPublish: function () {
        }
    };

    mailru.events.listen(mailru.app.events.friendsInvitation, function (event) {
        this.listeners.friendsInvitation(event);
    }.bind(this));

    mailru.events.listen(mailru.app.events.friendsRequest, function (event) {
        this.listeners.friendsRequest(event);
    }.bind(this));

    mailru.events.listen(mailru.common.events.streamPublish, function (event) {
        this.listeners.streamPublish(event);
    }.bind(this));

    callback(connector.STATUS_CONNECTED);
};

MyMailRuSocial.prototype.isLoggedIn = function () {
    return true;
};

MyMailRuSocial.prototype._getUserID = function () {
    return connector.utils.getRequestParameters().vid;
};

MyMailRuSocial.prototype.getAccessToken = function () {
    var names = [], params = connector.utils.getRequestParameters();
    for (var name in params) {
        if (name !== "sig") {
            names.push(name);
        }
    }
    names.sort();
    var str = "";
    for (var i = 0; i < names.length; i++) {
        str += names[i] + "=" + params[names[i]];
    }
    return "MM_" + params.sig + "_" + str;
};

MyMailRuSocial.prototype._login = function (callback) {
    callback();
};

MyMailRuSocial.prototype.invite = function (data, callback) {
    if (connector.info.os === connector.OS_IOS) {
        callback(connector.CODE_FAILED);
        return;
    }

    this.listeners.friendsInvitation = function (event) {
        if (event.status === "opened") {
            return;
        }
        callback(event.data && event.data.length ? connector.CODE_SUCCEED : connector.CODE_CANCELLED);
    };

    mailru.app.friends.invite({ text: data.text });
};

MyMailRuSocial.prototype.request = function (data, callback) {
    if (connector.info.os === connector.OS_IOS) {
        callback(connector.CODE_FAILED);
        return;
    }

    this.listeners.friendsRequest = function (event) {
        if (event.status === "opened") {
            return;
        }
        if (event.data && event.data.length) {
            callback(connector.CODE_SUCCEED, event.data);
        } else {
            callback(connector.CODE_CANCELLED);
        }
    };

    mailru.app.friends.request({
        text: data.text,
        image_url: data.image,
        friends: data.to
    });
};

MyMailRuSocial.prototype.share = function (data, callback) {
    if (connector.info.os === connector.OS_IOS) {
        callback(connector.CODE_FAILED);
        return;
    }

    this.listeners.streamPublish = function (event) {
        if (event.status === "opened") {
            return;
        }
        if (event.status === "publishSuccess") {
            callback(connector.CODE_SUCCEED);
        } else {
            console.log(event.status);
            callback(connector.CODE_CANCELLED);
        }
    };
    var postData = {
        title: data.title,
        text: data.text,
        img_url: data.image
    };

    mailru.common.stream.post(postData);
};

MyMailRuSocial.prototype._listFriends = function (callback) {
    mailru.common.friends.getAppUsers(function (list) {
        var friends = [];
        for (var i = 0; i < list.length; i++) {
            friends.push(MyMailRuSocial.formatUserData(list[i]));
        }
        callback(connector.CODE_SUCCEED, friends);
    }, true);
};

MyMailRuSocial.prototype._listInvitableFriends = function (callback) {
    mailru.common.friends.getExtended(function (list) {
        var friends = [];
        for (var i = 0; i < list.length; i++) {
            if (!list[i].app_installed) {
                friends.push(MyMailRuSocial.formatUserData(list[i]));
            }
        }
        callback(connector.CODE_SUCCEED, friends);
    });
};

MyMailRuSocial.formatUserData = function (user) {
    return {
        id: user.uid,
        name: user.first_name + " " + user.last_name,
        firstName: user.first_name,
        lastName: user.last_name,
        avatar: user.pic_128
    };
};

MyMailRuSocial.prototype._aboutUser = function (userId, callback) {
    var isSingle = !Array.isArray(userId);

    mailru.common.users.getInfo(function (data) {
        if (data.error) {
            callback(connector.CODE_FAILED);

            return;
        }

        if (!data || typeof data.map !== "function") {
            this.bus.trigger("stream:error", "MailRU aboutUser response - " + JSON.stringify(data));
        }

        var users = data.map(function (userData) {
            return MyMailRuSocial.formatUserData(userData);
        });
        if (isSingle) {
            callback(connector.CODE_SUCCEED, users[0]);
        } else {
            callback(connector.CODE_SUCCEED, users);
        }
    }.bind(this), userId);
};

MyMailRuSocial.prototype._aboutMe = function (callback) {
    // console.log('about me: ' + connector.platform.getUserID())
    this.aboutUser(connector.platform.getUserID(), callback);
};