/**
 * Created by slava on 4/21/17.
 */

var FotoStranaSocial = function () {
    Social.call(this, connector.FOTOSTRANA, {
        sdk: SdkManager.SDK_FOTOSTRANA
    });
};

FotoStranaSocial.prototype = Object.create(Social.prototype);
FotoStranaSocial.prototype.constructor = FotoStranaSocial;

FotoStranaSocial.prototype.isSupportsNativeShare = function () {
    return true;
};

FotoStranaSocial.prototype.isSupportsNativeInvite = function () {
    return true;
};

FotoStranaSocial.prototype.isSupportsNativeRequest = function () {
    return true;
};

FotoStranaSocial.prototype.isLoggedIn = function () {
    return true;
};

FotoStranaSocial.prototype.fromSocialUserId = function (socialUserId) {
    return "FS_" + socialUserId;
};

FotoStranaSocial.prototype.toSocialUserId = function (ourUserId) {
    return ourUserId.substr(3);
};

FotoStranaSocial.prototype._getUserID = function () {
    return this.fromSocialUserId(connector.utils.getRequestParameters().viewerId);
};

FotoStranaSocial.prototype.getAccessToken = function () {
    return "FS_" + connector.utils.getRequestParameters().authKey;
};

FotoStranaSocial.prototype._login = function (callback) {
    callback();
};

FotoStranaSocial.prototype.invite = function (data, callback) {
    FSClient.event("invite", function (response) {
        callback(response.send ? connector.CODE_SUCCEED : connector.CODE_CANCELLED);
    });
};

FotoStranaSocial.prototype.request = function (data, callback) {
    if (typeof data.to !== "object") {
        data.to = [data.to];
    }
    var uids = [];
    data.to.forEach(function (uid) {
        uids.push(this.toSocialUserId(uid));
    }.bind(this));

    FSClient.event("sendMessageAndInvite", function (response) {
        callback(response.messSent || response.invSent ? connector.CODE_SUCCEED : connector.CODE_CANCELLED);
    }, {
        message: data.text,
        customIds: uids.join(",")
    });
};

FotoStranaSocial.prototype.share = function (data, callback) {
    FSClient.event("postUserEventOnWall", function (response) {
        callback(response.result === "success" ? connector.CODE_SUCCEED : connector.CODE_CANCELLED);
    }, {
        title: data.title,
        text: data.text,
        imgUrl: data.image
    });
};

FotoStranaSocial.prototype.loadUsersData = function (ids, callback) {
    if (!ids || !ids.length) {
        callback(connector.CODE_CANCELLED, []);
        return;
    }

    FSClient.api("User.getProfiles", {
        userIds: ids.join(","),
        fields: "user_name, user_lastname, photo_small"
    }, function (data) {
        var res = [];
        if (data.response) {
            for (var id in data.response) {
                res.push(this.formatUserData(data.response[id]));
            }
            callback(connector.CODE_SUCCEED, res);
            return;
        }
        callback(connector.CODE_FAILED, res);
    }.bind(this));
};

FotoStranaSocial.prototype._listFriends = function (callback) {
    FSClient.api("User.getAppFriends", {
        appId: connector.config.fotostrana.appId,
        limit: 500
    }, function (data) {
        this.loadUsersData(data.response, callback);
    }.bind(this));
};

FotoStranaSocial.prototype._listInvitableFriends = function (callback) {
    FSClient.api("User.getFriends", {
        limit: 200
    }, function (data) {
        if (data.response && data.response.length) {
            var allIds = data.response;

            FSClient.api("User.getAppFriends", {
                appId: connector.config.fotostrana.appId,
                limit: 500
            }, function (data) {
                if (data.response && data.response.length) {
                    allIds = connector.utils.substract(allIds, data.response);
                }
                this.loadUsersData(allIds, callback);
            }.bind(this));
        } else {
            callback(connector.CODE_FAILED);
        }
    }.bind(this));
};

FotoStranaSocial.prototype.formatUserData = function (user) {
    return {
        id: this.fromSocialUserId(user.user_id),
        name: user.user_name + " " + user.user_lastname,
        firstName: user.user_name,
        lastName: user.user_lastname,
        avatar: user.photo_small
    };
};

FotoStranaSocial.prototype._aboutMe = function (callback) {
    this.loadUsersData([this.toSocialUserId(connector.platform.getUserID())], function (code, data) {
        if (code === connector.CODE_SUCCEED && data.length) {
            callback(connector.CODE_SUCCEED, data[0]);
        } else {
            callback(connector.CODE_FAILED);
        }
    });
};