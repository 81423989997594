/**
 * Created by iamso on 21.01.19.
 */

var SPMobageSocial = function () {
    MobageSocial.call(this, connector.SP_MOBAGE, {
        sdk: SdkManager.SDK_SPMOBAGE
    });

    this.clientData = {
        clientId: SPMobageSocial.getClientId(),
        state: "mobage-connect_" + Math.random().toString(36).slice(-16),
        code: null,
        connected: false
    };

    this.clientData.redirectUrl = SPMobageSocial.getRedirectUrl();

    connector.social.mobagePlatformLogin = false;

    connector.platform.on("pause", this._updateRefreshInterval.bind(this));
    connector.platform.on("resume", this._updateRefreshInterval.bind(this));
};

SPMobageSocial.prototype = Object.create(MobageSocial.prototype);
SPMobageSocial.prototype.constructor = SPMobageSocial;

SPMobageSocial.prototype.isSupportsNativeShare = function () {
    return false;
};

SPMobageSocial.prototype.isSupportsNativeInvite = function () {
    return true;
};

SPMobageSocial.prototype.isSupportsNativeRequest = function () {
    return false;
};

SPMobageSocial.prototype.needTokenRefresh = function () {
    if (this.clientData.token && this.clientData.token.expireTime) {
        return this.clientData.token.expireTime <= Date.now();
    }
    return true;
};

SPMobageSocial.prototype.checkAccessToken = function (callback) {
    callback = callback || function () {};

    var sendRequest = function (params) {
        ConnectorRestClient.get(
            params.path,
            {}, 
            function (response) {
                this.clientData.token.accessToken = response.accessToken;
                this.clientData.token.refreshToken = response.refreshToken;
                this.clientData.token.expireTime = Date.now() + (response.expiresIn * 1000);
                // console.log('new accessToken', this.clientData.token);
                callback();
            }.bind(this),
            function () {},
            {
                queryParams: params
            }
        );
    }.bind(this);

    var params = {
        redirect_uri: this.clientData.redirectUrl
    };

    if (!this.clientData.token) {
        this.clientData.token = {};
        params.path = "/spmbgatoken/get";
        params.code = this.clientData.code;
        sendRequest(params);
    } else if (this.needTokenRefresh()) {
        params.path = "/spmbgatoken/refresh";
        params.refresh_token = this.clientData.token.refreshToken;
        sendRequest(params);
    } else {
        callback();
    }
};

SPMobageSocial.prototype._connect = function (callback) {
    try {
        mobage.oauth.getConnectedStatus({ state: this.clientData.state }, function (err, result) {
            if (err) {
                connector.social.mobagePlatformLogin = err.login;
                this.clientData.connected = err.connected;

                callback(connector.STATUS_CONNECTED);
            } else {
                connector.social.mobagePlatformLogin = true;
                this.clientData.connected = true;
                this.clientData.code = result.response.code;

                this.getPlayerInfo(function (code) {
                    callback(code === connector.CODE_SUCCEED ? connector.STATUS_CONNECTED : connector.STATUS_DISCONNECTED);
                });
            }
        }.bind(this));
    } catch (e) {
        console.log("Get connect status error", e);
        callback(connector.STATUS_DISCONNECTED);
    }
};

SPMobageSocial.prototype.onConnected = function () {
    MobageSocial.prototype.onConnected.call(this);

    this._updateRefreshInterval();
};

SPMobageSocial.prototype._updateRefreshInterval = function () {
    if (!this.isConnected() || connector.platform.isPaused) {
        if (this._checkStatusInterval) {
            clearInterval(this._checkStatusInterval);
            this._checkStatusInterval = undefined;
        }
    } else if (!this._checkStatusInterval) {
        this._checkStatusInterval = setInterval(this.periodicCheck.bind(this), connector.utils.parseInterval(SPMobageSocial.CHECK_STATUS_INTERVAL));
    }
};

SPMobageSocial.prototype.getPlayerInfo = function (callback) {
    var params = {
        userId: "@me",
        groupId: "@self",
        fields: ["id", "displayName", "thumbnailUrl"]
    };

    mobage.api.people.get(params, function (error, result) {
        if (error) {
            console.log("Failed requesting user info", error);

            callback(connector.CODE_FAILED);
        } else {
            this.user = SPMobageSocial.formatUserData(result);

            callback(connector.CODE_SUCCEED);
        }
    }.bind(this));
};

SPMobageSocial.prototype._login = function (callback, options) {
    var params = {
        state: this.clientData.state
    };

    if (options && options.registration) {
        params.easyRegistration = true;
        params.requestType = "window";
    }

    mobage.oauth.connect(params, function (err, result) {
        if (err) {
            console.log("Authorization failed:", err);

            callback();
        } else {
            connector.social.mobagePlatformLogin = true;
            this.clientData.connected = true;
            this.clientData.code = result.response.code;

            this.checkAccessToken();

            this.getPlayerInfo(callback);
        }
    }.bind(this));
};

SPMobageSocial.prototype.periodicCheck = function () {
    if (!connector.social.isConnected()) {
        return;
    }

    if (!this.checkInProcess) {
        this.checkInProcess = true;
        try {
            mobage.oauth.getConnectedStatus({ state: this.clientData.state }, function (err, result) {
                this.checkInProcess = false;

                if (result) {
                    this.clientData.code = result.response.code;
                    this.checkAccessToken();
                }
            }.bind(this));
        } catch (e) {
            this.checkInProcess = false;
            console.log("Get connect status error", e);
        }
    }
};

SPMobageSocial.prototype.isLoggedIn = function () {
    return !!this.user;
};

SPMobageSocial.formatUserData = function (user) {
    return {
        id: user.id,
        name: user.displayName,
        avatar: SPMobageSocial.proxyAvatar(user.thumbnailUrl)
    };
};

SPMobageSocial.proxyAvatar = function (url) {
    var base = connector.config.deployment + "/proxy/sp";
    var parsed = url.match(/.*img_u(.*)/);
    return base + parsed[1];
};

SPMobageSocial.prototype.listFriendsByApp = function (inApp, callback) {
    var params = {
        userId: "@me",
        groupId: "@friends",
        fields: ["id", "displayName", "thumbnailUrl", "hasApp"]
    };

    mobage.api.people.get(params, function (error, result) {
        if (error) {
            console.log("Failed fetching friends", error);
            callback(connector.CODE_FAILED);
        } else {
            var friends = result.entry;
            friends = friends.filter(function (friend) {
                return friend.hasApp === inApp;
            });

            callback(connector.CODE_SUCCEED, friends.map(SPMobageSocial.formatUserData));
        }
    });
};

SPMobageSocial.prototype.invite = function (data, callback) {
    mobage.ui.open(
        "invitation",
        {},
        function (error, result) {
            if (error) {
                callback(connector.CODE_FAILED, error);
            } else if (result.response.result.channel === "mobage") {
                callback(connector.CODE_SUCCEED);
            } else {
                callback(connector.CODE_CANCELLED);
            }
        }
    );
};

SPMobageSocial.prototype.request = function (data, callback) {
    callback(connector.CODE_SUCCEED);
};

SPMobageSocial.prototype.share = function (data, callback) {
    callback(connector.CODE_SUCCEED);
};

// eslint-disable-next-line no-unused-vars
SPMobageSocial.prototype.sendActivity = function (data, callback) {
    callback(connector.CODE_FAILED);
};

SPMobageSocial.getClientId = function () {
    return connector.config.mbga.appId + "-4";
};

SPMobageSocial.getRedirectUrl = function () {
    return connector.config.deployment + "/publish/html5" + (connector.config.debugMode ? "-staging" : "") + "/index.html";
};

SPMobageSocial.CHECK_STATUS_INTERVAL = "10 minutes";