/**
 * Created by andrey on 26.06.2023
 */

var PWA = function () {
    Platform.call(this, connector.PWA);
};

PWA.prototype = Object.create(Platform.prototype);
PWA.prototype.constructor = PWA;

PWA.prototype.getLocalStoragePrefix = function () {
    return "_pwa";
};