/**
 * Created by vladislav on 9/7/2022
 */

var FacebookCanvas = function () {
    Platform.call(this, connector.FACEBOOK, {
        sdk: SdkManager.SDK_FACEBOOK_CANVAS
    });
};

FacebookCanvas.prototype = Object.create(Platform.prototype);
FacebookCanvas.prototype.constructor = FacebookCanvas;
