/**
 * Created by andrey on 15.10.19.
 */

var WebViewPayments = function (name) {
    Payments.call(this, name || connector.IAP);

    this.startTime = Date.now();
};

WebViewPayments.prototype = Object.create(Payments.prototype);
WebViewPayments.prototype.constructor = WebViewPayments;

WebViewPayments.prototype.getProductId = function (config) {
    return config.id[connector.platform.oneOf(connector.IOS, connector.MACOS) ? "ios" : connector.info.source];
};

WebViewPayments.prototype._loadSubscriptionsTokens = function (callback) {
    if (connector.platform.oneOf(connector.RUSTORE, connector.AMAZON)) {
        callback(connector.CODE_SUCCEED);
        return;
    }

    connector.platform.callNative("PurchasesPlugin.loadSubscriptionsTokens", function (code, response) {
        if (code !== connector.CODE_SUCCEED) {
            return;
        }

        var purchases = response.purchases || [];

        if (purchases) {
            console.log("WebViewPayments.onLoadSubscriptionsTokensResult purchases - " + purchases);
            this.addSubscriptionTokens(purchases);
        }

        callback(connector.CODE_SUCCEED);
    }.bind(this));
};

WebViewPayments.prototype.addSubscriptionTokens = function (products, restore) {
    if (!Array.isArray(products)) {
        products = [products];
    }

    if (!connector.platform.oneOf(connector.ANDROID, connector.IOS, connector.MACOS)) {
        return;
    }

    var tokens = products.map(function (product) {
        var ourProduct = this.getProduct(product.productId);
        if (ourProduct && ourProduct.type === "subscription") {
            var token = {
                name: ourProduct.itemId
            };

            if (connector.platform.oneOf(connector.ANDROID)) {
                token.purchaseToken = product.purchaseToken || product.receipt.purchaseToken;
                token.packageName = product.packageName || product.receipt.packageName;
                token.productId = product.productId || product.receipt.productId;
            } else {
                token.purchaseToken = product.receipt;
                token.packageName = product.packageName;
                token.productId = product.productId;
            }

            return token;
        }
        return undefined;
    }.bind(this)).filter(function (token) {
        return token;
    });

    if (tokens.length > 0) {
        this.bus.trigger("subscriptionTokensLoaded", tokens, restore);
    }
};

WebViewPayments.prototype._connect = function (callback) {
    var config = connector.config[connector.info.source] || {};

    var player = new ConnectorActionPlayer([
        function (f, stop) {
            if (connector.platform.oneOf(connector.IOS, connector.MACOS)) {
                f();
            } else {
                this.connectCallback = function (code) {
                    if (code === connector.CODE_SUCCEED) {
                        f();
                    } else {
                        stop();
                    }
                };

                connector.platform.callNative("PurchasesPlugin.connect", {
                    appId: config.appId
                });
            }
        }.bind(this),
        function (f, stop) {
            this.loadProducts(function (code) {
                if (connector.platform.oneOf(connector.ANDROID) && connector._countryManager.country === "RU") {
                    stop(connector.STATUS_DISABLED);
                    return;
                }

                if (code === connector.CODE_SUCCEED) {
                    f();
                } else {
                    stop();
                }
            });
        }.bind(this)
    ]);

    player.onSuccess(function () {
        callback(connector.STATUS_CONNECTED);
    });

    player.onFailure(function (status) {
        callback(status || connector.STATUS_DISCONNECTED);
    });

    player.play();
};

WebViewPayments.prototype.onCreated = function () {
    Payments.prototype.onCreated.call(this);

    if (!connector.platform.oneOf(connector.IOS, connector.MACOS)) {
        connector.platform.subscribeNative("WebViewPayments", this.onNativeEvent.bind(this));
    }
};

WebViewPayments.prototype.onRemoved = function () {
    Payments.prototype.onRemoved.call(this);

    connector.platform.unsubscribeNative("WebViewPayments");
};

WebViewPayments.prototype.onNativeConnected = function (connected) {
    console.log("WebViewPayments.updateConnected - " + connected);

    if (this.connectCallback) {
        this.connectCallback(connected ? connector.CODE_SUCCEED : connector.CODE_FAILED);
        delete this.connectCallback;
    }
};

WebViewPayments.prototype.onNativeEvent = function (name, options) {
    switch (name) {
        case "updateConnected":
            this.onNativeConnected(options.connected); break;
        case "requestRestore":
            connector._paymentsManager.restoreThrottled(); break;
    }
};

WebViewPayments.prototype.getStoreProductIds = function (type) {
    return this.listProductsOfType(type).map(function (product) {
        return product.productId;
    }).join(",");
};

WebViewPayments.prototype._loadProducts = function (callback) {
    var options = {
        productIds: this.getStoreProductIds("consumable")
    };

    var nonConsumableProductIds = this.getStoreProductIds("nonConsumable");
    if (nonConsumableProductIds) {
        options.nonConsumableProductIds = nonConsumableProductIds;
    }

    connector.platform.callNative("PurchasesPlugin.loadProducts", options, function (code, response) {
        var products = response.products || [];
        var loadedIds = products.map(function (product) {
            return product.productId;
        });

        this.onLoadResult(code, "inapp", products);

        callback(code, loadedIds);
    }.bind(this));
};

WebViewPayments.prototype._loadSubscriptions = function (callback) {
    if (connector.platform.oneOf(connector.RUSTORE, connector.AMAZON)) {
        callback(connector.CODE_SUCCEED, []);
        return;
    }

    connector.platform.callNative("PurchasesPlugin.loadSubscriptions", { productIds: this.getStoreProductIds("subscription") }, function (code, response) {
        var products = response.products || [];
        var loadedIds = products.map(function (product) {
            return product.productId;
        });

        this.onLoadResult(code, "subscription", products);

        callback(code, loadedIds);
    }.bind(this));
};

WebViewPayments.prototype.onLoadResult = function (code, type, products) {
    console.log("WebViewPayments.onLoadResult code - " + code + " type - " + type);
    if (code !== connector.CODE_SUCCEED) {
        console.log("WebViewPayments.onLoadResult failure");
        return;
    }

    console.log("WebViewPayments.onLoadResult products - " + JSON.stringify(products));

    var currency;

    products.forEach(function (product) {
        var ourProduct = this.getProduct(product.productId);
        var productCurrency = product.price_currency_code;
        currency = currency || productCurrency;

        if (ourProduct !== undefined) {
            ourProduct.textPrice = product.price;
            ourProduct.currency = productCurrency;
            if (connector.platform.oneOf(connector.AMAZON)) {
                var match = product.price.match(/[0-9.,]+/g);
                if (match && match[0]) {
                    ourProduct.price = parseFloat(match[0].replace(",", "."));
                } else {
                    this.bus.trigger("stream:error", "Error parsing amazon price " + product.price);
                }
            } else {
                ourProduct.price = Math.round(product.price_amount_micros / 1000) / 1000;
            }

            console.log("WebViewPayments product " + product.productId + " " + ourProduct.textPrice + " " + ourProduct.currency + " " + ourProduct.price);
        }
    }.bind(this));

    if (currency) {
        this.currency = currency;
        connector._countryManager.setCurrency(currency);
    }
};

WebViewPayments.prototype.extractPaymentId = function (nativePurchase) {
    return (nativePurchase.orderId || nativePurchase.receipt.orderId || "").substring(0, 50);
};

WebViewPayments.prototype.extractPayload = function (nativePurchase) {
    var payload = {};

    if (connector.platform.oneOf(connector.IOS, connector.MACOS)) {
        payload.packageName = nativePurchase.packageName;
        payload.receipt = nativePurchase.receipt;
        payload.event = nativePurchase.purchase_date_ms || nativePurchase.purchaseDate;
    } else if (connector.platform.oneOf(connector.ANDROID, connector.GPG)) {
        payload.packageName = nativePurchase.receipt.packageName;
        payload.receipt = nativePurchase.receipt.purchaseToken;
        payload.event = nativePurchase.receipt.purchaseTimeMillis || nativePurchase.receipt.purchaseTime;

        payload.purchaseState = nativePurchase.purchaseState;
    }

    if (connector.platform.oneOf(connector.RUSTORE)) {
        payload.subscriptionToken = nativePurchase.subscriptionToken;
    }

    return payload;
};

WebViewPayments.prototype.purchase = function (product, callback) {
    connector.platform.callNative("PurchasesPlugin.purchase", { productId: product.productId }, function (code, response) {
        if (code === connector.CODE_CANCELLED) {
            callback(ERRORS.PURCHASE.CANCEL);
            return;
        }

        if (code !== connector.CODE_SUCCEED) {
            callback(ERRORS.PURCHASE.UNKNOWN);
            return;
        }

        var nativePurchase = response.purchase;
        if (!nativePurchase) {
            this.bus.trigger("stream:error", "WebViewPayments.purchase no nativePurchase productId: " + product.productId);

            callback(ERRORS.PURCHASE.UNKNOWN);
            return;
        }

        var paymentId = this.extractPaymentId(nativePurchase);
        var payload = this.extractPayload(nativePurchase);

        console.log("WebViewPayments.purchase paymentId - " + paymentId);
        console.log("WebViewPayments.purchase nativePurchase - " + JSON.stringify(nativePurchase));

        var purchase = this.createPurchase(paymentId, product, payload);

        this.addSubscriptionTokens(nativePurchase);

        callback(connector.CODE_SUCCEED, purchase);
    }.bind(this));
};

WebViewPayments.prototype.validate = function (purchase, callback) {
    if (!connector.platform.oneOf(connector.ANDROID, connector.IOS, connector.MACOS, connector.GPG)) {
        callback(connector.CODE_SUCCEED, purchase);
        return;
    }

    var data = {
        purchase: purchase,
        version: 2
    };

    if (connector.platform.oneOf(connector.IOS, connector.MACOS)) {
        data.platform = "apple";
    } else {
        data.platform = "google";
    }

    ConnectorRestClient.post("/purchasevalidator/validate", data, function (response) {
        if (response.error) {
            console.log("WebViewPayments.validate Failure: " + response.error);
            callback(connector.CODE_FAILED, purchase);
            return;
        }

        purchase.paymentId = purchase.paymentId
            || response.receipt && response.receipt.orderId
            || response.transaction_id || response.transactionId;
        console.log("WebViewPayments.validate Success: " + JSON.stringify(response));
        callback(connector.CODE_SUCCEED, purchase);
    }, function () {
        console.log("WebViewPayments.validate onError");
        callback(connector.CODE_CANCELLED);
    });
};

WebViewPayments.prototype.getTextPrice = function (price) {
    price = String(price);
    if (price.indexOf(".") === -1) {
        price += ".00";
    }

    return Payments.prototype.getTextPrice.call(this, price);
};

WebViewPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.ANDROID, connector.AMAZON, connector.IOS, connector.MACOS);
};

WebViewPayments.prototype.restore = function (callback) {
    if (!connector.platform.oneOf(connector.ANDROID, connector.GPG, connector.IOS, connector.MACOS, connector.RUSTORE)) {
        callback(connector.CODE_FAILED);
        return;
    }

    connector.platform.callNative("PurchasesPlugin.restore", function (code, response) {
        if (code !== connector.CODE_SUCCEED) {
            callback(connector.CODE_FAILED);
            return;
        }

        var nativePurchase = response.purchase;

        if (!nativePurchase) {
            this.bus.trigger("stream:error", "WebViewPayments.restore no nativePurchase");

            callback(connector.CODE_FAILED);
            return;
        }

        var paymentId = this.extractPaymentId(nativePurchase);
        if (!paymentId) {
            this.bus.trigger("stream:error", "WebViewPayments.restore no paymentId nativePurchase: " + JSON.stringify(nativePurchase));

            callback(connector.CODE_FAILED);
            return;
        }

        var productId;
        if (connector.platform.oneOf(connector.IOS, connector.MACOS)) {
            productId = nativePurchase.productId;
        } else {
            productId = nativePurchase.productId || nativePurchase.receipt.productId;
        }

        if (!productId) {
            this.bus.trigger("stream:error", "WebViewPayments.restore no productId nativePurchase: " + JSON.stringify(nativePurchase));

            callback(connector.CODE_FAILED);
            return;
        }

        var product = this.getProduct(productId);
        if (!product) {
            this.bus.trigger("stream:error", "WebViewPayments.restore no product for productId: " + productId + " nativePurchase: " + JSON.stringify(nativePurchase));

            callback(connector.CODE_FAILED);
            return;
        }

        var payload = this.extractPayload(nativePurchase);

        var purchase = this.createPurchase(paymentId, product, payload);

        callback(connector.CODE_SUCCEED, purchase);
    }.bind(this));
};

WebViewPayments.prototype.consume = function (purchase, callback) {
    if (connector.platform.oneOf(connector.ANDROID, connector.IOS, connector.MACOS, connector.RUSTORE)) {
        connector.platform.callNative("PurchasesPlugin.consumePurchase", {
            orderId: purchase.paymentId,
            productId: purchase.productId
        });
    }

    callback(connector.CODE_SUCCEED);
};

WebViewPayments.prototype.restoreSubscriptions = function (callback) {
    callback = connector.utils.once(callback);

    if (!connector.payments.isConnected()) {
        callback(connector.CODE_FAILED);
        return;
    }

    connector.platform.callNative("PurchasesPlugin.restoreSubscriptions", function (code, response) {
        var purchases = response.purchases;

        console.log("WebViewPayments.onRestoreSubscriptionsResult code - " + code);

        if (purchases) {
            console.log("IOSPayments.onRestoreSubscriptionsResult purchases - " + purchases.length, purchases);
        }

        if (code === connector.CODE_SUCCEED) {
            callback(connector.CODE_SUCCEED);
            this.addSubscriptionTokens(purchases, true);
        } else {
            callback(connector.CODE_FAILED);
        }
    }.bind(this));
};
