/**
 * Created by vladislav on 4/22/2024
 */

// eslint-disable-next-line no-unused-vars
var connector = {};

window.connector = connector;

connector.AMAZON = "amazon";
connector.ANDROID = "android";
connector.APPLE = "apple";
connector.CLEVERAPPS = "cleverapps";
connector.CRAZY = "crazy";
connector.DRAUGIEM = "draugiem";
connector.FACEBOOK = "facebook";
connector.FOTOSTRANA = "fotostrana";
connector.GDCOM = "gdcom";
connector.COOLMATHGAMES = "coolmath";
connector.GPG = "gpg";
connector.INSTANT = "instant";
connector.IOS = "ios";
connector.KONGREGATE = "kongregate";
connector.MACOS = "macos";
connector.MICROSOFT = "microsoft";
connector.MOBAGE = "mbga";
connector.SP_MOBAGE = "sp_mbga";
connector.MSSTART = "msstart";
connector.MYGAMES = "mygames";
connector.MM = "mm";
connector.OK = "ok";
connector.PLAYABLE = "playable";
connector.PLIEGA = "pliega";
connector.PLINGA = "plng";
connector.PWA = "pwa";
connector.RUSTORE = "rustore";
connector.SAMSUNG = "samsung";
connector.TEST = "test";
connector.TON = "ton";
connector.VK = "vk";
connector.WECHAT = "wechat";
connector.WORTAL = "wortal";
connector.XIAOMI = "xiaomi";
connector.YANDEX = "yandex";
connector.PLAYHOP = "playhop";
connector.YOUTUBE = "youtube";
connector.GAMESNACK = "gamesnack";
connector.DISCORD = "discord";

connector.XSOLLA = "xsolla";
connector.IAP = "iap";
connector.YOOKASSA = "yookassa";

connector.AD_MOB = "ad_mob";
connector.APP_LOVIN = "app_lovin";
connector.GOOGLE_ADS = "google_ads";
connector.MULTI_ADS = "multi_ads";
connector.YANDEX_APP_ADS = "yandex_app_ads";
connector.YANDEX_WEB_ADS = "yandex_web_ads";

connector.MULTI_SOCIAL = "multi_social";

connector.NONE = "none";