/**
 * https://assets.msn.com/staticsb/statics/latest/msstart-games-sdk/documentation/docs/in-game-rewarded-ads-apis/index.html
 * https://assets.msn.com/staticsb/statics/latest/msstart-games-sdk/documentation/docs/in-game-interstitial-ads-apis/index.html
 *
 * https://www.msn.com/en-us/play/games/testing/cg-test-game?dev-playground=https://mergecraft.labsystech.ru/publish/html5-staging/msstart&item=flights:prg-cg-in-gm-rwd-ads
 *
 * Created by Aleksandr on 16.06.2023
 */

var MSStartAds = function () {
    AdsPlugin.call(this, connector.MSSTART, {
        sdk: SdkManager.SDK_MSSTART,
        cantLoadAndPlayInParallel: true,
        expirationPeriod: connector.utils.parseInterval("60 minutes")
    });

    this.cachedPrevious = {};
};

MSStartAds.prototype = Object.create(AdsPlugin.prototype);
MSStartAds.prototype.constructor = MSStartAds;

MSStartAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.MSSTART);// && !connector.config.debugMode;
};

MSStartAds.prototype._cacheAd = function (name, callback) {
    $msstart.loadAdsAsync(name === AdsPlugin.REWARDED).then(function (adInstance) {
        this.cachedPrevious[name] = { instanceId: adInstance.instanceId, _previousAd: true };
        callback(connector.CODE_SUCCEED, { instanceId: adInstance.instanceId });
    }.bind(this)).catch(function (error) {
        var errorCode = this.extractErrorCode(error);

        callback(AdsPlugin.ERROR_LOADING_FAILED);

        this.bus.trigger("error:details:msstart:cache", errorCode, error);
    }.bind(this));
};

MSStartAds.prototype._playAd = function (name, adInstance, callback) {
    var onFinish = connector.utils.once(function (code) {
        callback(code);
    });

    var onError = function (error) {
        var errorCode = this.extractErrorCode(error);

        if (name === AdsPlugin.REWARDED) {
            onFinish(this.parseRewardedError(errorCode));
        } else {
            onFinish(this.parseInterstitialError(errorCode));
        }

        this.bus.trigger("error:details:msstart:play", errorCode, error);
    }.bind(this);

    $msstart.showAdsAsync(adInstance.instanceId).then(function (adInstance) {
        adInstance.showAdsCompletedAsync.then(function () {
            onFinish(connector.CODE_SUCCEED);
        }).catch(onError);
    }).catch(onError);
};

MSStartAds.prototype.parseRewardedError = function (errorCode) {
    switch (errorCode) {
        case "REQUEST_THROTTLED":
            return ERRORS.SHOW_REWARDED.THROTTLED;
        case "USER_CANCELED":
            return ERRORS.SHOW_REWARDED.CLOSED_BY_USER;
        case "FAILED_BY_TIMEOUT":
        case "NO_LOADED_ADS_WITH_ID":
        default:
            return ERRORS.SHOW_REWARDED.UNKNOWN;
    }
};

MSStartAds.prototype.parseInterstitialError = function (errorCode) {
    switch (errorCode) {
        case "REQUEST_THROTTLED":
            return ERRORS.SHOW_INTERSTITIAL.THROTTLED;
        case "USER_CANCELED":
            return ERRORS.SHOW_INTERSTITIAL.CLOSED_BY_USER;
        case "FAILED_BY_TIMEOUT":
        case "NO_LOADED_ADS_WITH_ID":
        default:
            return ERRORS.SHOW_INTERSTITIAL.UNKNOWN;
    }
};

MSStartAds.prototype.extractErrorCode = function (originalError) {
    originalError = originalError || {};
    var extraError = originalError.extra && originalError.extra.error || {};
    var specialCode = {
        "No ad loader found.": "NO_AD_LOADER_FOUND",
        "Ads already loaded.": "AD_ALREADY_LOADED",
        "Ads is being loaded.": "AD_IS_BEING_LOADED",
        "No loaded ads with the given id found.": "NO_LOADED_ADS_WITH_ID",
        "Ad requires active tab.": "AD_REQUIRES_ACTIVE_TAB",
        "Failed by timeout.": "FAILED_BY_TIMEOUT" // code:COMPLETED_FAILURE,description:Showing In-Game Ads failed after started.
    }[extraError];

    specialCode = specialCode || {
        "User closed rewarded ad before completion.": "USER_CANCELED"
    }[originalError.description];

    if (extraError.code === 0) {
        extraError.code = "0";
    }

    var errorCode = originalError.code.replace("LOAD_ADS_CLIENT_FAILURE", "LOAD_ADS_FAILURE")
        .replace("LOAD_ADS_", "")
        .replace("SHOW_ADS_CLIENT_FAILURE", "SHOW_ADS_FAILURE")
        .replace("SHOW_ADS_", "");

    return specialCode || (errorCode + (extraError.code || ""));
};