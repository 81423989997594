/**
 * https://yandex.ru/dev/games/doc/ru/sdk/sdk-adv
 *
 * Created by iamso on 22.07.21
 */

var YandexAds = function () {
    AdsPlugin.call(this, connector.YANDEX, {
        sdk: SdkManager.SDK_YANDEX
    });
};

YandexAds.prototype = Object.create(AdsPlugin.prototype);
YandexAds.prototype.constructor = YandexAds;

YandexAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.YANDEX);
};

YandexAds.prototype._playAd = function (name, ad, callback) {
    if (name === AdsPlugin.REWARDED) {
        this._playRewardedAd(callback);
    } else {
        this._playInterstitialAd(callback);
    }
};

YandexAds.prototype._playRewardedAd = function (callback) {
    var rewarded = false;

    ysdk.adv.showRewardedVideo({
        callbacks: {
            onRewarded: function () {
                rewarded = true;
            },
            onClose: function () {
                if (rewarded) {
                    callback(connector.CODE_SUCCEED);
                } else {
                    callback(ERRORS.SHOW_REWARDED.CLOSED_BY_USER);
                }
            },
            onError: function (error) {
                var code = this.parseError(AdsPlugin.REWARDED, error);

                callback(code);

                if (code && code === ERRORS.SHOW_REWARDED.UNKNOWN) {
                    this.bus.trigger("error:details:yandex:showRewarded", error);
                }
            }.bind(this)
        }
    });
};

YandexAds.prototype._playInterstitialAd = function (callback) {
    ysdk.adv.showFullscreenAdv({
        callbacks: {
            onClose: function (wasShown) {
                callback(wasShown ? connector.CODE_SUCCEED : ERRORS.SHOW_INTERSTITIAL.NO_ADS);
            },
            onError: function (error) {
                var code = this.parseError(AdsPlugin.INTERSTITIAL, error);

                callback(code);

                if (code && code === ERRORS.SHOW_REWARDED.UNKNOWN) {
                    this.bus.trigger("error:details:yandex:showInterstitial", error);
                }
            }.bind(this)
        }
    });
};

YandexAds.prototype.parseError = function (type, error) {
    var message = String(error);

    var knownErrors = [{
        match: ["No rewarded video to show now", "No adv to show now", "No available waterfall item for tag",
            "Cannot open the advertisement. Try again later."],
        code: type === AdsPlugin.REWARDED ? ERRORS.SHOW_REWARDED.NO_ADS : ERRORS.SHOW_INTERSTITIAL.NO_ADS
    }, {
        match: ["Rewarded Video timeout", "Message rejected by timeout", "Fullscreen preload timeout"],
        code: type === AdsPlugin.REWARDED ? ERRORS.SHOW_REWARDED.UNKNOWN : ERRORS.SHOW_INTERSTITIAL.UNKNOWN
    }, {
        match: ["AdBlock prevents yandex ad to show"],
        code: type === AdsPlugin.REWARDED ? ERRORS.SHOW_REWARDED.AD_BLOCK : ERRORS.SHOW_INTERSTITIAL.AD_BLOCK
    }, {
        match: ["Please, don't try to open advertising often than once per", "Adv was delayed on"],
        code: type === AdsPlugin.REWARDED ? ERRORS.SHOW_REWARDED.THROTTLED : ERRORS.SHOW_INTERSTITIAL.THROTTLED
    }, {
        match: ["Another ad already opened"],
        code: type === AdsPlugin.REWARDED ? ERRORS.SHOW_REWARDED.AD_ALREADY_RUNNING : ERRORS.SHOW_INTERSTITIAL.AD_ALREADY_RUNNING
    }];

    var knownError = knownErrors.find(function (error) {
        return error.match.some(function (regexp) {
            return message.match(regexp);
        });
    });

    if (knownError) {
        return knownError.code;
    }

    return type === AdsPlugin.REWARDED ? ERRORS.SHOW_REWARDED.UNKNOWN : ERRORS.SHOW_INTERSTITIAL.UNKNOWN;
};