/**
 * Created by vladislav on 1/31/2024
 */

var SimpleDataLoader = function () {
    this.localStorage = new HtmlLocalStorage();

    this.data = {};
    this.enabled = true;
};

SimpleDataLoader.prototype.setEnabled = function (enabled) {
    this.enabled = Boolean(enabled);
};

SimpleDataLoader.prototype.getLocalStorage = function () {
    return this.localStorage;
};

SimpleDataLoader.prototype.load = function (key) {
    // eslint-disable-next-line no-prototype-builtins
    if (!this.data.hasOwnProperty(key)) {
        this.data[key] = this.localStorage.getItem(key);
    }

    var stored = this.data[key];

    if (stored !== null && stored !== undefined && stored !== "undefined") {
        return JSON.parse(stored);
    }

    return undefined;
};

SimpleDataLoader.prototype.save = function (key, data) {
    var string = JSON.stringify(data);

    this.data[key] = string;

    if (this.enabled) {
        this.localStorage.setItem(key, string);
    }
};

SimpleDataLoader.prototype.remove = function (key) {
    delete this.data[key];

    if (this.enabled) {
        this.localStorage.removeItem(key);
    }
};

SimpleDataLoader.prototype.cleanAll = function () {
    this.data = {};

    this.localStorage.clearData(SimpleDataLoader.GetLocalStoragePrefix());
};

SimpleDataLoader.GetLocalStoragePrefix = function () {
    return "pc_" + connector.config.projectId + connector.platform.getPlugin().getLocalStoragePrefix();
};

SimpleDataLoader.INIT_TYPES = function (migrate) {
    var prefix = SimpleDataLoader.GetLocalStoragePrefix();

    if (migrate) {
        var oldPrefix = connector.config.projectId + connector.platform.getPlugin().getLocalStoragePrefix();

        if (connector.dataLoader.load(oldPrefix + SimpleDataLoader.TYPES.USER_ID) && !connector.dataLoader.load(prefix + SimpleDataLoader.TYPES.USER_ID)) {
            Object.values(SimpleDataLoader.TYPES).forEach(function (key) {
                var value = connector.dataLoader.load(oldPrefix + key);
                if (value !== undefined) {
                    connector.dataLoader.save(prefix + key, value);
                }
            });
        }
    }

    Object.keys(SimpleDataLoader.TYPES).forEach(function (key) {
        SimpleDataLoader.TYPES[key] = prefix + SimpleDataLoader.TYPES[key];
    });
};

SimpleDataLoader.TYPES = {
    USER_ID: "_user_id",
    SHORTCUT_CREATED: "_instant_shortcut_created",
    DRAUGIEM_AUTH_DATA: "_draugiem_auth_data",
    XSOLLA_ACCESS_TOKEN: "_xsolla_access_token",
    APPLE_SOCIAL: "_apple_social",
    MS_SOCIAL: "_ms_social",
    PAYMENTS_COUNTRY: "_payments_country",
    XSOLLA2_PAYMENTS_AUTH: "_xsolla2_payments_auth",
    PAYMENTS: "_payments",
    ANDROID_INSTALL_REFERRER: "_android_install_referrer",
    TESTPAYMENTS: "_test_payments"
};
