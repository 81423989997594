/**
 * Created by vladislav on 1/11/2024
 */

var BasePlugin = function (pluginType, pluginName, options) {
    options = options || {};

    this.bus = new ConnectorEventEmitter();

    this.pluginType = pluginType;
    this.pluginName = pluginName;
    this.sdk = options.sdk;

    this.status = undefined;
};

BasePlugin.prototype.onCreated = function () {

};

BasePlugin.prototype.onRemoved = function () {

};

BasePlugin.prototype.canConnect = function () {
    return true;
};

BasePlugin.prototype.connect = function () {
    if (this.connecting || this.isConnected()) {
        return;
    }

    if (!this.canConnect()) {
        return;
    }

    this.connecting = true;

    console.log("[connector] connecting", this.pluginType, this.pluginName);

    var onComplete = function (status, reason) {
        this.connecting = false;

        status = status || connector.STATUS_DISCONNECTED;

        this.setStatus(status, reason);
        this.onConnected();
    }.bind(this);

    if (this.sdk) {
        this.sdkEventHandler = connector.sdk.on(this.sdk, this.onSdkEvent.bind(this));
        connector.sdk.loadSdk(this.sdk, function (code) {
            if (code === connector.CODE_SUCCEED) {
                this._connect(connector.utils.waitNoMore(BasePlugin.CONNECT_TIMEOUT, onComplete));
            } else {
                onComplete(connector.STATUS_DISCONNECTED);
            }
        }.bind(this));
    } else {
        this._connect(connector.utils.waitNoMore(BasePlugin.CONNECT_TIMEOUT, onComplete));
    }
};

BasePlugin.prototype._connect = function (callback) {
    callback(connector.STATUS_CONNECTED);
};

BasePlugin.prototype.onConnected = function () {

};

BasePlugin.prototype.disconnect = function () {
    this.setStatus(connector.STATUS_DISCONNECTED);
};

BasePlugin.prototype.setStatus = function (status, reason) {
    if (this.status === status) {
        return;
    }

    console.log("[connector] setStatus", this.pluginType, this.pluginName, status);

    this.status = status;

    if (this.status === connector.STATUS_DISCONNECTED) {
        if (this.sdkEventHandler) {
            this.sdkEventHandler.clear();
            this.sdkEventHandler = undefined;
        }
    }

    this.bus.trigger("changeStatus", this.status, reason);
};

BasePlugin.prototype.isConnected = function () {
    return this.status === connector.STATUS_CONNECTED;
};

BasePlugin.prototype.whenConnected = function (callback) {
    if (this.isConnected()) {
        callback();
    } else {
        var listener = this.bus.on("changeStatus", function (status) {
            if (status === connector.STATUS_CONNECTED) {
                listener.clear();
                callback();
            }
        });
    }
};

BasePlugin.prototype.onSdkEvent = function () {
    
};

BasePlugin.prototype.oneOf = function (arr) {
    arr = Array.isArray(arr) ? arr : Array.prototype.slice.call(arguments);

    if (arr.some(function (name) {
        return !name || typeof name !== "string";
    })) {
        throw "Invalid plugin name";
    }

    return arr.includes(this.pluginName);
};

BasePlugin.CONNECT_TIMEOUT = connector.utils.parseInterval("10 seconds");