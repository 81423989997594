/**
 * Created by iamso on 22.07.21
 */

var YandexPayments = function () {
    Payments.call(this, connector.YANDEX, {
        sdk: SdkManager.SDK_YANDEX
    });
};

YandexPayments.prototype = Object.create(Payments.prototype);
YandexPayments.prototype.constructor = YandexPayments;

YandexPayments.prototype.getDefaultCurrency = function () {
    return "YAN";
};

YandexPayments.prototype.getProductId = function (product) {
    return product.id.yandex;
};

YandexPayments.prototype._connect = function (callback) {
    ysdk.getPayments({ signed: true }).then(function (payments) {
        this.yaPayments = payments;

        callback(connector.STATUS_CONNECTED);
    }.bind(this)).catch(function (err) {
        console.log("YandexPayments connect error", err);

        callback(connector.STATUS_DISCONNECTED);
    });
};

YandexPayments.prototype._loadProducts = function (callback) {
    this.yaPayments.getCatalog().then(function (products) {
        var loadedIds = products.map(function (product) {
            return product.id;
        });

        products.forEach(function (product) {
            var ourProduct = this.getProduct(product.id);
            if (ourProduct) {
                ourProduct.price = product.priceValue;
                ourProduct.textPrice = this.getTextPrice(product.priceValue);
                // ourProduct.textPrice = product.price;
                // ourProduct.currency = product.priceCurrencyCode;
                ourProduct.currencyImage = product.getPriceCurrencyImage && product.getPriceCurrencyImage("medium");
            }
        }, this);

        callback(connector.CODE_SUCCEED, loadedIds);
    }.bind(this)).catch(function (err) {
        console.log("Failed prices check", err);

        callback(connector.CODE_FAILED);
    });
};

YandexPayments.prototype.purchase = function (product, callback) {
    this.yaPayments.purchase({
        id: product.productId,
        developerPayload: JSON.stringify({
            userId: connector.platform.getUserID(),
            itemId: product.itemId
        })
    }).then(function (yandexPurchase) {
        var purchase = this.createPurchase(yandexPurchase.purchaseToken, product, {
            signature: yandexPurchase.signature
        });

        console.log("success", purchase);

        callback(connector.CODE_SUCCEED, purchase);
    }.bind(this)).catch(function (e) {
        this.bus.trigger("stream:error", "YandexPayments.purchase", e);

        console.log("error", e);
        callback(ERRORS.PURCHASE.UNKNOWN);
    }.bind(this));
};

YandexPayments.prototype.consume = function (purchase, callback) {
    // console.log("consume start", purchase);

    this.yaPayments.consumePurchase(purchase.paymentId).then(function () {
        console.log("consume success", purchase);
        callback(connector.CODE_SUCCEED);
    }).catch(function (e) {
        this.bus.trigger("stream:error", "YandexPayments.consume", e);

        console.log("consume error", e);
        callback(ERRORS.CONSUME.UNKNOWN);
    }.bind(this));
};

YandexPayments.prototype.isRestoreAvailable = function () {
    return connector.social.isLoggedIn() && Boolean(this.yaPayments);
};

YandexPayments.prototype.restore = function (callback) {
    this.yaPayments.getPurchases().then(function (purchases) {
        console.log("getPurchases", purchases);

        if (purchases.length === 0) {
            callback(connector.CODE_FAILED);
            return;
        }

        var yandexPurchase = purchases[0];

        var productId = JSON.parse(yandexPurchase.developerPayload).itemId;

        var product = this.getProduct(productId);

        var purchase = this.createPurchase(yandexPurchase.purchaseToken, product, {
            signature: purchases.signature
        });

        callback(connector.CODE_SUCCEED, purchase);
    }.bind(this)).catch(function (e) {
        this.bus.trigger("stream:error", "YandexPayments.restore", e);

        callback(connector.CODE_FAILED);

        console.log("error getPurchases", e);
    }.bind(this));
};

YandexPayments.prototype.validate = function (purchase, callback) {
    var data = {
        purchase: purchase,
        version: 2
    };

    ConnectorRestClient.post("/yandexpayments/validate", data, function (res) {
        var code = res === "ok" ? connector.CODE_SUCCEED : connector.CODE_FAILED;
        callback(code, purchase);
    }, function () {
        callback(connector.CODE_CANCELLED, purchase);
    });
};

YandexPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.YANDEX);
};
