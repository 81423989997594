/**
 * Created by Aleksandr on 06.10.2022.
 */

var PlingaAds = function () {
    AdsPlugin.call(this, connector.PLINGA, {
        sdk: SdkManager.SDK_PLINGA
    });
};

PlingaAds.prototype = Object.create(AdsPlugin.prototype);
PlingaAds.prototype.constructor = PlingaAds;

PlingaAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.PLINGA);
};

PlingaAds.prototype._connect = function (callback) {
    if (typeof plingaRpc !== "undefined" && plingaRpc.requestRewardedAdvertisement !== "undefined" && plingaRpc.requestAdvertisement !== "undefined") {
        console.log("PlingaAds inited");

        callback(connector.STATUS_CONNECTED);
    } else {
        callback(connector.STATUS_DISCONNECTED);
    }
};

PlingaAds.prototype._playAd = function (name, ad, callback) {
    callback = connector.utils.once(callback);

    try {
        if (name === AdsPlugin.REWARDED) {
            plingaRpc.requestRewardedAdvertisement(function () {
                callback(connector.CODE_SUCCEED);
            }, function () {
                callback(ERRORS.SHOW_REWARDED.NO_ADS);
            });
        } else {
            plingaRpc.requestAdvertisement(function () {
                callback(connector.CODE_SUCCEED);
            }, function () {
                callback(ERRORS.SHOW_INTERSTITIAL.NO_ADS);
            });
        }
    } catch (e) {
        if (name === AdsPlugin.REWARDED) {
            callback(ERRORS.SHOW_REWARDED.UNKNOWN);
        } else {
            callback(ERRORS.SHOW_INTERSTITIAL.UNKNOWN);
        }
    }
};

PlingaAds.prototype.getECPM = function () {
    return connector.ads.eCPM.Plinga;
};
