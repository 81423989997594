/**
 * Created by vladislav on 2/9/2024
 */

/**
 *
 * Info about the player.
 *
 * You can wait for player initialization via promise or event:
 *  ``` javascript
 *  await connector.player.ready;
 *  console.log(connector.player.name);
 *  console.log(connector.player.avatar);
 *
 *  // or subscribe to event
 *  connector.player.on("ready", () => {
 *    console.log(connector.player.name);
 *    console.log(connector.player.avatar);
 *  });
 *
 *  // subscribe to player info change
 *  connector.player.on("changed", () => {});
 *  ```
 *
 * @namespace Player
 * */

var PlayerInterface = function () {
    return {
        on: function (eventName, callback) {
            return connector._playerManager.bus.on(eventName, callback);
        },
        once: function (eventName, callback) {
            return connector._playerManager.bus.once(eventName, callback);
        },
        /**
         * @type {Promise}
         * @memberof Player
         * @desc Returns promise you can use to wait for player init
         * */
        get ready() {
            return new Promise(function (resolve) {
                connector._playerManager.whenReady(resolve);
            });
        },
        whenReady: function (callback) {
            connector._playerManager.whenReady(callback);
        },
        /**
         * @type {string}
         * @memberof Player
         * @desc Player name
         * */
        get name() {
            return connector._playerManager.name;
        },
        /**
         * @type {string}
         * @memberof Player
         * @desc Player first name
         * */
        get firstName() {
            return connector._playerManager.firstName;
        },
        /**
         * @type {string}
         * @memberof Player
         * @desc Player last name
         * */
        get lastName() {
            return connector._playerManager.lastName;
        },
        /**
         * @type {string}
         * @memberof Player
         * @desc Player id
         * */
        get id() {
            return connector.platform.getUserID();
        },
        /**
         * @type {string}
         * @memberof Player
         * @desc Player avatar URL
         * */
        get avatar() {
            return connector._playerManager.avatar;
        }
    };
};