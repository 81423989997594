/**
 * Created by vladislav on 2/13/2024
 */

var CommonInterface = function () {
    var bus = new ConnectorEventEmitter();
    var plugin = undefined;

    return {
        _refreshPlugin: function () {
            var PluginClass = this._detectPlugin();

            if (!PluginClass || plugin && plugin.constructor === PluginClass) {
                return;
            }

            this._installPlugin(new PluginClass());

            this.connect();
        },

        _installPlugin: function (newPlugin) {
            this._removePlugin();

            plugin = newPlugin;

            console.log("[connector] create plugin", plugin.pluginType, plugin.pluginName);

            plugin.onCreated();

            plugin.bus.setProxy(bus);

            bus.trigger("pluginChanged");
        },

        _removePlugin: function () {
            if (plugin) {
                console.log("[connector] remove plugin", plugin.pluginType, plugin.pluginName);

                plugin.disconnect();
                plugin.onRemoved();

                plugin.bus.setProxy(undefined);
                plugin = undefined;
            }
        },

        getPlugin: function () {
            return plugin;
        },

        getName: function () {
            if (plugin) {
                return plugin.pluginName;
            }

            return undefined;
        },

        on: function (eventName, callback) {
            return bus.on(eventName, callback);
        },

        once: function (eventName, callback) {
            return bus.once(eventName, callback);
        },

        oneOf: function (arr) {
            return plugin && plugin.oneOf(Array.isArray(arr) ? arr : Array.prototype.slice.call(arguments));
        },

        getStatus: function () {
            if (!plugin) {
                return connector.STATUS_DISABLED;
            }

            return plugin.status || connector.STATUS_DISCONNECTED;
        },

        connect: function () {
            if (plugin) {
                plugin.connect();
            }
        },

        disconnect: function () {
            if (plugin) {
                plugin.disconnect();
            }
        },

        isConnected: function () {
            if (plugin) {
                return plugin.isConnected();
            }

            return false;
        },

        whenConnected: function (callback) {
            if (plugin && plugin.isConnected()) {
                callback();
            } else {
                var listener = bus.on("changeStatus", function (status) {
                    if (status === connector.STATUS_CONNECTED) {
                        listener.clear();
                        callback();
                    }
                });
            }
        }
    };
};
