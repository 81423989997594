/**
 * Created by vladislav on 2/20/2024
 */

connector.EXCHANGE_RATES = {
    USD: 1,
    EUR: 1,
    RUB: 90,
    GBP: 0.85,
    DEM: 1.7,
    JPY: 132,
    INR: 80,
    FRF: 6.5,
    CAD: 1.3,
    AUD: 1.45,
    BEF: 40.6,
    CNY: 7.13,
    SGD: 1.34,

    mygames: {
        RUB: 20
    },

    wechat: {
        CNY: 5
    },

    yookassa: {
        RUB: 50
    },

    rustore: {
        RUB: 50
    }
};

connector.VIRTUAL_EXCHANGE_RATES = {
    FM: 1,
    MC: 100,
    KG: 10,
    MLK: 20,
    OK: 20,
    VK_GOLOS: 2.85,
    WECHAT_GEMS: 10,
    YAN: 20
};