/**
 * Created by vladislav on 9/6/2022
 */

var Microsoft = function () {
    Platform.call(this, connector.MICROSOFT);

    connector.info.os = connector.OS_WINDOWS;
    connector.social.needWindowForLogin = true;
};

Microsoft.prototype = Object.create(Platform.prototype);
Microsoft.prototype.constructor = Microsoft;

Microsoft.prototype._initialize = function (callback) {
    this.callNative("app.initialize", function (code, response) {
        if (response && response.deviceId) {
            connector.info.deviceID = response && response.deviceId;
        }

        callback();
    });
};

Microsoft.prototype.getAccessToken = function () {
    return "microsoft";
};

Microsoft.prototype.canReview = function () {
    return true;
};

Microsoft.prototype._requestReview = function (callback) {
    this.callNative("store.review", undefined, function (code, response) {
        var result = !response || response.error || code !== connector.CODE_SUCCEED ? connector.CODE_FAILED : connector.CODE_SUCCEED;

        callback(result);
    });
};

Microsoft.prototype.parsePayload = function (callback) {
    this.callNative("social.getCampaignId", undefined, function (code, response) {
        var channel = response && response.campaignId;
        callback({ channel: channel });
    });
};

Microsoft.prototype._callNative = function (method, options) {
    var data = Object.assign({
        method: method
    }, options || {});

    window.chrome.webview.postMessage(JSON.stringify(data));
};

Microsoft.prototype.callCallback = function (method, code, options) {
    try {
        Platform.prototype.callCallback.call(this, method, code, options);
    } catch (e) {
        setTimeout(function () {
            throw e;
        }, 0);
    }
};
