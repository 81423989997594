/**
 * Created by slava on 4/21/17.
 */

var FotoStranaPayments = function () {
    RestPayments.call(this, connector.FOTOSTRANA, {
        sdk: SdkManager.SDK_FOTOSTRANA
    });
};

FotoStranaPayments.prototype = Object.create(RestPayments.prototype);
FotoStranaPayments.prototype.constructor = FotoStranaPayments;

FotoStranaPayments.prototype.getProductId = function (product) {
    return product.id.mymailru;
};

FotoStranaPayments.prototype.getDefaultCurrency = function () {
    return "FM";
};

FotoStranaPayments.prototype.getTextPrice = function (price) {
    var textPrice = price + " " + Payments.CURRENCY_SIGNS[this.currency];
    return textPrice.replace(".", ",");
};

FotoStranaPayments.prototype.purchaseOpen = function (product, callback) {
    var priceInCents = Math.floor(product.price * 100);

    if (connector.config.debugMode || connector.platform.isTester()) {
        priceInCents = 1;
    }

    FSClient.event("buyItemCallback", function (response) {
        var purchase = this.createPurchase(undefined, product);
        
        if (response.result === "success") {
            purchase = this.createPurchase(undefined, product);

            callback(connector.CODE_SUCCEED, purchase);
        } else if (response.result === "cancel" || response.result === "close") {
            callback(ERRORS.PURCHASE.CANCEL);
        } else {
            this.bus.trigger("stream:error", "FotoStranaPayments.purchase productId:" + product.productId + " " + JSON.stringify(response));

            callback(ERRORS.PURCHASE.UNKNOWN);
        }
    }.bind(this), {
        itemId: product.productId,
        name: product.description,
        forwardData: product.productId,
        picUrl: connector.platform.getExternalUrl(product.image.replace("fbproducts", "fsproducts")),
        priceFmCents: priceInCents,
        isDebug: connector.platform.isTester() ? 1 : 0
    });
};

FotoStranaPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.FOTOSTRANA);
};