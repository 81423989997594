/**
 * Created by slava on 4/21/17.
 */

var OKPayments = function () {
    RestPayments.call(this, connector.OK, {
        sdk: SdkManager.SDK_OK
    });
};

OKPayments.prototype = Object.create(RestPayments.prototype);
OKPayments.prototype.constructor = OKPayments;

OKPayments.prototype.getDefaultCurrency = function () {
    return "OK";
};

OKPayments.prototype.getProductId = function (product) {
    return product.id.ok;
};

OKPayments.prototype.purchaseOpen = function (product, callback) {
    var purchase = this.createPurchase(undefined, product);

    var listener = function (result, data) {
        if (result !== OKSocial.CODE_SUCCEED) {
            var isCancelled = result === "cancel" || data === "cancel";

            callback(isCancelled ? ERRORS.PURCHASE.CANCEL : ERRORS.PURCHASE.UNKNOWN);

            if (!isCancelled) {
                this.bus.trigger("stream:error", "OKPayments.purchase productId:" + product.productId + " result:" + result + " " + JSON.stringify(data));
            }

            return;
        }

        if (product.type === "subscription") {
            callback(connector.CODE_SUCCEED, purchase);
            return;
        }

        this.startPurchasePinger(purchase, function (code) {
            callback(code ? ERRORS.PURCHASE.UNKNOWN : connector.CODE_SUCCEED, purchase);
        }, { timeout: 15000 });

        this.setWaitPaymentIdTimeout(5500, purchase, callback);
    }.bind(this);

    if (product.type === "subscription") {
        connector.platform.getPlugin().listeners.showPaymentSubscription = listener;
        FAPI.invokeUIMethod("showPaymentSubscription", product.productId, product.price);
    } else {
        connector.platform.getPlugin().listeners.showPayment = listener;
        FAPI.UI.showPayment(
            product.description,
            product.title,
            product.itemId,
            purchase.price,
            null,
            null,
            "ok",
            "true"
        );
    }
};

OKPayments.prototype.stopPurchaseActions = function () {
    RestPayments.prototype.stopPurchaseActions.call(this);

    this.clearWaitPaymentIdTimeout();

    delete connector.platform.getPlugin().listeners.showPayment;
    delete connector.platform.getPlugin().listeners.showPaymentSubscription;
};

OKPayments.prototype.clearWaitPaymentIdTimeout = function () {
    clearTimeout(this.waitPaymentIdTimeout);
    delete this.waitPaymentIdTimeout;
};

OKPayments.prototype.setWaitPaymentIdTimeout = function (timeout, purchase, callback) {
    this.clearWaitPaymentIdTimeout();

    this.waitPaymentIdTimeout = setTimeout(function () {
        purchase.paymentId = purchase.paymentId || ("tmp" + connector.platform.getUserID() + "_" + Date.now());
        purchase.state = 0;

        console.log("create tmp PaymentId", purchase.paymentId);

        var path = "/payments/add/" + connector.info.source + "/" + encodeURIComponent(connector.platform.getUserID());

        ConnectorRestClient.post(path, {
            purchase: purchase,
            version: 2
        });

        callback(connector.CODE_SUCCEED, purchase);
    }, timeout);
};

OKPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.OK);
};
