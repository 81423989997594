/**
 * Created by Andrey Popov on 06.07.2020.
 */

NoSocial = function () {
    Social.call(this, connector.NONE);
};

NoSocial.prototype = Object.create(Social.prototype);
NoSocial.prototype.constructor = NoSocial;

NoSocial.prototype._connect = function (callback) {
    callback(connector.STATUS_CONNECTED);
};