/**
 * Created by vladislav on 4/2/2024
 */

var PlatformDetector = function () {
    var source = connector.info.source;

    var platforms = {
        microsoft: typeof Microsoft !== "undefined" && Microsoft,
        msstart: typeof MSStart !== "undefined" && MSStart,
        gdcom: typeof GDCom !== "undefined" && GDCom,
        coolmath: typeof CoolMathGames !== "undefined" && CoolMathGames,
        instant: typeof Instant !== "undefined" && Instant,
        ok: typeof OKPlatform !== "undefined" && OKPlatform,
        mm: typeof MyMailRu !== "undefined" && MyMailRu,
        vk: typeof VKPlatform !== "undefined" && VKPlatform,
        sp_mbga: typeof SPMobage !== "undefined" && SPMobage,
        mbga: typeof Mobage !== "undefined" && Mobage,
        draugiem: typeof Draugiem !== "undefined" && Draugiem,
        fotostrana: typeof FotoStrana !== "undefined" && FotoStrana,
        cleverapps: typeof CleverApps !== "undefined" && CleverApps,
        yandex: typeof Yandex !== "undefined" && Yandex,
        macos: typeof MacOS !== "undefined" && MacOS,
        ios: typeof IOS !== "undefined" && IOS,
        gpg: typeof GPG !== "undefined" && GPG,
        android: typeof AndroidPlatform !== "undefined" && AndroidPlatform,
        pliega: typeof Pliega !== "undefined" && Pliega,
        plng: typeof Plinga !== "undefined" && Plinga,
        kongregate: typeof Kongregate !== "undefined" && Kongregate,
        crazy: typeof Crazy !== "undefined" && Crazy,
        ton: typeof Ton !== "undefined" && Ton,
        wortal: typeof WortalPlatform !== "undefined" && WortalPlatform,
        rustore: typeof Rustore !== "undefined" && Rustore,
        amazon: typeof Amazon !== "undefined" && Amazon,
        mygames: typeof Mygames !== "undefined" && Mygames,
        samsung: typeof Samsung !== "undefined" && Samsung,
        facebook: typeof FacebookCanvas !== "undefined" && FacebookCanvas,
        xiaomi: typeof Xiaomi !== "undefined" && Xiaomi,
        pwa: typeof PWA !== "undefined" && PWA,
        wechat: typeof Wechat !== "undefined" && Wechat,
        youtube: typeof Youtube !== "undefined" && Youtube,
        gamesnack: typeof GameSnack !== "undefined" && GameSnack,
        discord: typeof Discord !== "undefined" && Discord,
        test: typeof TestPlatform !== "undefined" && TestPlatform
    };

    if (connector.config[source] && connector.config[source].adsense !== undefined) {
        connector.config.adsense = connector.config[source].adsense;
    }

    if (connector.config[source] && connector.config[source].xsolla !== undefined) {
        connector.config.xsolla = connector.config[source].xsolla;
    }

    return platforms[source] || FacebookCanvas;
};
