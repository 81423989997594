/**
 * Created by vladislav on 9/6/2022
 */

var IOS = function () {
    Apple.call(this, connector.IOS);

    connector.info.os = connector.OS_IOS;
};

IOS.prototype = Object.create(Apple.prototype);
IOS.prototype.constructor = IOS;