/**
 * Created by slava on 4/21/17.
 */

var MyMailRuPayments = function () {
    RestPayments.call(this, connector.MM, {
        sdk: SdkManager.SDK_MYMAILRU
    });
};

MyMailRuPayments.prototype = Object.create(RestPayments.prototype);
MyMailRuPayments.prototype.constructor = MyMailRuPayments;

MyMailRuPayments.prototype.getDefaultCurrency = function () {
    return "MLK";
};

MyMailRuPayments.prototype.getProductId = function (product) {
    return product.id.mymailru;
};

MyMailRuPayments.prototype._connect = function (callback) {
    this.listeners = {
        status: function () {
        },
        income: function () {
        }
    };

    mailru.events.listen(mailru.app.events.paymentDialogStatus, function (event) {
        this.listeners.status(event);
    }.bind(this));

    mailru.events.listen(mailru.app.events.incomingPayment, function (event) {
        this.listeners.income(event);
    }.bind(this));

    callback(connector.STATUS_CONNECTED);
};

MyMailRuPayments.prototype.purchaseOpen = function (product, callback) {
    this.listeners.income = function (event) {
        console.log("[MM payments] event income", event);

        if (event.status === "success") {
            var purchase = this.createPurchase(undefined, product);

            callback(connector.CODE_SUCCEED, purchase);
        } else {
            callback(ERRORS.PURCHASE.UNKNOWN);

            this.bus.trigger("stream:error", "MyMailRuPayments.purchase productId:" + product.productId + " " + JSON.stringify(event));
        }
    }.bind(this);

    mailru.app.payments.showDialog({
        service_id: product.productId,
        service_name: product.title,
        mailiki_price: this.getPriceToBePaid(product)
    });
};

MyMailRuPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.MM);
};
