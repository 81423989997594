/**
 * Created by vladislav on 9/6/2022
 */

var CleverApps = function () {
    Platform.call(this, connector.CLEVERAPPS);

    connector.social.needWindowForLogin = true;

    this.needsCountry = true;
};

CleverApps.prototype = Object.create(Platform.prototype);
CleverApps.prototype.constructor = CleverApps;

CleverApps.prototype.getLocalStoragePrefix = function () {
    return "_cleverapps";
};
