/**
 * Created by Vladislav on 10.06.2024.
 */

var ERRORS = connector.ERRORS = {
    NOT_CONNECTED: "not_connected"
};

ERRORS.SHOW_REWARDED = {
    NOT_SUPPORTED: "rewarded_not_supported",
    NO_ADS: "rewarded_not_found",
    NOT_CONFIGURED: "rewarded_not_configured",
    AD_BLOCK: "rewarded_ad_block",
    THROTTLED: "rewarded_throttled",
    AD_ALREADY_RUNNING: "ad_already_running",
    CLOSED_BY_USER: "closed_by_user",
    AD_EXPIRED: "rewarded_expired_error",
    UNKNOWN: "rewarded_unknown_error"
};

ERRORS.SHOW_INTERSTITIAL = {
    NOT_SUPPORTED: "interstitial_not_supported",
    NO_ADS: "interstitial_not_found",
    NOT_CONFIGURED: "interstitial_not_configured",
    AD_BLOCK: "interstitial_ad_block",
    THROTTLED: "interstitial_throttled",
    AD_ALREADY_RUNNING: "ad_already_running",
    CLOSED_BY_USER: "closed_by_user",
    UNKNOWN: "interstitial_unknown_error"
};

ERRORS.SHOW_STICKY = {
    NOT_SUPPORTED: "sticky_not_supported",
    NO_STICKY: "sticky_not_found",
    NOT_CONFIGURED: "sticky_not_configured",
    AD_BLOCK: "sticky_ad_block",
    TIMEOUT: "show_sticky_timeout",
    UNKNOWN: "show_sticky_unknown_error"
};

ERRORS.CLOSE_STICKY = {
    TIMEOUT: "close_sticky_timeout",
    UNKNOWN: "close_sticky_unknown_error"
};

ERRORS.PURCHASE = {
    ALREADY_OPEN: "purchase_already_open",
    PRODUCT_NOT_FOUND: "product_not_found",
    LOGIN_REQUIRED: "login_required",
    EMAIL_REQUIRED: "email_required",
    INVALID_EMAIL: "invalid_email",
    TIMEOUT: "purchase_timeout",
    CANCEL: "purchase_canceled_by_user",
    UNKNOWN: "purchase_unknown_error"
};

ERRORS.CONSUME = {
    UNKNOWN: "consume_unknown_error"
};

ERRORS.SWITCH_GAME = {
    CANCEL: "switch_game_canceled_by_user",
    UNKNOWN: "switch_game_unknown_error"
};

ERRORS.STORAGE = {
    NOT_READY: "storage_not_ready",

    LOAD: {
        UNKNOWN: "storage_load_unknown_error"
    },

    SET: {
        UNKNOWN: "storage_set_unknown_error"
    }
};
