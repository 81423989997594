/**
 * Created by vladislav on 2/9/2024
 */

/**
 *
 * Working with social features is implemented via **connector.social** interface.
 *
 * ## Share
 *
 * Shows a window inviting the player to make a wall post that will appear in their feed.
 *
 * ### Supported Platforms
 * <ul class="platforms-list">
 *   <li>
 *       ✅ <img src="images/vk.png"> VK Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/ok.png"> OK Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/kongregate.png"> Kongregate
 *   </li>
 *   <li>
 *       ✅ <img src="images/instant.png"> Facebook Instant Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/facebook.png"> Facebook
 *   </li>
 *   <li>
 *       ✅ <img src="images/fotostrana.png"> Fotostrana
 *   </li>
 *   <li>
 *       ✅ <img src="images/mbga.png"> Mobage
 *   </li>
 *   <li>
 *       ✅ <img src="images/sp_mbga.png"> SP Mobage
 *   </li>
 *   <li>
 *       ✅ <img src="images/mm.png"> My World (except for iOS)
 *   </li>
 *   <li>
 *       ✅ <img src="images/draugiem.png"> Draugiem
 *   </li>
 *   <li>
 *       ✅ <img src="images/plinga.png"> Plinga
 *   </li>
 *   <li>
 *       ✅ <img src="images/wortal.png"> Wortal
 *   </li>
 * </ul>
 *
 * You can check whether shares are supported on the platform with
 * ```
 * if (connector.social.isSupportsNativeShare) {
 *   // shares are supported
 * }
 * ```
 *
 * When calling share you can provide title, text and image. If title is omitted, title tag will be used.
 * For VK you need to provide image in the attachment format photo123456_123456
 *
 * ```
 * const success = await connector.social.share({
 *   title: "Level completed!"
 *   text: "I passed a level! It's an amazing game, you should try it!",
 *   image: "https://example.com/img.png"
 * })
 *
 * if (success) {
 *   // Share successful
 * }
 * ```
 *
 * You can also subscribe to on share event
 *
 * ```
 * connector.social.on("share", (success) => {
 *  if (success) {
 *    // Share successful
 *  }
 * });
 * ```
 *
 * ## Invite friends
 *
 * Shows the player a friends list to whom you can send the invitation.
 *
 * ### Supported Platforms
 * <ul class="platforms-list">
 *   <li>
 *       ✅ <img src="images/vk.png"> VK Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/ok.png"> OK Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/kongregate.png"> Kongregate
 *   </li>
 *   <li>
 *       ✅ <img src="images/instant.png"> Facebook Instant Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/msn.png"> MSN
 *   </li>
 *   <li>
 *       ✅ <img src="images/facebook.png"> Facebook
 *   </li>
 *   <li>
 *       ✅ <img src="images/fotostrana.png"> Fotostrana
 *   </li>
 *   <li>
 *       ✅ <img src="images/mbga.png"> Mobage
 *   </li>
 *   <li>
 *       ✅ <img src="images/mm.png"> My World (except for iOS)
 *   </li>
 *   <li>
 *       ✅ <img src="images/draugiem.png"> Draugiem
 *   </li>
 *   <li>
 *       ✅ <img src="images/mygames.png"> Mygames
 *   </li>
 *   <li>
 *       ✅ <img src="images/plinga.png"> Plinga
 *   </li>
 *   <li>
 *       ✅ <img src="images/wortal.png"> Wortal
 *   </li>
 * </ul>
 *
 * You can check whether invites are supported on the platform with
 * ```
 * if (connector.social.isSupportsNativeInvite) {
 *   // invites are supported
 * }
 * ```
 *
 * When calling invite you can provide title, text and image. If title is omitted, title tag will be used.
 *
 * ```
 * const success = await connector.social.invite({
 *   text: Join this fabulous game!,
 *   image: "https://example.com/img.png"
 * })
 *
 * if (success) {
 *   // Invite successful
 * }
 * ```
 *
 * You can also subscribe to on invite event
 *
 * ```
 * connector.social.on("invite", (success) => {
 *  if (success) {
 *    // Invite successful
 *  }
 * });
 * ```
 *
 * ## Join community
 *
 * Shows the player an overlay with the opportunity to join the community (if the platform allows) or opens a link to the community in a new tab.
 *
 * ### Supported Platforms
 * <ul class="platforms-list">
 *   <li>
 *       ✅ <img src="images/vk.png"> VK Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/ok.png"> OK Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/instant.png"> Facebook Instant Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/facebook.png"> Facebook
 *   </li>
 *   <li>
 *       ✅ <img src="images/fotostrana.png"> Fotostrana
 *   </li>
 *   <li>
 *       ✅ <img src="images/mbga.png"> Mobage
 *   </li>
 *   <li>
 *       ✅ <img src="images/sp_mbga.png"> SP Mobage
 *   </li>
 *   <li>
 *       ✅ <img src="images/mm.png"> My World (except for iOS)
 *   </li>
 *   <li>
 *       ✅ <img src="images/draugiem.png"> Draugiem
 *   </li>
 * </ul>
 *
 * ```
 * if (connector.social.canJoinCommunity) {
 *   // Join community is available
 * }
 * ```
 *
 * ### Platform with native functionality
 * <ul class="platforms-list">
 *   <li>
 *       ✅ <img src="images/ok.png"> OK Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/instant.png"> Facebook Instant Games
 *   </li>
 * </ul>
 *
 * ```
 * if (connector.social.isSupportsNativeCommunityJoin) {
 *   // Native join community is available
 * }
 * ```
 *
 * You can check whether native functionality is available on the platform with
 * ```
 * if (connector.social.isSupportsNativeInvite) {
 *   // invites are supported
 * }
 * ```
 *
 * Join community
 *
 * ```
 * const success = await connector.social.joinCommunity();
 * if (success) {
 *   // Join community success
 * }
 * ```
 *
 * You can also subscribe to on join community event:
 *
 * ```
 * connector.social.on("joinCommunity", (success) => {
 * if (success) {
 *   // Join community success
 * }
 * });
 * ```
 *
 * ### Login
 *
 * Login user on the platform
 *
 * ```
 * const success = await connector.social.login();
 * if (success) {
 *   // Login success
 * }
 *
 * // Or you can subscribe to on login event
 * connector.social.on("login", { oldId, newId } => {
 *   // Login success
 * })
 * ```
 *
 * You can check whether the player is logged in with
 * ```
 * connector.social.isLoggedIn();
 * ```
 *
 * @namespace Social
 * */

var SocialInterface = function () {
    return Object.assign(
        {
            /**
             * @type {Boolean}
             * @memberof Social
             * @desc Are native shares supported on this platform
             * */
            get isSupportsNativeShare() {
                return this.getPlugin().isSupportsNativeShare();
            },
            /**
             * @type {Boolean}
             * @memberof Social
             * @desc Are native invites supported on this platform
             * */
            get isSupportsNativeInvite() {
                return this.getPlugin().isSupportsNativeInvite() || connector._socialManager.isInviteViaPlatform();
            },
            /**
             * @type {Boolean}
             * @memberof Social
             * @desc Are native requests supported on this platform
             * */
            get isSupportsNativeRequest() {
                return this.getPlugin().isSupportsNativeRequest();
            },
            /**
             * @type {Boolean}
             * @memberof Social
             * @desc Whether a user needs to be logged in to invite
             * */
            get isNeedsLoginForInvite() {
                return !connector._socialManager.isInviteViaPlatform();
            },
            /**
             * @type {Boolean}
             * @memberof Social
             * @desc Are native community join supported on this platform
             * */
            get isSupportsNativeCommunityJoin() {
                return this.getPlugin().isSupportsNativeCommunityJoin();
            },
            /**
             * @type {Boolean}
             * @memberof Social
             * @desc Whether player can join community on this platform
             * */
            get canJoinCommunity() {
                return connector.platform.oneOf(
                    connector.OK, 
                    connector.INSTANT,
                    connector.VK,
                    connector.MM, 
                    connector.MOBAGE, 
                    connector.SP_MOBAGE,
                    connector.DRAUGIEM, 
                    connector.DRAUGIEM, 
                    connector.FOTOSTRANA, 
                    connector.FACEBOOK
                );
            }
        },
        CommonInterface(),
        {
            _detectPlugin: SocialDetector,

            needWindowForLogin: false,

            /**
             * @function isLoggedIn
             * @memberof Social
             * @desc Is player logged in
             * @return {Boolean}
             * */
            isLoggedIn: function (options) {
                return this.getPlugin().isLoggedIn(options);
            },

            /**
             * @function login
             * @memberof Social
             * @desc Login
             * @return {Promise<Boolean>}
             * */
            login: function (options) {
                return new Promise(function (resolve) {
                    connector.social.loginCallback(resolve, options);
                });
            },

            loginCallback: function (callback, options) {
                this.getPlugin().login(callback, options);
            },

            logout: function () {
                this.getPlugin().logout();
            },

            hasLeaderBoard: function () {
                return this.getPlugin().hasLeaderBoard();
            },

            showLeaderBoard: function (options, callback) {
                var plugin = this.getPlugin();
                if (plugin && plugin.isConnected()) {
                    plugin.showLeaderBoard(options, callback);
                } else {
                    callback(connector.CODE_FAILED);
                }
            },

            /**
             * @function joinCommunity
             * @memberof Social
             * @desc Join community
             * @return {Promise<Boolean>}
             * */
            joinCommunity: function () {
                return new Promise(function (resolve) {
                    this.joinCommunityCallback(resolve);
                }.bind(this));
            },

            joinCommunityCallback: function (callback) {
                connector._socialManager.joinCommunity(callback);
            },

            joinCommunityNativeCallback: function (callback) {
                connector._socialManager.joinCommunity(callback, true);
            },
            /**
             * @function share
             * @memberof Social
             * @desc Opens share dialog
             * @param {object} options - Options
             * @param {string} options.title - Title
             * @param {string} options.text - Text
             * @param {string} options.image - Image URL
             * @param {object} options.linktext - Text for link, for Plinga only
             * @param {object} options.subTitle - Sub title, for Draugiem only
             * @return {Promise<Boolean>}
             * */
            share: function (options) {
                return new Promise(function (resolve) {
                    this.shareCallback(options, resolve);
                }.bind(this));
            },

            shareCallback: function (options, callback) {
                connector._socialManager.share(options, callback);
            },
            /**
             * @function invite
             * @memberof Social
             * @desc Opens share dialog
             * @param {object} options - Options
             * @param {string} options.title - Title
             * @param {string} options.text - Text
             * @param {string} options.image - Image URL
             * @return {Promise<Boolean>}
             * */
            invite: function (options) {
                return new Promise(function (resolve) {
                    this.inviteCallback(options, resolve);
                }.bind(this));
            },

            inviteCallback: function (options, callback) {
                connector._socialManager.invite(options, callback);
            },
            /**
             * @function request
             * @memberof Social
             * @desc Send request to friends
             * @param {object} options - Options
             * @param {string} options.title - Title
             * @param {string} options.text - Text
             * @param {string} options.image - Image URL
             * @param {Array<string>} options.to - List of friend IDs
             * @return {Promise<Boolean>}
             * */
            request: function (options) {
                return new Promise(function (resolve) {
                    this.requestCallback(options, resolve);
                }.bind(this));
            },

            requestCallback: function (options, callback) {
                connector._socialManager.request(options, callback);
            },

            askFriendsPermission: function (callback) {
                var plugin = this.getPlugin();
                if (plugin.askFriendsPermission && plugin.isConnected()) {
                    plugin.askFriendsPermission(callback);
                } else {
                    callback(connector.CODE_FAILED);
                }
            },

            listFriends: function (callback) {
                this.getPlugin().listFriends(callback);
            },

            listInvitableFriends: function (callback) {
                this.getPlugin().listInvitableFriends(callback);
            },

            aboutUser: function (userId, callback) {
                this.getPlugin().aboutUser(userId, callback);
            },

            sendActivity: function (data, callback) {
                var plugin = this.getPlugin();
                if (plugin.sendActivity && plugin.isConnected()) {
                    plugin.sendActivity(data, callback);
                } else {
                    callback(connector.CODE_FAILED);
                }
            },

            markAchievement: function (data) {
                var plugin = this.getPlugin();
                if (plugin.markAchievement && plugin.isConnected()) {
                    plugin.markAchievement(data);
                }
            },

            invalidateAccessToken: function () {
                var plugin = this.getPlugin();
                if (plugin.invalidateAccessToken) {
                    plugin.invalidateAccessToken();
                }
            },

            listAvailable: function () {
                return this.getPlugin().listAvailable();
            }
        }
    );
};