/**
 * Created by andrey on 28.12.2023
 */

var AppLovinAds = function () {
    AdsPlugin.call(this, connector.APP_LOVIN, {
        cantLoadAndPlayInParallel: true,
        customImpressionEvent: true
    });

    this.onAdLoadedCallback = {};
    this._playCallback = function () {};
};

AppLovinAds.prototype = Object.create(AdsPlugin.prototype);
AppLovinAds.prototype.constructor = AppLovinAds;

AppLovinAds.prototype._connect = function (callback) {
    var config = AppLovinAds.getConfig();

    connector.platform.callNative("AppLovinPlugin.init", config, function () {
        connector.platform.subscribeNative("AppLovinPlugin", this.onNativeEvent.bind(this));
        callback(connector.STATUS_CONNECTED);
    }.bind(this));
};

AppLovinAds.prototype._cacheAd = function (name, callback) {
    this.onAdLoadedCallback[name] = callback;

    if (name === AdsPlugin.REWARDED) {
        connector.platform.callNative("AppLovinPlugin.cacheRewarded");
    } else {
        connector.platform.callNative("AppLovinPlugin.cacheInterstitial");
    }
};

AppLovinAds.prototype.onAdLoadResult = function (name, code) {
    if (this.onAdLoadedCallback[name]) {
        this.onAdLoadedCallback[name](code, true);
        this.onAdLoadedCallback[name] = undefined;
    }
};

AppLovinAds.prototype._playAd = function (name, ad, callback) {
    this.success = false;
    this._playCallback = callback;

    connector.platform.callNative("AppLovinPlugin.show", { name: name });
};

AppLovinAds.isAppropriate = function () {
    return AppLovinAds.getConfig() && connector.platform.oneOf(connector.ANDROID, connector.AMAZON, connector.IOS);
};

AppLovinAds.getConfig = function (source) {
    source = source || connector.info.source;
    return connector.config[source] && connector.config[source].appLovin
        || source === "amazon" && AppLovinAds.getConfig("android");
};

AppLovinAds.prototype.onNativeEvent = function (event, options) {
    console.log("AppLovinAds.onNativeEvent - " + event);

    switch (event) {
        case "onAdRevenuePaid":
            this.onImpressionEvent(options.name, options.cost || (connector.config.debugMode ? 0.000001 : 0));
            break;
        case "onAdAlreadyRunning":
            if (options.name === AdsPlugin.REWARDED) {
                this.bus.trigger("error:showRewarded", ERRORS.SHOW_REWARDED.AD_ALREADY_RUNNING);
            } else {
                this.bus.trigger("error:showInterstitial", ERRORS.SHOW_INTERSTITIAL.AD_ALREADY_RUNNING);
            }
            break;
        case "onAdDisplayed":
            var adInfo = {
                adUnitId: options.adUnitId,
                networkName: options.networkName,
                placement: options.placement,
                creativeId: options.creativeId,
                dspName: options.dspName
            };
            console.log(this.pluginName, "Applovin.onAdDisplayed", options.name, connector.utils.stringify(adInfo));
            this.bus.trigger("applovin:displayed", options.name, adInfo);
            break;
        case "onAdDisplayFailed":
        case "onAdHidden":
            if (options.name === AdsPlugin.INTERSTITIAL) {
                this._playCallback(connector.CODE_SUCCEED);
            } else {
                this._playCallback(this.success ? connector.CODE_SUCCEED : ERRORS.SHOW_REWARDED.UNKNOWN);
            }
            break;
        case "onAdAlreadyLoading":
            this.bus.trigger(options.name + ":cached", AdsPlugin.ERROR_LOADING_ALREADY_LOADING, this.pluginName);
            break;
        case "onAdLoaded":
            this.onAdLoadResult(options.name, connector.CODE_SUCCEED);
            break;
        case "onAdLoadFailed":
            this.onAdLoadResult(options.name, options.name === AdsPlugin.STICKY ? ERRORS.SHOW_STICKY.UNKNOWN : AdsPlugin.ERROR_LOADING_FAILED);
            break;
        case "onUserRewarded":
            this.success = true;
            break;
    }
};

AppLovinAds.prototype._askPermission = function (callback) {
    var business = connector.config.android.business || connector.config.business;
    var terms = connector.config.terms[business];

    var options = Object.assign({}, terms, {
        userID: connector.platform.getUserID()
    });

    connector.platform.callNative("AppLovinPlugin.askPermission", options, callback);
};

AppLovinAds.prototype.isStickySupported = function () {
    return connector.platform.oneOf(connector.ANDROID, connector.AMAZON, connector.IOS);
};

AppLovinAds.prototype.isStickyConfigured = function () {
    return AppLovinAds.getConfig().banner;
};

AppLovinAds.prototype.showSticky = function (callback) {
    connector.platform.callNative("AppLovinPlugin.showBanner");

    this.onAdLoadedCallback[AdsPlugin.STICKY] = callback;
};

AppLovinAds.prototype.closeSticky = function (callback) {
    connector.platform.callNative("AppLovinPlugin.hideBanner", callback);
};
