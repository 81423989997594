/**
 * Created by vladislav on 9/6/2022
 */

var VKPlatform = function () {
    Platform.call(this, connector.VK, {
        sdk: SdkManager.SDK_VK
    });

    var vkPlatform = connector.utils.getRequestParameters().platform;
    if (vkPlatform !== "web") {
        connector.info.isMobile = true;
    }

    if (["html5_ios", "html5_android"].includes(vkPlatform)) {
        connector.info.isInApp = true;
    }
};

VKPlatform.prototype = Object.create(Platform.prototype);
VKPlatform.prototype.constructor = VKPlatform;

VKPlatform.prototype._connect = function (callback) {
    vkBridge.subscribe(function (event) {
        console.log("vkBridge event", event);
    });

    this.askPermissions("", function () {
        this.resetCounter();

        callback(connector.STATUS_CONNECTED);
    }.bind(this));
};

VKPlatform.prototype._loadDataKeys = function () {
    return vkBridge.send("VKWebAppStorageGetKeys", {
        count: 1000,
        offset: 0
    }).then(function (data) {
        return data.keys;
    });
};

VKPlatform.prototype.loadData = function () {
    return this._loadDataKeys().then(function (keys) {
        if (keys.length === 0) {
            return Promise.resolve({});
        }

        return vkBridge.send("VKWebAppStorageGet", {
            keys: keys
        }).then(function (data) {
            return data.keys.reduce(function (res, data) {
                res[data.key] = JSON.parse(data.value);

                return res;
            }, {});
        });
    });
};

VKPlatform.prototype.setData = function (key, data) {
    return vkBridge.send("VKWebAppStorageSet", {
        key: key,
        value: JSON.stringify(data)
    }).then(function (data) {
        if (!data.result) {
            return Promise.reject(new Error("Value was not set"));
        }

        return true;
    });
};

VKPlatform.prototype.askPermissions = function (permissions, callback) {
    var scope = {};

    permissions.split(",").forEach(function (permission) {
        scope[permission] = false;
    });

    if (this.scope) {
        this.scope.split(",").forEach(function (permission) {
            scope[permission] = true;
        });
    }

    if (Object.values(scope).every(Boolean)) {
        callback(connector.CODE_SUCCEED);
        return;
    }

    this.callNative("VKWebAppGetAuthToken", {
        app_id: parseInt(connector.config.vk.appId),
        scope: Object.keys(scope).join(",")
    }, function (code, data) {
        if (code === connector.CODE_SUCCEED) {
            this.accessToken = data.access_token;
            this.scope = data.scope;
        }

        callback(code);
    }.bind(this));
};

VKPlatform.prototype.resetCounter = function () {
    this.callNative("VKWebAppCallAPIMethod", {
        method: "setCounter",
        params: {
            v: VKPlatform.API_VERSION,
            access_token: this.accessToken,
            counter: 0,
            increment: 0
        }
    }, function (code) {
        if (code === connector.CODE_FAILED) {
            console.log("VK.resetCounter error");
        }
    });
};

VKPlatform.prototype.getLocalStoragePrefix = function () {
    return "_VK";
};

VKPlatform.prototype.listSuggestedLanguages = function () {
    return [connector.LANGUAGE_RUSSIAN];
};

VKPlatform.prototype._canCreateShortcut = function () {
    return true;
};

VKPlatform.prototype.callNative = function (method, params, callback) {
    if (!this.isConnected() && method !== "VKWebAppGetAuthToken") {
        if (callback) {
            callback(connector.CODE_FAILED);
        }
        return;
    }

    if (["apps.", "friends."].some(function (prefix) {
        return method.indexOf(prefix) === 0;
    })) {
        params = {
            method: method,
            params: Object.assign({
                v: VKPlatform.API_VERSION,
                access_token: this.accessToken
            }, params)
        };

        method = "VKWebAppCallAPIMethod";
    }

    if (callback !== undefined) {
        var typeSuccess = method === "VKWebAppGetAuthToken" ? "VKWebAppAccessTokenReceived" : method + "Result";
        var typeFail = method === "VKWebAppGetAuthToken" ? "VKWebAppAccessTokenFailed" : method + "Failed";
        this.subscribe(typeSuccess, typeFail, callback);
    }

    vkBridge.send(method, params);
};

VKPlatform.prototype.subscribe = function (typeSuccess, typeFail, callback) {
    var cb = function (event) {
        console.log("vkBridge response", event);

        if (event.detail.type === typeSuccess) {
            callback(connector.CODE_SUCCEED, event.detail.data);
            vkBridge.unsubscribe(cb);
        }
        if (event.detail.type === typeFail) {
            callback(connector.CODE_FAILED, event.detail.data);
            vkBridge.unsubscribe(cb);
        }
    };

    vkBridge.subscribe(cb);
};

VKPlatform.prototype.createShortcut = function (callback) {
    this.callNative("VKWebAppAddToFavorites");

    if (callback) {
        callback(connector.CODE_SUCCEED);
    }
};

VKPlatform.prototype.parsePayload = function (callback) {
    var params = connector.utils.getRequestParameters();
    params.channel = params.hash;
    callback(params);
};

VKPlatform.prototype.parseOtherWays = function (callback) {
    var params = connector.utils.getRequestParameters();
    var filter = ["featured", "collections", "collection"];
    var entryPoint = filter.includes(params.referrer) ? params.referrer : undefined;
    callback(entryPoint);
};

VKPlatform.API_VERSION = "5.199";

VKPlatform.prototype.openUrl = function (url) {
    var link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("href", url);
    link.click();
};

VKPlatform.getPlatfrom = function () {
    return connector.utils.getRequestParameters().platform;
};
