/**
 * Created by slava on 4/5/17.
 */

var WebViewFacebook = function () {
    this.apiJobs = [];
    this.apiProcessing = false;

    BaseFbSocial.call(this, connector.FACEBOOK);

    this.listeners = {};
};

WebViewFacebook.prototype = Object.create(BaseFbSocial.prototype);
WebViewFacebook.prototype.constructor = WebViewFacebook;

WebViewFacebook.prototype.onAuthDetails = function (authDetails) {
    console.log("WebViewFacebook.onAuthDetails - " + JSON.stringify(authDetails));

    this.accessToken = authDetails.accessToken;
    this.userID = authDetails.userID;
    this.graphDomain = authDetails.graphDomain;
    this.instantUserID = authDetails.instantUserID;

    if (this.graphDomain === "gaming") {
        this.instantUserID = this.instantUserID || !connector.platform.withTmpID() && connector.platform.getUserID();
        this.userID = this.instantUserID;

        this.accessToken = authDetails.userID && authDetails.accessToken
            ? "instant_" + authDetails.userID + "|" + authDetails.accessToken
            : "instant";
    }
};

WebViewFacebook.prototype._connect = function (callback) {
    this.deleteAllRequests();

    connector.platform.callNative("FacebookPlugin.init", function (code, authDetails) {
        this.onAuthDetails(authDetails);
        
        callback(connector.STATUS_CONNECTED);
    }.bind(this));
};

WebViewFacebook.prototype.isLoggedIn = function () {
    return this.accessToken && this.userID;
};

WebViewFacebook.prototype._getUserID = function () {
    return this.userID;
};

WebViewFacebook.prototype.getAccessToken = function () {
    return this.accessToken;
};

WebViewFacebook.prototype._login = function (callback) {
    connector.platform.callNative(
        "FacebookPlugin.login", 
        { permissions: BaseFbSocial.PERMISSIONS },
        function (code, authDetails) {
            console.log("WebViewFacebook.onLogin - " + code + " - " + JSON.stringify(authDetails));

            this.onAuthDetails(authDetails);

            console.log("WebViewFacebook.onLogin isLoggedIn - " + this.isLoggedIn());

            callback();
        }.bind(this)
    );
};

WebViewFacebook.prototype.api = function (apiMethod, httpMethod, params, callback) {
    params = params || {};
    if (typeof params === "function") {
        callback = params;
        params = {};
    }

    if (!this.isLoggedIn()) {
        console.log("Not logged in!");
        if (callback) {
            callback(connector.CODE_CANCELLED);
        }
        return;
    }

    if (httpMethod === undefined) {
        httpMethod = "GET";
        callback = function () {};
    } else if (typeof httpMethod === "function") {
        callback = httpMethod;
        httpMethod = "GET";
    }

    // console.log('Add api: ' + apiMethod, httpMethod, JSON.stringify(params));

    this.apiJobs.push({
        apiMethod: apiMethod,
        httpMethod: httpMethod,
        params: params,
        callback: callback
    });

    this.processApi();
};

WebViewFacebook.prototype.processApi = function () {
    if (this.apiProcessing || this.apiJobs.length === 0) {
        return;
    }

    this.apiProcessing = true;
    var job = this.apiJobs.shift();

    var callback = job.callback;
    delete job.callback;

    connector.platform.callNative("FacebookPlugin.api", job, function (code, data) {
        if (callback && code === connector.CODE_SUCCEED) {
            callback(connector.CODE_SUCCEED, data);
        }

        this.apiProcessing = false;
        this.processApi();
    }.bind(this));
};

WebViewFacebook.isAvailable = function () {
    return connector.config.facebookLogin && connector.platform.oneOf(connector.ANDROID, connector.AMAZON, connector.IOS);
};

WebViewFacebook.APPINVITES_SDKBOX_ERROR = "Unrecognized 'com.facebook.platform.protocol.PROTOCOL_ACTION' extra: 'com.facebook.platform.action.request.APPINVITES_DIALOG'.";