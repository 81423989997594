/**
 * Created by andrey on 19.03.2024
 */

var Pinger = function (options) {
    this.interval = options.interval || Pinger.INTERVAL;
    this.finishTime = Date.now() + (options.timeout || Pinger.TIMEOUT);
    this.maxAttempts = options.attempts || 1e6;
    this.backoff = options.backoff || Pinger.BACKOFF;

    this.callback = options.callback || function () {};
    this.action = options.action;

    this.attempt = 0;
};

Pinger.prototype.ping = function () {
    if (Date.now() > this.finishTime || this.attempt > this.maxAttempts) {
        this.callback(connector.CODE_FAILED);
        this.stop();
        return;
    }

    this.player = new ConnectorActionPlayer([
        function (f) {
            f = connector.utils.waitNoMore(10000, f);

            this.action(function (code) {
                if ([connector.CODE_SUCCEED, connector.CODE_FAILED, connector.CODE_CANCELLED].includes(code)) {
                    this.callback.apply(this, arguments);
                    this.stop();
                } else {
                    f();
                }
            }.bind(this));
        }.bind(this),

        function (f) {
            var timeout = Math.min(this.interval * Math.pow(this.backoff, this.attempt), Pinger.MAX_INTERVAL);

            this.timeout = setTimeout(this.ping.bind(this), Math.round(timeout));
            this.attempt++;

            f();
        }.bind(this)
    ]);

    this.player.play();
};

Pinger.prototype.start = function () {
    this.ping();
};

Pinger.prototype.stop = function () {
    if (this.timeout) {
        clearTimeout(this.timeout);
        delete this.timeout;
    }

    if (this.player) {
        this.player.stop();
        delete this.player;
    }
};

Pinger.BACKOFF = 1.4;
Pinger.INTERVAL = connector.utils.parseInterval("2 seconds");
Pinger.MAX_INTERVAL = connector.utils.parseInterval("1 minute");
Pinger.TIMEOUT = connector.utils.parseInterval("30 minutes");