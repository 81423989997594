/**
 * Created by slava on 23.03.17.
 */

var Social = function (pluginName, options) {
    BasePlugin.call(this, "social", pluginName, options);
};

Social.prototype = Object.create(BasePlugin.prototype);
Social.prototype.constructor = Social;

Social.prototype.isLoggedIn = function () {
    return false;
};

Social.prototype._getUserID = function () {
};

Social.prototype.getAccessToken = function () {
};

Social.prototype.isSupportsNativeShare = function () {
    return false;
};

Social.prototype.isSupportsNativeInvite = function () {
    return false;
};

Social.prototype.isSupportsNativeRequest = function () {
    return false;
};

Social.prototype.isSupportsNativeCommunityJoin = function () {
    return false;
};

Social.prototype.canJoinCommunity = function (callback) {
    callback(false);
};

Social.prototype.onConnected = function () {
    if (this.isLoggedIn()) {
        this.onLogin();
    } else {
        var deviceId = connector.info.deviceID;
        if (deviceId && connector.platform.oneOf(connector.MICROSOFT, connector.RUSTORE)) {
            connector.platform.setUserID(deviceId);
        }
    }
};

Social.prototype.onLogin = function () {
    var oldId = connector.platform.getUserID();
    var newId = this._getUserID();

    console.log("Social.onLogin", oldId, newId);

    connector.platform.setUserID(newId);

    this.deleteAllRequests();

    connector._playerManager.refresh();

    this.bus.trigger("login", {
        oldId: oldId,
        newId: newId
    });
};

Social.prototype.login = function (callback, options) {
    console.log("Social.login");

    this._login(function () {
        if (this.isLoggedIn()) {
            console.log("Social.login success");

            this.onLogin();

            callback(true);
        } else {
            console.log("Social.login fail");

            callback(false);
        }
    }.bind(this), options);
};

Social.prototype._login = function (callback) {
    callback();
};

Social.prototype.logout = function () {
};

Social.prototype.invite = function (data, callback) {
    callback(connector.CODE_FAILED);
};

Social.prototype.request = function (data, callback) {
    callback(connector.CODE_FAILED);
};

Social.prototype.share = function (data, callback) {
    callback(connector.CODE_FAILED);
};

Social.prototype.listFriends = function (callback) {
    if (connector.social.isConnected()) {
        this._listFriends(callback);
    } else {
        callback(connector.CODE_FAILED);
    }
};

Social.prototype._listFriends = function (callback) {
    callback(connector.CODE_FAILED);
};

Social.prototype.listInvitableFriends = function (callback) {
    if (connector.social.isConnected()) {
        this._listInvitableFriends(callback);
    } else {
        callback(connector.CODE_FAILED);
    }
};

Social.prototype._listInvitableFriends = function (callback) {
    callback(connector.CODE_FAILED);
};

Social.prototype.hasLeaderBoard = function () {
    return false;
};

Social.prototype._aboutMe = function (callback) {
    this.aboutUser("me", callback);
};

Social.prototype.aboutUser = function (userId, callback) {
    if (connector.social.isConnected()) {
        this._aboutUser(userId, callback);
    } else {
        callback(connector.CODE_FAILED);
    }
};

Social.prototype._aboutUser = function (userId, callback) {
    callback(connector.CODE_FAILED);
};

Social.prototype.deleteAllRequests = function () {
};

Social.prepareId = function (uid) {
    return "" + uid;
};

Social.prototype.listAvailable = function () {
    return [this.pluginName];
};

Social.MAX_INVITE_RANDOM_FRIENDS = 10;