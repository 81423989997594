/**
 * https://ads.yandex.com/helpcenter/ru/dev/platforms
 *
 * Created by andrey on 17.05.2023
 */

var YandexAppAds = function () {
    AdsPlugin.call(this, connector.YANDEX_APP_ADS, {
        cantLoadAndPlayInParallel: true
    });

    this.onAdLoadedCallback = {};
    this._playCallback = function () {};
};

YandexAppAds.prototype = Object.create(AdsPlugin.prototype);
YandexAppAds.prototype.constructor = YandexAppAds;

YandexAppAds.prototype._connect = function (callback) {
    var config = YandexAppAds.getConfig();

    if (connector.config.debugMode || connector.platform.isTester()) {
        config.rewarded = connector.config.debug.yandex.rewarded_yandex;
        config.interstitial = connector.config.debug.yandex.interstitial_yandex;
        config.banner = connector.config.debug.yandex.banner_yandex;
    }

    connector.platform.callNative("YandexAdsPlugin.init", config, function () {
        connector.platform.subscribeNative("YandexAppAds", this.onNativeEvent.bind(this));
        callback(connector.STATUS_CONNECTED);
    }.bind(this));
};

YandexAppAds.prototype.onNativeEvent = function (event, options) {
    switch (event) {
        case "onAdAlreadyLoading":
            this.bus.trigger(options.name + ":cached", AdsPlugin.ERROR_LOADING_ALREADY_LOADING, this.pluginName);
            break;
        case "onAdLoaded":
            this.onAdLoadResult(options.name, connector.CODE_SUCCEED);
            break;
        case "onAdFailedToLoad":
            this.onAdLoadResult(options.name, options.name === AdsPlugin.STICKY ? ERRORS.SHOW_STICKY.UNKNOWN : AdsPlugin.ERROR_LOADING_FAILED);
            break;
        case "onAdAlreadyRunning":
            if (options.name === AdsPlugin.REWARDED) {
                this.bus.trigger("error:showRewarded", ERRORS.SHOW_REWARDED.AD_ALREADY_RUNNING);
            } else {
                this.bus.trigger("error:showInterstitial", ERRORS.SHOW_INTERSTITIAL.AD_ALREADY_RUNNING);
            }
            break;
        case "onAdFailedToShow":
            this._playCallback(options.name === AdsPlugin.REWARDED ? ERRORS.SHOW_REWARDED.UNKNOWN : ERRORS.SHOW_INTERSTITIAL.UNKNOWN);
            break;
        case "onRewarded":
            this.success = true;
            break;
        case "onAdImpression":
            try {
                var data = JSON.parse(options.data);
                var impressionCost = parseFloat(data.revenueUSD || data.revenue || data.cost) || 0;
                if (options.name === AdsPlugin.STICKY) {
                    this.onImpressionEvent(options.name, impressionCost);
                } else {
                    this.bus.trigger("impressionTest", options.name, impressionCost);
                }
            } catch (e) {
                // do nothing
            }
            break;
        case "onAdDismissed":
            if (this.success) {
                this._playCallback(connector.CODE_SUCCEED);
            } else if (options.name === AdsPlugin.INTERSTITIAL) {
                this._playCallback(connector.CODE_SUCCEED);
            } else {
                this._playCallback(ERRORS.SHOW_REWARDED.UNKNOWN);
            }
            break;
        case "onAdShown":
            break;
    }
};

YandexAppAds.prototype._cacheAd = function (name, callback) {
    this.onAdLoadedCallback[name] = callback;

    if (name === AdsPlugin.REWARDED) {
        connector.platform.callNative("YandexAdsPlugin.cacheRewarded");
    } else {
        connector.platform.callNative("YandexAdsPlugin.cacheInterstitial");
    }
};

YandexAppAds.prototype.onAdLoadResult = function (name, code) {
    if (this.onAdLoadedCallback[name]) {
        this.onAdLoadedCallback[name](code, true);
        this.onAdLoadedCallback[name] = undefined;
    }
};

YandexAppAds.prototype._playAd = function (name, ad, callback) {
    this.success = false;
    this._playCallback = callback;
    connector.platform.callNative("YandexAdsPlugin.show", { name: name });
};

YandexAppAds.prototype.isStickySupported = function () {
    return true;
};

YandexAppAds.prototype.isStickyConfigured = function () {
    return YandexAppAds.getConfig().banner;
};

YandexAppAds.prototype.showSticky = function (callback) {
    connector.platform.callNative("YandexAdsPlugin.showBanner");

    this.onAdLoadedCallback[AdsPlugin.STICKY] = callback;
};

YandexAppAds.prototype.closeSticky = function (callback) {
    connector.platform.callNative("YandexAdsPlugin.hideBanner", callback);
};

YandexAppAds.getConfig = function () {
    var source = connector.info.source;
    return connector.config[source] && connector.config[source].yandexAds;
};

YandexAppAds.isAppropriate = function () {
    return (connector.platform.oneOf(connector.ANDROID) && connector._countryManager.country === "RU" || connector.platform.oneOf(connector.RUSTORE))
        && YandexAppAds.getConfig();
};
