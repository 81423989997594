/**
 * Created by iamso on 19.09.2019.
 */

var DraugiemPayments = function () {
    RestPayments.call(this, connector.DRAUGIEM, {
        sdk: SdkManager.SDK_DRAUGIEM
    });
};

DraugiemPayments.prototype = Object.create(RestPayments.prototype);
DraugiemPayments.prototype.constructor = DraugiemPayments;

DraugiemPayments.prototype.getDefaultCurrency = function () {
    return "EUR";
};

DraugiemPayments.prototype.getProductId = function (product) {
    return product.id.draugiem;
};

DraugiemPayments.prototype.stopSocialPinger = function () {
    if (this.socialPinger) {
        this.socialPinger.stop();
        delete this.socialPinger;
    }
};

DraugiemPayments.prototype.startSocialPinger = function (purchase, callback, options) {
    this.stopSocialPinger();

    this.socialPinger = new Pinger({
        action: function (callback) {
            console.log("ask transaction status: " + purchase.paymentId);
            connector.platform.callNative("transactions/check", { id: purchase.paymentId }, function (code, response) {
                if (response === undefined) {
                    callback(ERRORS.PURCHASE.UNKNOWN);
                    return;
                }

                console.log("status: ", response.status);
                if (response.status === "FAILED") {
                    callback(ERRORS.PURCHASE.UNKNOWN);
                } else if (response.status === "OK") {
                    callback(connector.CODE_SUCCEED);
                } else {
                    callback(ERRORS.PURCHASE.UNKNOWN);
                }
            });
        },

        callback: function (code) {
            callback(code, purchase);
        },

        timeout: options && options.timeout
    });
    this.socialPinger.start();
};

DraugiemPayments.prototype.purchaseOpen = function (product, callback) {
    var priceInCents = Math.floor(product.price * 100);

    if (connector.config.debugMode || connector.platform.isTester()) {
        priceInCents = 1;
    }

    var params = {
        price: priceInCents,
        service: product.productId
    };

    connector.platform.callNative("transactions/create", params, function (code, response) {
        console.log(product.productId, code, response);
        if (code !== connector.CODE_SUCCEED) {
            this.bus.trigger("stream:error", "DraugiemPayments.purchase productId:" + product.productId + " " + JSON.stringify(response));

            callback(ERRORS.PURCHASE.UNKNOWN);
            return;
        }

        var purchase = this.createPurchase(response.transaction.id, product);

        this.startSocialPinger(purchase, callback);

        this.setNotifyActiveListener(function () {
            this.startPurchasePinger(purchase, function (code) {
                callback(code ? ERRORS.PURCHASE.UNKNOWN : connector.CODE_SUCCEED, purchase);
            });
            this.startSocialPinger(purchase, callback, {
                timeout: connector.utils.parseInterval("15 seconds")
            });
        }.bind(this));

        draugiemWindowOpen(response.transaction.link, 350, 400, function () {
            console.log("Draugiem payment window close", product.itemId);
            this.notifyActive();
        }.bind(this));
    }.bind(this));
};

DraugiemPayments.prototype.stopPurchaseActions = function () {
    RestPayments.prototype.stopPurchaseActions.call(this);

    this.stopSocialPinger();
};

DraugiemPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.DRAUGIEM);
};