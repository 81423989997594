/**
 * Created by vladislav on 9/6/2022
 */

var Draugiem = function () {
    Platform.call(this, connector.DRAUGIEM, {
        sdk: SdkManager.SDK_DRAUGIEM
    });
};

Draugiem.prototype = Object.create(Platform.prototype);
Draugiem.prototype.constructor = Draugiem;

Draugiem.prototype.getLocalStoragePrefix = function () {
    return "_draugiem";
};

Draugiem.prototype.listSuggestedLanguages = function () {
    return [connector.LANGUAGE_LATVIAN, connector.LANGUAGE_RUSSIAN];
};

Draugiem.prototype.callNative = function (apiMethod, params, callback) {
    if (!callback && typeof params === "function") {
        callback = params;
        params = {};
    }

    params = Object.assign({
        action: apiMethod,
        apikey: connector.social.getPlugin() && connector.social.getPlugin().accessToken
    }, params);

    console.log("[connector] callNative", apiMethod);

    ConnectorRestClient.post("/draugiem", params, function (response) {
        if (response.error) {
            console.log("[connector] callNative error", apiMethod, response);
            callback(connector.CODE_FAILED, response);
        } else {
            console.log("[connector] callNative success", apiMethod, response);
            callback(connector.CODE_SUCCEED, response);
        }
    });
};