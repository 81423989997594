/**
 * Created by Aleksandr on 13.10.2022.
 */

var PlingaSocial = function () {
    Social.call(this, connector.PLINGA, {
        sdk: SdkManager.SDK_PLINGA
    });
};

PlingaSocial.prototype = Object.create(Social.prototype);
PlingaSocial.prototype.constructor = PlingaSocial;

PlingaSocial.prototype.isSupportsNativeShare = function () {
    return true;
};

PlingaSocial.prototype.isSupportsNativeInvite = function () {
    return true;
};

PlingaSocial.prototype.isSupportsNativeRequest = function () {
    return true;
};

PlingaSocial.prototype._login = function () {
    plingaRpc.invite();
};

PlingaSocial.prototype._aboutUser = function (usersId, callback) {
    var isSingle = !Array.isArray(usersId);

    plingaRpc.getUsers(isSingle ? [usersId] : usersId, function (users) {
        var usersData = users.map(PlingaSocial.formatUserData);
        callback(connector.CODE_SUCCEED, isSingle ? usersData[0] : usersData);
    });
};

PlingaSocial.prototype._aboutMe = function (callback) {
    var info = plingaRpc.getOwner();
    callback(connector.CODE_SUCCEED, PlingaSocial.formatUserData(info));
};

PlingaSocial.prototype._listFriends = function (callback) {
    var list = plingaRpc.getFriends();
    var friends = list.map(PlingaSocial.formatUserData);
    callback(connector.CODE_SUCCEED, friends);
};

PlingaSocial.formatUserData = function (user) {
    return {
        id: user.uid,
        name: user.firstname + " " + user.lastname,
        firstName: user.firstname,
        lastName: user.lastname,
        avatar: user.thumbnailurl
    };
};

PlingaSocial.prototype.invite = function (data, callback) {
    plingaRpc.invite(data.text);
    callback(connector.CODE_SUCCEED);
};

PlingaSocial.prototype.share = function (data, callback) {
    plingaRpc.post({
        target: connector.platform.getUserID(),
        title: data.title,
        body: data.text,
        linktext: data.linktext,
        pic: data.image
    }, function (params) {
        console.log("post callback", params);
        if (params.globalError_ === false) {
            callback(connector.CODE_SUCCEED);
        } else {
            callback(connector.CODE_FAILED);
        }
    });
    callback(connector.CODE_SUCCEED);
};

PlingaSocial.prototype.sendMessage = function (options, callback) {
    console.log(options);
    plingaRpc.sendMessage({
        target: "userid of the receiver",
        title: "text, which is shown as title",
        body: "text of the post",
        pic: "link to a picture, which is shown in the post",
        linktext: "text to display for the link"
    }, callback);
};

PlingaSocial.prototype.getAccessToken = function () {
    var getParams = connector.utils.getRequestParameters();
    return "PLINGA_" + getParams.sessionid + "_" + getParams.sessionkey;
};

PlingaSocial.prototype.isLoggedIn = function () {
    return connector.social.isConnected() && plingaRpc.getOwner().guest !== true;
};

PlingaSocial.prototype._getUserID = function () {
    if (!connector.social.isConnected()) {
        return;
    }

    return plingaRpc.getOwner().uid;
};