/**
 * Created by mac on 4/8/18
 */

var VideoAds = function () {
    AdsPlugin.call(this, connector.TEST, {
        cantLoadAndPlayInParallel: true
    });

    this.bannerProbability = 1;
};

VideoAds.prototype = Object.create(AdsPlugin.prototype);
VideoAds.prototype.constructor = VideoAds;

VideoAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.TEST);
};

VideoAds.prototype.isStickySupported = function () {
    return true;
};

VideoAds.prototype.isStickyAvailable = function () {
    return true;
};

VideoAds.prototype._connect = function (callback) {
    setTimeout(function () {
        callback(connector.STATUS_CONNECTED);
    }, 0);
};

VideoAds.prototype.isInterstitialSupported = function () {
    return true;
};

VideoAds.prototype.isRewardedSupported = function () {
    return true;
};

VideoAds.prototype._cacheAd = function (name, callback) {
    setTimeout(function () {
        callback(connector.CODE_SUCCEED, true);
    }, 5000);
};

VideoAds.prototype._playAd = function (name, ad, callback) {
    var styles = document.createElement("style");
    styles.innerHTML = ""
        + ".video-ads-overlay {"
        + "    position: fixed;"
        + "    left: 0;"
        + "    top: 0;"
        + "    right: 0;"
        + "    bottom: 0;"
        + "    background-color: rgba(200, 200, 255, 1);"
        + "    z-index: 1000;"
        + "    display: flex;"
        + "    justify-content: center;"
        + "    align-items: center;"
        + "  }"
        + "  .video-ads-skip {"
        + "    position: absolute;"
        + "    bottom: 10%;"
        + "    color: white;"
        + "    font-size: 3em;"
        + "    transition: transform 0.2s;"
        + "    user-select: none;"
        + "    font-weight: bold;"
        + "    text-shadow: 0.1em 0.1em 0.2em black;"
        + "  }"
        + "  button.video-ads-skip {"
        + "    cursor: pointer;"
        + "    border: none;"
        + "    background: none;"
        + "  }"
        + "  button.video-ads-skip:hover {"
        + "    transform: scale(1.05);"
        + "  }"
        + "  button.video-ads-skip:active {"
        + "    transform: scale(0.95);"
        + "  }"
        + "  .video-ads-type {"
        + "    position: absolute;"
        + "    bottom: 50%;"
        + "    color: white;"
        + "    font-size: 3em;"
        + "    font-weight: bold;"
        + "    text-shadow: 0.1em 0.1em 0.2em black;"
        + "    transition: transform 0.2s;"
        + "    user-select: none;"
        + "  }";
    document.head.appendChild(styles);

    var overlay = document.createElement("div");
    overlay.className = "video-ads-overlay";
    document.body.appendChild(overlay);

    var type = document.createElement("p");
    type.innerText = name;
    if (window.isShowingPreroll) {
        type.innerText += " (preroll)";
        window.isShowingPreroll = undefined;
    }
    type.className = "video-ads-type";
    overlay.appendChild(type);

    if (name === AdsPlugin.REWARDED) {
        var skip = document.createElement("button");
        skip.innerText = "skip >>";
        skip.className = "video-ads-skip";
        overlay.appendChild(skip);
        skip.onclick = function () {
            stop(ERRORS.SHOW_REWARDED.CLOSED_BY_USER);
        };
    } else if (name === AdsPlugin.INTERSTITIAL) {
        skip = document.createElement("div");
        skip.innerText = "tap anywhere to skip";
        skip.className = "video-ads-skip";
        overlay.appendChild(skip);
        overlay.onclick = function () {
            stop(connector.CODE_SUCCEED);
        };
    }

    var stop = connector.utils.once(function (code) {
        clearTimeout(rewardTimeout);

        document.body.removeChild(overlay);
        document.head.removeChild(styles);

        if (code === connector.CODE_SUCCEED) {
            callback(connector.CODE_SUCCEED);
        } else if (name === AdsPlugin.REWARDED) {
            callback(ERRORS.SHOW_REWARDED.UNKNOWN);
        } else {
            callback(ERRORS.SHOW_INTERSTITIAL.UNKNOWN);
        }
    });

    var rewardTimeout = setTimeout(function () {
        stop(connector.CODE_SUCCEED);
    }, 3000);
};

VideoAds.prototype.setBannerProbability = function (probability) {
    this.bannerProbability = probability;
};

VideoAds.prototype.setBannerSize = function (bannerSize) {
    this.bannerSize = bannerSize;
};

VideoAds.prototype.showSticky = function (callback) {
    var prob = this.bannerProbability || 0.3;
    if (Math.random() > prob) {
        callback && callback(ERRORS.SHOW_STICKY.NO_STICKY);
        return;
    }

    this.closeSticky();

    var bannerSize = this.bannerSize || {
        width: Math.min(728, Math.round(window.innerWidth * 0.95)),
        height: 90
    };

    var banner = this.banner = document.createElement("div");
    document.body.appendChild(banner);
    banner.style.width = bannerSize.width + "px";
    banner.style.maxWidth = bannerSize.width + "px";
    banner.style.height = bannerSize.height + "px";
    banner.style.maxHeight = bannerSize.height + "px";
    banner.style.position = "absolute";
    banner.style.bottom = "0px";
    banner.style.left = "50%";
    banner.style.marginLeft = -bannerSize.width / 2 + "px";
    banner.style.backgroundColor = "white";
    banner.style.zIndex = "10";

    callback && callback(connector.CODE_SUCCEED, bannerSize);
};

VideoAds.prototype.closeSticky = function (callback) {
    if (this.banner) {
        document.body.removeChild(this.banner);
        delete this.banner;
    }

    callback && callback(connector.CODE_SUCCEED);
};
