/**
 * Created by andrey on 23.12.2022
 */

var RestPayments = function (name, options) {
    Payments.call(this, name, options);
};

RestPayments.prototype = Object.create(Payments.prototype);
RestPayments.prototype.constructor = RestPayments;

RestPayments.prototype.purchase = function (product, callback) {
    var purchase = this.createPurchase(undefined, product);

    this.startPurchasePinger(purchase, callback);

    this.purchaseOpen(product, callback);
};

RestPayments.prototype.purchaseOpen = function (product, callback) {
    callback(connector.CODE_FAILED);
};

RestPayments.prototype.restore = function (callback) {
    var path = "/payments/restore/" + connector.info.source + "/" + encodeURIComponent(connector.platform.getUserID());

    var data = {
        version: 3,
        method: this.pluginName
    };

    ConnectorRestClient.post(path, data, function (purchase) {
        var productId = purchase && purchase.productId;

        if (this.getProduct(productId)) {
            callback(connector.CODE_SUCCEED, purchase);
        } else {
            callback(connector.CODE_FAILED);
        }
    }.bind(this), function () {
        callback(connector.CODE_FAILED);
    });
};

RestPayments.prototype.validate = function (purchase, callback) {
    this.startPurchasePinger(purchase, callback, {
        timeout: connector.utils.parseInterval("3 minutes")
    });
};

RestPayments.prototype.consume = function (purchase, callback) {
    this.startPurchasePinger(purchase, function (code) {
        if (code) {
            callback(ERRORS.CONSUME.UNKNOWN);
        } else {
            callback(connector.CODE_SUCCEED);
        }
    }, {
        method: "consume",
        attempts: 5
    });
};

RestPayments.prototype.stopPurchasePinger = function () {
    if (this.purchasePinger) {
        this.purchasePinger.stop();
        delete this.purchasePinger;
    }
};

RestPayments.prototype.startPurchasePinger = function (purchase, callback, options) {
    this.stopPurchasePinger();
    
    callback = connector.utils.once(callback);
    options = options || {};

    var method = options.method || "validate";

    var path = "/payments/" + method + "/" + connector.info.source + "/" + encodeURIComponent(connector.platform.getUserID());

    var data = {
        purchase: purchase,
        version: 5
    };

    this.purchasePinger = new Pinger(Object.assign({}, options, {
        action: function (callback) {
            ConnectorRestClient.send("POST", path, data, function (response) {
                var code = response.code;
                var data = response.data;

                if (code === "ok") {
                    callback(connector.CODE_SUCCEED, data);
                } else {
                    callback(connector.CODE_PENDING);
                }
            });
        },

        callback: function (code, data) {
            if (code === connector.CODE_SUCCEED) {
                var paymentId = data && data.paymentId;

                if (paymentId && (!purchase.paymentId || RestPayments.IsTmpPaymentId(purchase.paymentId))) {
                    purchase.paymentId = paymentId;
                }
            }

            callback(code, purchase);
        }
    }));
    this.purchasePinger.start();
};

RestPayments.prototype.notifyActive = function () {
    if (this.notifyActiveListener) {
        console.log("Payments notifyActive");
        this.notifyActiveListener();
        delete this.notifyActiveListener;
    }
};

RestPayments.IsTmpPaymentId = function (paymentId) {
    return (paymentId + "").startsWith("tmp");
};

RestPayments.prototype.setNotifyActiveListener = function (notifyActiveListener) {
    this.notifyActiveListener = notifyActiveListener;
};

RestPayments.prototype.setPurchaseState = function (purchaseState) {
    if (this.purchaseState !== purchaseState) {
        this.purchaseState = purchaseState;
        this.stopPurchaseActions();

        if (this.purchaseState === undefined) {
            this.bus.trigger("paymentClosed");
        }
    }
};

RestPayments.prototype.stopPurchaseActions = function () {
    this.stopPurchasePinger();

    delete this.notifyActiveListener;
};
