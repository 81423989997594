/**
 * Created by Vladislav on 05.09.2024.
 */

var PlayerManager = function () {
    connector.social.whenConnected(this.refresh.bind(this));

    this.bus = new ConnectorEventEmitter();

    this.isReady = false;

    this.name = "";
    this.firstName = "";
    this.lastName = "";
    this.avatar = "";
    this.id = "";
};

PlayerManager.prototype.whenReady = function (callback) {
    if (this.isReady) {
        callback();
    } else {
        this.bus.once("ready", callback);
    }
};

PlayerManager.prototype.refresh = function () {
    if (!connector.social.isLoggedIn()) {
        return;
    }

    connector.social.getPlugin()._aboutMe(function (code, player) {
        if (code !== connector.CODE_SUCCEED) {
            return;
        }

        if (!player) {
            return;
        }

        if (!this.isReady || player.id !== this.id || player.name !== this.name || player.avatar !== this.avatar) {
            if (player.currency) {
                connector.payments.getPlugin().setCurrency(player.currency);
            }

            this.name = player.name || "";
            this.firstName = player.firstName || "";
            this.lastName = player.lastName || "";
            this.avatar = player.avatar || "";
            this.id = player.id || "";

            if (!this.isReady) {
                this.isReady = true;

                this.bus.trigger("ready");
            }

            this.bus.trigger("changed");
        }
    }.bind(this));
};