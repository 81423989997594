/**
 * Created by slava on 4/21/17.
 */

var VKSocial = function () {
    Social.MAX_INVITE_RANDOM_FRIENDS = 1;

    Social.call(this, connector.VK, {
        sdk: SdkManager.SDK_VK
    });

    if (connector.config.debugMode && !connector.platform.isTester()) {
        document.body.innerHTML = "приложение недоступно";
    }
};

VKSocial.prototype = Object.create(Social.prototype);
VKSocial.prototype.constructor = VKSocial;

VKSocial.prototype.isSupportsNativeShare = function () {
    return true;
};

VKSocial.prototype.isSupportsNativeInvite = function () {
    return true;
};

VKSocial.prototype.isSupportsNativeRequest = function () {
    return true;
};

VKSocial.prototype.sendActivity = function (data, callback) {
    var params = {
        user_id: connector.platform.getUserID(),
        v: VKPlatform.API_VERSION
    };

    params.activity_id = data.activityId;
    if (data.value) {
        params.value = data.value;
    }

    ConnectorRestClient.get("/vkappevents", {}, function () {
        callback(connector.CODE_SUCCEED);
    }, function () {
        callback(connector.CODE_FAILED);
    }, {
        queryParams: params
    });
};

VKSocial.prototype.markAchievement = function (data) {
    if (data.vkId !== undefined) {
        this.sendActivity({
            activityId: data.vkId
        }, function () {});
    }
};

VKSocial.prototype.isLoggedIn = function () {
    return true;
};

VKSocial.prototype._getUserID = function () {
    return connector.utils.getRequestParameters().viewer_id;
};

VKSocial.prototype.getAccessToken = function () {
    return "VK_" + connector.utils.getRequestParameters().auth_key;
};

VKSocial.prototype._login = function (callback) {
    callback();
};

VKSocial.prototype.invite = function (data, callback) {
    connector.platform.callNative("VKWebAppShowInviteBox", {}, callback);
};

VKSocial.prototype.requestToOne = function (uid, data, callback) {
    connector.platform.callNative("VKWebAppShowRequestBox", {
        uid: parseInt(uid),
        message: data.text,
        requestKey: "key"
    }, callback);
};

VKSocial.prototype.request = function (data, callback) {
    var to = data.to;
    if (!Array.isArray(to)) {
        to = [to];
    }

    var sended = [];
    var sendOneRequest = function (id) {
        if (id === to.length) {
            callback(sended.length > Math.floor(to.length / 2) ? connector.CODE_SUCCEED : connector.CODE_CANCELLED, sended);
        } else {
            this.requestToOne(to[id], data, function (response) {
                if (response === connector.CODE_SUCCEED) {
                    sended.push(to[id]);
                }
                sendOneRequest(id + 1);
            });
        }
    }.bind(this);
    sendOneRequest(0);
};

VKSocial.prototype.share = function (data, callback) {
    connector.platform.callNative("VKWebAppShowWallPostBox", {
        message: data.text + "\n" + connector.platform.getGameUrl(),
        attachments: data.image + ",https://vk.com/app" + connector.config.vk.appId
    }, callback);
};

VKSocial.prototype.hasLeaderBoard = function () {
    return connector.info.isMobile;
};

VKSocial.prototype.showLeaderBoard = function (options, callback) {
    connector.platform.callNative("VKWebAppShowLeaderBoardBox", {
        user_result: options.userResult
    }, callback);
};

VKSocial.prototype.askFriendsPermission = function (callback) {
    connector.platform.getPlugin().askPermissions("friends", callback);
};

VKSocial.prototype.listFriendsByApp = function (inApp, callback) {
    var getAppFriendsCb = function (code, appData) {
        if (code !== connector.CODE_SUCCEED) {
            callback(connector.CODE_FAILED, []);
            return;
        }

        if (!appData || !appData.response || !appData.response.map) {
            callback(connector.CODE_FAILED, []);
            return;
        }

        var appIds = appData.response.map(VKSocial.prepareId);

        connector.platform.callNative("friends.get", {
            count: 1000,
            fields: "photo_200"
        }, function (code, data) {
            if (code !== connector.CODE_SUCCEED) {
                callback(connector.CODE_FAILED, []);
                return;
            }

            callback(connector.CODE_SUCCEED, this.prepareFriendsData(data.response && data.response.items, appIds, inApp));
        }.bind(this));
    }.bind(this);

    connector.platform.callNative("friends.getAppUsers", {}, getAppFriendsCb);
};

VKSocial.prototype.prepareFriendsData = function (friendsData, appIds, inApp) {
    var friends = (friendsData || []).filter(function (friend) {
        return friend.deactivated === undefined;
    });

    return friends.map(VKSocial.formatUserData).filter(function (friend) {
        return inApp === (appIds.indexOf(friend.id) !== -1);
    });
};

VKSocial.prototype._listFriends = function (callback) {
    this.listFriendsByApp(true, callback);
};

VKSocial.prototype._listInvitableFriends = function (callback) {
    this.listFriendsByApp(false, callback);
};

VKSocial.prototype._aboutMe = function (callback) {
    connector.platform.callNative("VKWebAppGetUserInfo", {}, function (code, data) {
        if (code === connector.CODE_SUCCEED) {
            callback(connector.CODE_SUCCEED, VKSocial.formatUserData(data));
        } else {
            callback(connector.CODE_FAILED);
        }
    });
};

VKSocial.prepareId = function (uid) {
    return "" + uid;
};

VKSocial.formatUserData = function (user) {
    return {
        id: VKSocial.prepareId(user.id),
        name: user.first_name + " " + user.last_name,
        firstName: user.first_name,
        lastName: user.last_name,
        avatar: (user.photo_200 && user.photo_200.indexOf("camera_200.png") === -1) ? user.photo_200 : undefined
    };
};

VKSocial.DEFAULT_HEIGHT = 720;
VKSocial.DEFAULT_WIDTH = 800;
VKSocial.MIN_HEIGHT = 480;
VKSocial.SCREEN_HEIGHT_RESERVED = 230;
