/**
 * Created by slava on 4/21/17.
 */

var DraugiemSocial = function () {
    Social.call(this, connector.DRAUGIEM, {
        sdk: SdkManager.SDK_DRAUGIEM
    });
};

DraugiemSocial.prototype = Object.create(Social.prototype);
DraugiemSocial.prototype.constructor = DraugiemSocial;

DraugiemSocial.prototype.isSupportsNativeShare = function () {
    return true;
};

DraugiemSocial.prototype.isSupportsNativeInvite = function () {
    return true;
};

DraugiemSocial.prototype.isSupportsNativeRequest = function () {
    return true;
};

DraugiemSocial.prototype._connect = function (callback) {
    var tokenRetrieved = function (token) {
        this.accessToken = token;
        connector.utils.setUrlHash({ saved_token: token });
        connector.platform.callNative("userdata", function (code, result) {
            this.user = DraugiemSocial.formatUserData(Object.values(result.users)[0]);
            callback(code === connector.CODE_SUCCEED ? connector.STATUS_CONNECTED : connector.STATUS_DISCONNECTED);
        }.bind(this));
    }.bind(this);

    var authCode = connector.utils.getRequestParameters().dr_auth_code;
    var oldData = connector.dataLoader.load(SimpleDataLoader.TYPES.DRAUGIEM_AUTH_DATA);

    if (oldData && oldData.code === authCode) {
        tokenRetrieved(oldData.token);
    } else {
        connector.platform.callNative("authorize", { code: authCode }, function (code, result) {
            if (!result || result.error || !result.apikey) {
                if (result && result.error && result.error.code === 106) {
                    var tokenFromHash = connector.utils.getRequestParameters(window.location.hash).saved_token;
                    if (tokenFromHash) {
                        tokenRetrieved(tokenFromHash);
                        return;
                    }
                }

                var debugData = JSON.stringify({
                    arguments: arguments,
                    auth_code: authCode
                });

                this.bus.trigger("stream:error", { group: "DRAUGIEM_AUTH_ERROR", message: "draugiem authorize empty result " + debugData });
                this.user = DraugiemSocial.formatUserData({ uid: "1" });

                callback(connector.STATUS_DISCONNECTED);
            } else {
                this.accessToken = result.apikey;
                this.user = DraugiemSocial.formatUserData(result.users[result.uid]);

                connector.dataLoader.save(SimpleDataLoader.TYPES.DRAUGIEM_AUTH_DATA, {
                    code: authCode,
                    token: this.accessToken
                });

                connector.utils.setUrlHash({ saved_token: this.accessToken });

                callback(connector.STATUS_CONNECTED);
            }
        }.bind(this));
    }
};

DraugiemSocial.prototype.isLoggedIn = function () {
    return true;
};

DraugiemSocial.prototype._getUserID = function () {
    return this.user && this.user.id;
};

DraugiemSocial.prototype.getAccessToken = function () {
    return "DRG_" + this.accessToken;
};

DraugiemSocial.prototype._login = function (callback) {
    callback();
};

DraugiemSocial.prototype.sendActivity = function (data, callback) {
    var params = {
        prefix: data.title,
        text: data.body,
        link: data.link
    };

    connector.platform.callNative("add_activity", params, function (code, result) {
        if (result && result.status === "OK") {
            callback(connector.CODE_SUCCEED);
        } else {
            callback(connector.CODE_FAILED);
        }
    });
};

DraugiemSocial.prototype.invite = function (data, callback) {
    draugiemSendInvite(data.text, "", function (res) {
        if (res) {
            callback(connector.CODE_SUCCEED);
        } else {
            callback(connector.CODE_CANCELLED);
        }
    });
};

DraugiemSocial.prototype.requestToOne = function (uid, data, callback) {
    var message = data.text + "\n" + connector.platform.getGameUrl();
    draugiemSendMessage(uid, data.title, message, function (sent) {
        if (sent) {
            callback(connector.CODE_SUCCEED);
        } else {
            callback(connector.CODE_FAILED);
        }
    });
};

DraugiemSocial.prototype.request = function (data, callback) {
    var to = data.to;
    if (!Array.isArray(to)) {
        to = [to];
    }

    var sended = [];
    var sendOneRequest = function (id) {
        if (id === to.length) {
            callback(sended.length > 0 ? connector.CODE_SUCCEED : connector.CODE_CANCELLED, sended);
        } else {
            this.requestToOne(to[id], data, function (response) {
                if (response === connector.CODE_SUCCEED) {
                    sended.push(to[id]);
                }
                setTimeout(function () {
                    sendOneRequest(id + 1);
                }, 500);
            });
        }
    }.bind(this);
    sendOneRequest(0);
};

DraugiemSocial.prototype.share = function (data, callback) {
    draugiemSay(data.title, connector.platform.getGameUrl(), data.subTitle, data.text, function (added) {
        if (added) {
            callback(connector.CODE_SUCCEED);
        } else {
            callback(connector.CODE_FAILED);
        }
    });
};

DraugiemSocial.prototype.listFriendsByApp = function () {

};

DraugiemSocial.prototype._listFriends = function (callback) {
    connector.platform.callNative("app_friends", function (code, responce) {
        if (code === connector.CODE_SUCCEED) {
            var friendsData = [];
            if (responce.total > 0) {
                friendsData = Object.values(responce.users).map(DraugiemSocial.formatUserData);
            }
            callback(connector.CODE_SUCCEED, friendsData);
        } else {
            callback(connector.CODE_FAILED);
        }
    });
};

DraugiemSocial.formatUserData = function (userData) {
    return {
        id: "" + userData.uid,
        name: userData.surname + " " + userData.name,
        firstName: userData.name,
        lastName: userData.surname,
        avatar: userData.img
    };
};

DraugiemSocial.prototype._aboutMe = function (callback) {
    callback(connector.CODE_SUCCEED, this.user);
};