/**
 * Created by vladislav on 9/6/2022
 */

var Yandex = function () {
    Platform.call(this, connector.YANDEX, {
        sdk: SdkManager.SDK_YANDEX
    });

    connector.social.needWindowForLogin = true;
};

Yandex.prototype = Object.create(Platform.prototype);
Yandex.prototype.constructor = Yandex;

Yandex.prototype._initialize = function (callback) {
    connector.info.playhop = window.location.href.indexOf("playhop.com") !== -1;

    callback();
};

Yandex.prototype._connect = function (callback) {
    ysdk.feedback.canReview().then(function (res) {
        this.canReviewGame = res.value;
    }.bind(this));

    if (ysdk.environment.i18n) {
        connector.info.changeLanguage(ysdk.environment.i18n.lang);
    }

    callback(connector.STATUS_CONNECTED);
};

Yandex.prototype._loadPlayerObject = function () {
    if (this.player) {
        return Promise.resolve();
    }

    return ysdk.getPlayer({ signed: true }).then(function (player) {
        this.player = player;
    }.bind(this));
};

Yandex.prototype.loadData = function () {
    return this._loadPlayerObject().then(function () {
        return this.player.getData();
    }.bind(this));
};

Yandex.prototype.setData = function () {
    return this.player.setData(connector._platformStorage.data);
};

Yandex.prototype.notifyGameReady = function () {
    if (typeof ysdk !== "undefined" && ysdk.features && ysdk.features.LoadingAPI) {
        ysdk.features.LoadingAPI.ready();
    }
};

Yandex.prototype.getLocalStoragePrefix = function () {
    return "_yandex";
};

Yandex.prototype.listSuggestedLanguages = function () {
    if (typeof ysdk !== "undefined" && ysdk.environment.i18n) {
        return [ysdk.environment.i18n.lang];
    }

    if (connector.info.playhop) {
        return [connector.LANGUAGE_ENGLISH];
    }

    return [connector.LANGUAGE_RUSSIAN];
};

Yandex.prototype.parsePayload = function (callback) {
    callback();
};

Yandex.prototype.canReview = function () {
    return this.canReviewGame;
};

Yandex.prototype._requestReview = function (callback) {
    ysdk.feedback.requestReview().then(function (res) {
        callback(res.feedbackSent ? connector.CODE_SUCCEED : connector.CODE_FAILED);
    });
};
