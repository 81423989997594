/**
 * Created by slava on 4/21/17.
 */

var TestPayments = function () {
    Payments.call(this, "test");
};

TestPayments.prototype = Object.create(Payments.prototype);
TestPayments.prototype.constructor = TestPayments;

TestPayments.prototype._connect = function (callback) {
    this.purchased = connector.dataLoader.load(SimpleDataLoader.TYPES.TESTPAYMENTS) || {};

    callback(connector.STATUS_CONNECTED);
};

TestPayments.prototype.save = function () {
    connector.dataLoader.save(SimpleDataLoader.TYPES.TESTPAYMENTS, this.purchased);
};

TestPayments.prototype.purchase = function (product, callback) {
    setTimeout(function () {
        if (confirm("Buying? " + product.title + " for " + product.textPrice)) {
            setTimeout(function () {
                var purchase = this.createPurchase(Date.now(), product, {
                    purchaseToken: "test_token"
                });
                this.purchased[purchase.paymentId] = purchase;
                this.save();
                callback(connector.CODE_SUCCEED, purchase);
            }.bind(this), TestPayments.PROCESS_TIMEOUT);
        } else {
            setTimeout(function () {
                console.log("TestPayments.Cancelled");
                callback(ERRORS.PURCHASE.CANCEL);
            }, TestPayments.PROCESS_TIMEOUT);
        }
    }.bind(this), 1000);
};

TestPayments.prototype.restore = function (callback) {
    var purchase = Object.values(this.purchased)[0];
    if (purchase) {
        callback(connector.CODE_SUCCEED, purchase);
    } else {
        callback(connector.CODE_FAILED);
    }
};

TestPayments.prototype.consume = function (purchase, callback) {
    if (purchase && purchase.paymentId) {
        delete this.purchased[purchase.paymentId];
        this.save();
    }

    callback(connector.CODE_SUCCEED);
};

TestPayments.isAppropriate = function () {
    return TestSocial.isAppropriate();
};

TestPayments.PROCESS_TIMEOUT = 0;