/**
 * Created by vladislav on 4/2/2024
 */

var AdsDetector = function () {
    var PluginClass = NoAdsPlugin;

    var types = [
        typeof MultiAdsPlugin !== "undefined" && MultiAdsPlugin,
        typeof GDComAds !== "undefined" && GDComAds,
        typeof CrazyAds !== "undefined" && CrazyAds,
        typeof PlingaAds !== "undefined" && PlingaAds,
        typeof YandexAppAds !== "undefined" && YandexAppAds,
        typeof AppLovinAds !== "undefined" && AppLovinAds,
        typeof AdMobAds !== "undefined" && AdMobAds,
        typeof OKAds !== "undefined" && OKAds,
        typeof VKAds !== "undefined" && VKAds,
        typeof InstantAds !== "undefined" && InstantAds,
        typeof YandexWebAds !== "undefined" && YandexWebAds,
        typeof GoogleWebAds !== "undefined" && GoogleWebAds,
        typeof GameSnackAds !== "undefined" && GameSnackAds,
        typeof YandexAds !== "undefined" && YandexAds,
        typeof MSStartAds !== "undefined" && MSStartAds,
        typeof WortalAds !== "undefined" && WortalAds,
        typeof SamsungAds !== "undefined" && SamsungAds,
        typeof WechatAds !== "undefined" && WechatAds,
        typeof VideoAds !== "undefined" && VideoAds
    ];

    for (var i = 0; i < types.length; i++) {
        if (types[i] && types[i].isAppropriate()) {
            PluginClass = types[i];
            break;
        }
    }

    return PluginClass;
};