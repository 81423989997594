/**
 * Created by andrey on 29.09.2023
 */

var WebViewLocalStorage = function () {
    this.data = {};
};

WebViewLocalStorage.prototype.initialize = function (callback) {
    connector.platform.callNative("LocalStoragePlugin.initialize", function (code, options) {
        this.data = options.data || {};
        callback();
    }.bind(this));
};

WebViewLocalStorage.prototype.getItem = function (key) {
    return this.data[key];
};

WebViewLocalStorage.prototype.setItem = function (key, value) {
    connector.platform.callNative("LocalStoragePlugin.setItem", {
        key: key,
        value: value
    });

    this.data[key] = value;
};

WebViewLocalStorage.prototype.removeItem = function (key) {
    connector.platform.callNative("LocalStoragePlugin.removeItem", {
        key: key
    });

    delete this.data[key];
};

WebViewLocalStorage.prototype.clearData = function () {
    connector.platform.callNative("LocalStoragePlugin.clear");

    this.data = {};
};

WebViewLocalStorage.prototype.getData = function (prefix) {
    var data = {};

    for (var key in this.data) {
        if (key.indexOf(prefix) === 0) {
            data[key] = this.data[key];
        }
    }

    return data;
};
