/**
 * Created by andrey on 20.07.2020
 */

var AndroidSocial = function () {
    this.refreshThrottled = connector.utils.timeredThrottle(connector.utils.parseInterval("10 seconds"), this.refresh.bind(this, undefined));

    Social.call(this, connector.ANDROID);

    connector.platform.on("pause", this._updateRefreshInterval.bind(this));
    connector.platform.on("resume", this._updateRefreshInterval.bind(this));
};

AndroidSocial.prototype = Object.create(Social.prototype);
AndroidSocial.prototype.constructor = AndroidSocial;

AndroidSocial.prototype._connect = function (callback) {
    this.refresh(function () {
        callback(connector.STATUS_CONNECTED);
    });
};

AndroidSocial.prototype.onConnected = function () {
    Social.prototype.onConnected.call(this);

    this._updateRefreshInterval();
};

AndroidSocial.prototype._updateRefreshInterval = function () {
    if (!this.isConnected() || connector.platform.isPaused) {
        if (this._refreshInterval) {
            clearInterval(this._refreshInterval);
            this._refreshInterval = undefined;
        }
    } else if (!this._refreshInterval) {
        this.refreshThrottled();
        this._refreshInterval = setInterval(this.refreshThrottled.bind(this), connector.utils.parseInterval("10 minutes"));
    }
};

AndroidSocial.prototype.refresh = function (callback) {
    connector.platform.callNative("AndroidSocialPlugin.refresh", function (code, authDetails) {
        this.onAuthDetails(authDetails);

        callback && callback();
    }.bind(this));
};

AndroidSocial.prototype.isLoggedIn = function () {
    return Boolean(this.userId && this.accessToken);
};

AndroidSocial.prototype._getUserID = function () {
    return this.userId;
};

AndroidSocial.isAndroidUserId = function (id) {
    return id && id.indexOf("android_") === 0;
};

AndroidSocial.prototype.getAccessToken = function () {
    return this.accessToken;
};

AndroidSocial.prototype._login = function (callback) {
    connector.platform.callNative("AndroidSocialPlugin.login", function (code, authDetails) {
        this.onAuthDetails(authDetails);

        callback();
    }.bind(this));
};

AndroidSocial.prototype.onAuthDetails = function (authDetails) {
    if (authDetails.userId && authDetails.accessToken) {
        this.userId = "android_" + authDetails.userId;
        this.accessToken = authDetails.accessToken;
    } else {
        this.userId = undefined;
        this.accessToken = undefined;
    }
};

AndroidSocial.prototype._aboutMe = function (callback) {
    connector.platform.callNative("AndroidSocialPlugin.aboutMe", function (code, response) {
        callback(code, response);
    });
};

AndroidSocial.isAvailable = function () {
    return connector.platform.oneOf(connector.ANDROID, connector.GPG);
};
