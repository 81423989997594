/**
 * Created by andrey on 20.07.2020
 */

var SelectSocial = function () {
    this.socials = {};

    Social.call(this, connector.MULTI_SOCIAL);
};

SelectSocial.prototype = Object.create(Social.prototype);
SelectSocial.prototype.constructor = SelectSocial;

SelectSocial.prototype._connect = function (callback) {
    this.createSocials();

    var actions = Object.keys(this.socials).map(function (key) {
        return function (f) {
            var social = this.socials[key];
            social.connect();
            social.whenConnected(function () {
                if (social.isLoggedIn()) {
                    connector.social._installPlugin(social);
                    callback(connector.STATUS_CONNECTED);
                    return;
                }

                f();
            });
        }.bind(this);
    }, this);

    new ConnectorActionPlayer(actions).play(function () {
        callback(connector.STATUS_CONNECTED);
    });
};

SelectSocial.prototype.createSocials = function () {
    var isTmpId = connector.platform.withTmpID();
    var isAndroidId = typeof AndroidSocial !== "undefined" && AndroidSocial.isAndroidUserId(connector.platform.getUserID());
    var isAppleId = typeof AppleSocial !== "undefined" && AppleSocial.isAppleUserId(connector.platform.getUserID());
    var isFBId = !isTmpId && !isAndroidId && !isAppleId;

    if (isTmpId || isFBId) {
        var webViewFacebook = new WebViewFacebook();
        this.socials[webViewFacebook.pluginName] = webViewFacebook;
    }

    if (typeof AndroidSocial !== "undefined" && isTmpId && AndroidSocial.isAvailable() || isAndroidId) {
        var androidSocial = new AndroidSocial();
        this.socials[androidSocial.pluginName] = androidSocial;
    }

    if (typeof AppleSocial !== "undefined" && isTmpId && AppleSocial.isAvailable() || isAppleId) {
        var appleSocial = new AppleSocial();
        this.socials[appleSocial.pluginName] = appleSocial;
    }

    console.log("SelectSocial.createSocials - " + connector.platform.getUserID() + " - " + Object.keys(this.socials).join(","));
};

SelectSocial.prototype.login = function (callback, options) {
    var key = options.source;
    var social = this.socials[key];

    social._login(function () {
        if (social.isLoggedIn()) {
            console.log("SelectSocial.login success");

            connector.social._installPlugin(social);
            social.onLogin();

            callback(true);
        } else {
            console.log("SelectSocial.login fail");

            callback(false);
        }
    });
};

SelectSocial.prototype.listAvailable = function () {
    return Object.keys(this.socials).sort();
};
