/**
 * https://github.com/discord/embedded-app-sdk/blob/main/docs/in-app-purchases.md
 *
 * Created by razial on 29.08.2024.
 */

var DiscordPayments = function () {
    Payments.call(this, connector.DISCORD, {
        sdk: SdkManager.SDK_DISCORD
    });
};

DiscordPayments.prototype = Object.create(Payments.prototype);
DiscordPayments.prototype.constructor = DiscordPayments;

DiscordPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.DISCORD);
};

DiscordPayments.prototype.getProductId = function (product) {
    return product.id.discord;
};

DiscordPayments.prototype._loadProducts = function (callback) {
    discordSdk.commands.getSkus().then(function (data) {
        console.log("discord products loaded", data);

        var skus = data && data.skus || [];
        var currency;

        var loadedIds = skus.map(function (product) {
            return product.id;
        });

        skus.map(function (product) {
            var ourProduct = this.getProduct(product.id);
            if (ourProduct && product.price) {
                currency = currency || product.price.currency && product.price.currency.toUpperCase();

                var textPrice = DiscordPriceUtils.formatPrice(product.price, "en-US");

                ourProduct.textPrice = textPrice;
                ourProduct.price = parseFloat(textPrice.replace(/[^0-9.-]/g, ""));
                ourProduct.currency = product.price.currency && product.price.currency.toUpperCase();
            }
        }.bind(this));

        this.currency = currency || this.currency;

        callback(connector.CODE_SUCCEED, loadedIds);
    }.bind(this)).catch(function (e) {
        console.log("discord load products error", e);

        this.bus.trigger("error:details:discord:loadProducts", e);

        callback(connector.CODE_FAILED);
    }.bind(this));
};

DiscordPayments.prototype.purchase = function (product, callback) {
    var discordPurchase;

    var onSuccess = connector.utils.wait(2, function () {
        console.log("success purchase", discordPurchase);

        var purchase = this.createPurchase(discordPurchase.id, product);

        callback(connector.CODE_SUCCEED, purchase);
    }.bind(this));

    var player = new ConnectorActionPlayer([
        function (f) {
            discordSdk.unsubscribe("ENTITLEMENT_CREATE").then(f)
                .catch(function (e) {
                    console.log("discord purchase unsubscribe error", e);

                    this.bus.trigger("error:details:discord:purchase", e);

                    callback(ERRORS.PURCHASE.UNKNOWN);
                }.bind(this));
        }.bind(this),

        function (f) {
            discordSdk.subscribe("ENTITLEMENT_CREATE", function () {
                onSuccess();
            }).then(f).catch(function (e) {
                console.log("discord purchase subscribe error", e);

                this.bus.trigger("error:details:discord:purchase", e);

                callback(ERRORS.PURCHASE.UNKNOWN);
            }.bind(this));
        }.bind(this),

        function (f) {
            discordSdk.commands.startPurchase({
                sku_id: product.productId
            }).then(function (entitlements) {
                console.log("discord purchase started", entitlements);

                discordPurchase = entitlements && entitlements[0];
                if (!discordPurchase) {
                    this.bus.trigger("stream:error", "Discord purchase is undefined productId: " + product.productId);

                    callback(ERRORS.PURCHASE.UNKNOWN);
                    return;
                }

                f();
            }.bind(this)).catch(function (e) {
                console.log("discord purchase error", e);

                this.bus.trigger("error:details:discord:purchase", e);

                var code = ERRORS.PURCHASE.UNKNOWN;
                if (e && e.code === 5008 && e.message === "Purchase was canceled by the user.") {
                    code = ERRORS.PURCHASE.CANCEL;
                }

                this.bus.trigger("error:details:instant:purchase", e);

                callback(code);
            }.bind(this));
        }.bind(this)
    ]);

    player.play(onSuccess);
};

DiscordPayments.prototype.validate = function (purchase, callback) {
    var userId = connector.platform.getUserID();
    var paymentId = purchase.paymentId;

    ConnectorRestClient.get("/discordpayments/validate/" + userId + "/" + paymentId, {}, function (response) {
        console.log("discord validate result", response);

        if (response === "ok") {
            callback(connector.CODE_SUCCEED, purchase);
        } else {
            callback(connector.CODE_FAILED);
        }
    }, function (error) {
        console.log("discord validate error", error);
        callback(connector.CODE_SUCCEED, purchase);
    });
};

DiscordPayments.prototype.consume = function (purchase, callback) {
    var userId = connector.platform.getUserID();
    var paymentId = purchase.paymentId;

    ConnectorRestClient.get("/discordpayments/consume/" + userId + "/" + paymentId, {}, function (response) {
        console.log("discord consume result", response);

        if (response === "ok") {
            callback(connector.CODE_SUCCEED);
        } else {
            callback(ERRORS.CONSUME.UNKNOWN);
        }
    }, function (error) {
        console.log("discord consume error", error);
        callback(ERRORS.CONSUME.UNKNOWN);
    });
};

DiscordPayments.prototype.restore = function (callback) {
    var userId = connector.platform.getUserID();

    ConnectorRestClient.get("/discordpayments/restore/" + userId, {}, function (discordPurchase) {
        console.log("discord restore purchase", discordPurchase);

        if (!discordPurchase) {
            callback(connector.CODE_FAILED);
            return;
        }

        var product = this.getProduct(discordPurchase.sku_id);
        var purchase = this.createPurchase(discordPurchase.id, product);

        callback(connector.CODE_SUCCEED, purchase);
    }.bind(this), function (error) {
        console.log("discord restore error", error);

        callback(connector.CODE_FAILED);
    });
};