/**
 * Created by Aleksandr on 01.02.2023
 */

var KongregateSocial = function () {
    Social.call(this, connector.KONGREGATE, {
        sdk: SdkManager.SDK_KONGREGATE
    });
};

KongregateSocial.prototype = Object.create(Social.prototype);
KongregateSocial.prototype.constructor = KongregateSocial;

KongregateSocial.prototype.isSupportsNativeShare = function () {
    return true;
};

KongregateSocial.prototype.isSupportsNativeInvite = function () {
    return true;
};

KongregateSocial.prototype.isSupportsNativeRequest = function () {
    return true;
};

KongregateSocial.prototype._login = function () {
    kongregate.services.showRegistrationBox();
};

KongregateSocial.prototype._aboutMe = function (callback) {
    callback(connector.CODE_SUCCEED, {
        id: connector.platform.getUserID(),
        name: kongregate.services.getUsername()
    });
};

KongregateSocial.prototype.invite = function (data, callback) {
    kongregate.services.showInvitationBox({
        content: data.text,
        filter: "not_played"
    });
    callback(connector.CODE_SUCCEED);
};

KongregateSocial.prototype.share = function (data, callback) {
    kongregate.services.showFeedPostBox({
        content: data.text,
        image_uri: data.image
    }, function (result) {
        console.log("post callback", result);
        if (result.success) {
            callback(connector.CODE_SUCCEED);
        } else {
            callback(connector.CODE_FAILED);
        }
    });
};

KongregateSocial.prototype.sendMessage = function (options, callback) {
    console.log(options, callback);
    // kongregate.services.privateMessage("You just did something really great!");
};

KongregateSocial.prototype.markAchievement = function (data) {
    if (data.kongId !== undefined) {
        kongregate.stats.submit(data.kongId, 1);
    }
};

KongregateSocial.prototype.getAccessToken = function () {
    if (!connector.social.isConnected()) {
        return;
    }

    return "KONGREGATE_" + kongregate.services.getGameAuthToken();
};

KongregateSocial.prototype.isLoggedIn = function () {
    return connector.social.isConnected() && kongregate.services.isGuest() !== true;
};

KongregateSocial.prototype._getUserID = function () {
    if (!connector.social.isConnected()) {
        return;
    }

    return "" + kongregate.services.getUserId();
};