/**
 * Created by razial on 04.04.2024
 */

var SdkManager = function () {
    ConnectorEventEmitter.call(this);

    this.ready = {};
    this.loading = {};
};

SdkManager.prototype = Object.create(ConnectorEventEmitter.prototype);
SdkManager.prototype.constructor = SdkManager;

SdkManager.prototype.loadSdk = function (name, callback) {
    var loader = SdkManager.LOADERS[name];
    if (!loader) {
        callback(connector.CODE_FAILED);
        return;
    }

    if (this.ready[name]) {
        callback(connector.CODE_SUCCEED);
        return;
    }

    if (this.loading[name]) {
        this.loading[name].push(callback);
        return;
    }

    this.loading[name] = [callback];

    var onComplete = function (code) {
        if (code === connector.CODE_SUCCEED) {
            this.ready[name] = true;
            console.log("SdkManager initialize " + name + " sdk done");
        } else {
            console.log("SdkManager initialize " + name + " sdk failed");
        }

        var listeners = this.loading[name];
        this.loading[name] = undefined;

        listeners.forEach(function (callback) {
            try {
                callback(code);
            } catch (e) {
                setTimeout(function () {
                    throw e;
                }, 0);
            }
        });
    }.bind(this);

    console.log("SdkManager loading " + name + " sdk started");

    var player = new ConnectorActionPlayer(loader);
    player.onSuccess(onComplete.bind(this, connector.CODE_SUCCEED));
    player.onFailure(onComplete.bind(this, connector.CODE_FAILED));
    player.play();
};

SdkManager.timeout = function (timeout, step) {
    return function (success, failure) {
        setTimeout(failure, timeout);
        step(success, failure);
    };
};

SdkManager.once = function (step) {
    return function (success) {
        if (step) {
            var oldStep = step;
            step = undefined;
            oldStep.apply(this, arguments);
        } else {
            success();
        }
    };
};

SdkManager.load = function (url, options) {
    return function (success, failure) {
        connector.utils.loadSdk(url, {
            onSuccess: success,
            onFailure: failure,
            crossorigin: options && options.crossorigin
        });
    };
};

SdkManager.SDK_FACEBOOK_CANVAS = "facebook_canvas";
SdkManager.SDK_CRAZY = "crazy";
SdkManager.SDK_DRAUGIEM = "draugiem";
SdkManager.SDK_FOTOSTRANA = "fotostrana";
SdkManager.SDK_GDCOM = "gdcom";
SdkManager.SDK_INSTANT = "instant";
SdkManager.SDK_KONGREGATE = "kongregate";
SdkManager.SDK_MSSTART = "msstart";
SdkManager.SDK_MYMAILRU = "mymailru";
SdkManager.SDK_OK = "ok";
SdkManager.SDK_PLINGA = "plinga";
SdkManager.SDK_SAMSUNG = "samsung";
SdkManager.SDK_SPMOBAGE = "spmobage";
SdkManager.SDK_VK = "vk";
SdkManager.SDK_WORTAL = "wortal";
SdkManager.SDK_YANDEX = "yandex";
SdkManager.SDK_XSOLLA = "xsolla";
SdkManager.SDK_GOOGLE_ADS = "google_ads";
SdkManager.SDK_YANDEX_WEB_ADS = "yandex_web_ads";
SdkManager.SDK_DISCORD = "discord";

SdkManager.LOADERS = {};

SdkManager.LOADERS[SdkManager.SDK_FACEBOOK_CANVAS] = [
    function (success, failure) {
        window.fbAsyncInit = success;

        connector.utils.loadSdk("//connect.facebook.net/en_US/sdk.js", {
            onSuccess: function () {
                setTimeout(failure, 10000);
            },
            onFailure: failure
        });
    },

    function (success, failure) {
        connector.config.plugin = {
            facebook: {
                status: true,
                version: "v3.3",
                appId: connector.config.facebook.appId
            }
        };

        StartFacebook();

        var fbInitInterval = setInterval(function () {
            if (typeof plugin !== "undefined"
                && plugin.FacebookAgent
                && plugin.FacebookAgent.getInstance()
                && plugin.FacebookAgent.getInstance()._isLoggedIn !== undefined
            ) {
                clearInterval(fbInitInterval);
                clearTimeout(fbFailureTimeout);
                setTimeout(success, 500);
            }
        }, 100);

        var fbFailureTimeout = setTimeout(function () {
            clearInterval(fbInitInterval);
            clearTimeout(fbFailureTimeout);
            setTimeout(failure, 500);
        }, 10000);
    }
];

SdkManager.LOADERS[SdkManager.SDK_CRAZY] = [
    SdkManager.load("//sdk.crazygames.com/crazygames-sdk-v2.js"),

    function (success, failure) {
        if (typeof CrazyGames !== "undefined") {
            success();
        } else {
            failure();
        }
    }
];

SdkManager.LOADERS[SdkManager.SDK_DRAUGIEM] = [
    SdkManager.load("//draugiem.lv/applications/external/draugiem.js", {
        crossorigin: false
    }),

    function (success) {
        // eslint-disable-next-line camelcase
        draugiem_callback_url = connector.config.serverURL + "res/draugiem/callback.html";
        // eslint-disable-next-line camelcase
        draugiem_domain = "www.draugiem.lv";

        success();
    }
];

SdkManager.LOADERS[SdkManager.SDK_FOTOSTRANA] = [
    SdkManager.load(connector.utils.getRequestParameters().fsapi, {
        crossorigin: false
    }),

    function (success) {
        // eslint-disable-next-line new-cap
        window.FSClient = new fsapi(connector.config.fotostrana.appId, connector.config.fotostrana.privateKey);
        FSClient.init(function () {
            console.log("API Error!");
        });
        success();
    }
];

SdkManager.LOADERS[SdkManager.SDK_GDCOM] = [
    function (success) {
        window.GD_OPTIONS = {
            gameId: connector.config.gdcom.appId,
            onEvent: function (event) {
                console.log(SdkManager.SDK_GDCOM, "sdk event", JSON.stringify(event));
                connector.sdk.trigger(SdkManager.SDK_GDCOM, event);
            }
        };

        success();
    },

    SdkManager.load("//html5.api.gamedistribution.com/main.min.js")
];

SdkManager.LOADERS[SdkManager.SDK_INSTANT] = [
    SdkManager.load("//connect.facebook.net/en_US/fbinstant.7.1.js"),

    SdkManager.timeout(60000, function (success, failure) {
        FBInstant.initializeAsync().then(function () {
            var onCompleteCallback = function () {
                clearInterval(waitUserInterval);
                clearTimeout(waitUserTimeout);
                success();
            };

            var waitUserInterval = setInterval(function () {
                if (FBInstant.player.getID()) {
                    onCompleteCallback();
                }
            }, 100);

            var waitUserTimeout = setTimeout(function () {
                onCompleteCallback();
            }, 30000);
        }).catch(failure);
    })
];

SdkManager.LOADERS[SdkManager.SDK_KONGREGATE] = [
    SdkManager.load("//cdn1.kongregate.com/javascripts/kongregate_api.js", {
        crossorigin: false
    }),

    SdkManager.timeout(10000, function (success) {
        kongregateAPI.loadAPI(function () {
            window.kongregate = kongregateAPI.getAPI();
            success();
        });
    })
];

SdkManager.LOADERS[SdkManager.SDK_MSSTART] = [
    SdkManager.load("//assets.msn.com/staticsb/statics/latest/msstart-games-sdk/msstart-v1.0.0-rc.14.min.js", {
        crossorigin: false
    })
];

SdkManager.LOADERS[SdkManager.SDK_MYMAILRU] = [
    SdkManager.load("//connect.mail.ru/js/loader.js", {
        crossorigin: false
    }),

    SdkManager.timeout(10000, function (success) {
        mailru.loader.require("api", function () {
            mailru.app.init(connector.config.mm.privateKey);
            success();
        });
    })
];

SdkManager.LOADERS[SdkManager.SDK_OK] = [
    function (success) {
        window.API_callback = function (method, result, data) {
            connector.sdk.trigger(SdkManager.SDK_OK, method, result, data);
        };
        success();
    },

    SdkManager.load("//api.ok.ru/js/fapi5.js", {
        crossorigin: false
    }),

    SdkManager.timeout(10000, function (success, failure) {
        var rParams = FAPI.Util.getRequestParameters();

        FAPI.init(rParams.api_server, rParams.apiconnection, success, failure);
    })
];

SdkManager.LOADERS[SdkManager.SDK_PLINGA] = [
    SdkManager.load("//s3.amazonaws.com/imgs3.plinga.de/general/easyXDM.min.js"),

    function (success) {
        easyXDM.DomHelper.requiresJSON("//s3.amazonaws.com/imgs3.plinga.de/general/json2.min.js");
        success();
    },

    SdkManager.load("//s3.amazonaws.com/imgs3.plinga.de/plingaRpc/plingaRpc.js"),

    SdkManager.timeout(10000, function (success) {
        plingaRpc.init(success);
    })
];

SdkManager.LOADERS[SdkManager.SDK_SAMSUNG] = [
    // must be in index.html
    // <script src="https://gtg.samsungapps.com/gsinstant-sdk/gsinstant.0.44.js"> </script>
    // <script src="https://img.samsungapps.com/gsinstant-sdk/iapinstant.1.0.js"> </script>

    SdkManager.timeout(10000, function (success, failure) {
        GSInstant.initializeAsync().then(success).catch(failure);
    })
];

SdkManager.LOADERS[SdkManager.SDK_SPMOBAGE] = [
    function (success, failure) {
        var sdkPath = connector.config.debugMode
            ? "https://cdn-sb-connect.mobage.jp/jssdk/mobage-jssdk-client.3.10.1.min.js"
            : "https://cdn-connect.mobage.jp/jssdk/mobage-jssdk-client.3.10.1.min.js";

        document.addEventListener("mobageReady", success);

        connector.utils.loadSdk(sdkPath, {
            onSuccess: function () {
                setTimeout(failure, 10000);
            },
            onFailure: failure,
            crossorigin: false
        });
    },

    function (success, failure) {
        try {
            mobage.init({
                clientId: SPMobageSocial.getClientId(),
                redirectUri: SPMobageSocial.getRedirectUrl()
            });
            success();
        } catch (e) {
            failure();
        }
    }
];

SdkManager.LOADERS[SdkManager.SDK_VK] = [
    SdkManager.load("//unpkg.com/@vkontakte/vk-bridge/dist/browser.min.js"),

    SdkManager.timeout(10000, function (success, failure) {
        if (!vkBridge.isWebView()) {
            vkBridge.send("VKWebAppInit", {});
            success();
            return;
        }

        var cb = function (event) {
            if (event.detail.type === "VKWebAppInitResult") {
                vkBridge.unsubscribe(cb);
                success();
            }
            if (event.detail.type === "VKWebAppInitFailed") {
                vkBridge.unsubscribe(cb);
                vkBridge.donate(101);
                failure();
            }
        };

        vkBridge.subscribe(cb);
        vkBridge.send("VKWebAppInit", {});
    })
];

SdkManager.LOADERS[SdkManager.SDK_WORTAL] = [
    function (success, failure) {
        window.addEventListener("wortal-sdk-initialized", success);

        connector.utils.loadSdk("//storage.googleapis.com/html5gameportal.com/wortal-sdk/wortal-core-1.6.13.js", {
            onSuccess: function () {
                setTimeout(failure, 10000);
            },
            onFailure: failure
        });
    }
];

SdkManager.LOADERS[SdkManager.SDK_YANDEX] = [
    function (success, failure) {
        if (typeof Promise === "undefined") {
            connector.utils.loadSdk("//cdn.jsdelivr.net/bluebird/latest/bluebird.core.min.js", {
                onSuccess: success,
                onFailure: failure
            });
        } else {
            success();
        }
    },

    SdkManager.load("//sdk.games.s3.yandex.net/sdk.js"),

    SdkManager.timeout(10000, function (success, failure) {
        if (typeof YaGames === "undefined" || typeof YaGames.init !== "function") {
            failure();
            return;
        }

        YaGames.init().then(function (ysdk) {
            window.ysdk = ysdk;
            success();
        }).catch(failure);
    })
];

SdkManager.LOADERS[SdkManager.SDK_XSOLLA] = [
    SdkManager.load("//cdn.xsolla.net/embed/paystation/1.2.7/widget.min.js")
];

SdkManager.LOADERS[SdkManager.SDK_GOOGLE_ADS] = [
    function (success, failure) {
        var xiaomiClient = connector.config.xiaomi && connector.config.xiaomi.adsense && connector.config.xiaomi.adsense.client;
        var client = connector.platform.oneOf(connector.XIAOMI) && xiaomiClient || connector.config.adsense.client;

        var attrs = {
            "data-ad-client": client,
            "data-ad-frequency-hint": "5s"
        };

        if (connector.config.debugMode) {
            attrs["data-adbreak-test"] = "on";
        }

        connector.utils.loadSdk("//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=" + client, {
            attrs: attrs,
            onSuccess: success,
            onFailure: failure
        });
    },

    SdkManager.once(function (success) {
        window.adsbygoogle = window.adsbygoogle || [];

        window.adsbygoogle.push({
            preloadAdBreaks: "on",
            sound: "on",
            onReady: function () {
                window.googleadsReady = true;

                if (window.googleadsOnReady) {
                    window.googleadsOnReady();
                    delete window.googleadsOnReady;
                }
            }
        });

        success();
    }),

    SdkManager.timeout(10000, function (success) {
        if (window.googleadsReady) {
            success();
        } else {
            window.googleadsOnReady = success;
        }
    })
];

SdkManager.LOADERS[SdkManager.SDK_YANDEX_WEB_ADS] = [
    function (success, failure) {
        window.yaContextCb = window.yaContextCb || [];

        connector.utils.loadSdk("https://yandex.ru/ads/system/context.js", {
            onSuccess: success,
            onFailure: failure
        });
    }
];

SdkManager.LOADERS[SdkManager.SDK_DISCORD] = [
    // must be in index.html
    // <script src="discord-1.4.2.3.min.js"></script>

    SdkManager.timeout(10000, function (success, failure) {
        window.discordSdk = new DiscordSDK(connector.config.discord.appId);
        window.discordSdk.ready().then(success).catch(failure);
    })
];
