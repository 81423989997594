/**
 * Created by razial on 09.04.2024.
 */

var ConnectorEventEmitter = function () {
    this.bindings = {};
};

ConnectorEventEmitter.prototype.once = function (eventName, callback) {
    var handler = this.on(eventName, function () {
        handler.clear();
        callback.apply(this, arguments);
    }.bind(this));

    return handler;
};

ConnectorEventEmitter.prototype.on = function (eventName, callback) {
    if (!this.bindings[eventName]) {
        this.bindings[eventName] = [];
    }
    this.bindings[eventName].push(callback);

    var handler = {
        clear: function () {
            if (this.bindings[eventName]) {
                var index = this.bindings[eventName].indexOf(callback);
                if (index >= 0) {
                    this.bindings[eventName].splice(index, 1);
                }
            }
        }.bind(this)
    };

    return handler;
};

ConnectorEventEmitter.prototype.off = function (eventName) {
    this.bindings[eventName] = undefined;
};

ConnectorEventEmitter.prototype.purge = function () {
    this.bindings = {};
};

ConnectorEventEmitter.prototype.trigger = function () {
    var args = [];
    for (var i = 0; i < arguments.length; i++) {
        args.push(arguments[i]);
    }
    if (this.proxyBus) {
        this.proxyBus.trigger.apply(this.proxyBus, arguments);
    }

    var eventName = args.shift();
    if (this.bindings[eventName]) {
        this.bindings[eventName].slice().forEach(function (callback) {
            callback.apply(this, args);
        });
    }
};

ConnectorEventEmitter.prototype.setProxy = function (proxyBus) {
    this.proxyBus = proxyBus;
};
