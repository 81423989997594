/**
 * Created by mac on 4/8/18
 */

var VKAds = function () {
    AdsPlugin.call(this, connector.VK, {
        sdk: SdkManager.SDK_VK
    });
};

VKAds.prototype = Object.create(AdsPlugin.prototype);
VKAds.prototype.constructor = VKAds;

VKAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.VK);
};

VKAds.prototype.isStickySupported = function () {
    return true;
};

VKAds.prototype._cacheAd = function (name, callback) {
    vkBridge.send("VKWebAppCheckNativeAds", {
        "ad_format": name === AdsPlugin.REWARDED ? "reward" : "interstitial"
    }).then(function (data) {
        if (data.result) {
            console.log("VKWebAppCheckNativeAds: have ads!", name);
            callback(connector.CODE_SUCCEED, true);
        } else {
            console.log("VKWebAppCheckNativeAds: no ads!", name, data);
            callback(AdsPlugin.ERROR_LOADING_FAILED);
        }
    }).catch(function (error) {
        console.log("VKWebAppCheckNativeAds: error", name, error);
        callback(AdsPlugin.ERROR_LOADING_UNKNOWN);
    });
};

VKAds.prototype._playAd = function (name, ad, callback) {
    vkBridge.send("VKWebAppShowNativeAds", {
        ad_format: name === AdsPlugin.REWARDED ? "reward" : "interstitial"
    }).then(function (data) {
        if (data.result) {
            console.log("VKWebAppShowNativeAds: shown", name);
            callback(connector.CODE_SUCCEED);
        } else {
            console.log("VKWebAppShowNativeAds: canceled", name, data);
            if (name === AdsPlugin.REWARDED) {
                callback(ERRORS.SHOW_REWARDED.UNKNOWN);
            } else {
                callback(ERRORS.SHOW_INTERSTITIAL.UNKNOWN);
            }
        }
    }).catch(function (error) {
        console.log("VKWebAppShowNativeAds: error", name, error);
        if (name === AdsPlugin.REWARDED) {
            callback(ERRORS.SHOW_REWARDED.UNKNOWN);
        } else {
            callback(ERRORS.SHOW_INTERSTITIAL.UNKNOWN);
        }
    });
};

VKAds.prototype.showSticky = function (callback) {
    if (!this.bannerAdListener) {
        this.bannerAdListener = function (event) {
            event = event && event.detail;

            if (!event) {
                return;
            }

            if (event.type === "VKWebAppBannerAdUpdated" && event.data.result === true) {
                this.bus.trigger("sticky:render");
            }
        }.bind(this);

        vkBridge.subscribe(this.bannerAdListener);
    }

    vkBridge.send("VKWebAppShowBannerAd", {
        layout_type: "resize",
        can_close: false
    })
        .then(function (data) {
            if (!data || !data.result) {
                callback(ERRORS.SHOW_STICKY.UNKNOWN);
                return;
            }
            callback(connector.CODE_SUCCEED);
            this.bus.trigger("sticky:render");
        }.bind(this))
        .catch(function () {
            callback(ERRORS.SHOW_STICKY.UNKNOWN);
        });
};

VKAds.prototype.closeSticky = function (callback) {
    vkBridge.send("VKWebAppHideBannerAd")
        .then(function (data) {
            if (!data || !data.result) {
                callback(ERRORS.CLOSE_STICKY.UNKNOWN);
                return;
            }

            callback(connector.CODE_SUCCEED);

            if (this.bannerAdListener) {
                vkBridge.unsubscribe(this.bannerAdListener);
                this.bannerAdListener = undefined;
            }
        })
        .catch(function () {
            callback(ERRORS.CLOSE_STICKY.UNKNOWN);
        });
};