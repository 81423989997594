/**
 * Created by andrey on 19.12.19.
 */

var GDComAds = function () {
    AdsPlugin.call(this, connector.GDCOM, {
        sdk: SdkManager.SDK_GDCOM,
        customImpressionEvent: true
    });
};

GDComAds.prototype = Object.create(AdsPlugin.prototype);
GDComAds.prototype.constructor = GDComAds;

GDComAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.GDCOM, connector.COOLMATHGAMES);
};

GDComAds.prototype._connect = function (callback) {
    if (typeof gdsdk !== "undefined" && gdsdk.preloadAd !== "undefined" && gdsdk.showAd !== "undefined") {
        callback(connector.STATUS_CONNECTED);
    } else {
        callback(connector.STATUS_DISCONNECTED);
    }
};

GDComAds.prototype.isStickySupported = function () {
    return true;
};

GDComAds.prototype.showSticky = function (callback) {
    var container = document.createElement("div");
    container.id = "gdcom-ad-banner-container";
    container.style.display = "block";
    container.style.visibility = "hidden";
    container.style.position = "absolute";
    container.style.zIndex = "2";
    container.style.bottom = "0";
    container.style.margin = "0 auto";
    container.style.height = GDComAds.AD_BANNER_HEIGHT + "px";
    container.style.width = "100%";
    document.body.appendChild(container);

    var onBannerReady = connector.utils.once(function (code) {
        if (!code && !container.getElementsByTagName("iframe").length) {
            code = ERRORS.SHOW_STICKY.NO_STICKY;
        }

        if (code) {
            container.remove();
            callback(code);
            return;
        }

        container.style.visibility = "visible";

        callback(connector.CODE_SUCCEED, GDComAds.AD_BANNER_HEIGHT);
    });

    setTimeout(function () {
        onBannerReady(ERRORS.SHOW_STICKY.TIMEOUT);
    }, GDComAds.AD_BANNER_ERROR_TIMEOUT);

    try {
        gdsdk.showAd(gdsdk.AdType.Display, {
            containerId: "gdcom-ad-banner-container"
        }).then(function () {
            setTimeout(onBannerReady, GDComAds.AD_BANNER_SUCCESS_TIMEOUT);
        }).catch(onBannerReady);
    } catch (e) {
        onBannerReady(e);
    }
};

GDComAds.prototype.closeSticky = function (callback) {
    var container = document.getElementById("gdcom-ad-banner-container");
    if (container) {
        container.remove();
    }

    callback(connector.CODE_SUCCEED);
};

GDComAds.prototype.onSdkEvent = function (event) {
    var name = this.whichIsPlaying();
    if (name === undefined) {
        return;
    }

    switch (event.name) {
        case "LOG":
            break;
        case "AD_ERROR":
            this._playCallbackErrorDelayed && this._playCallbackErrorDelayed(name === AdsPlugin.REWARDED ? ERRORS.SHOW_REWARDED.UNKNOWN : ERRORS.SHOW_INTERSTITIAL.UNKNOWN);
            break;
        case "IMPRESSION":
        case "SDK_GAME_PAUSE":
            this.started = true;
            break;
        case "STARTED":
        case "FIRST_QUARTILE":
        case "MIDPOINT":
        case "THIRD_QUARTILE":
            this.started = true;
            this.onImpressionEvent(name);
            break;
        case "USER_CLOSE":
        case "SKIPPED":
            this._playCallback(name === AdsPlugin.REWARDED ? ERRORS.SHOW_REWARDED.CLOSED_BY_USER : ERRORS.SHOW_INTERSTITIAL.UNKNOWN);
            break;
        case "ALL_ADS_COMPLETED":
        case "COMPLETE":
            if (name === AdsPlugin.REWARDED) {
                this._playCallbackDelayed(ERRORS.SHOW_REWARDED.UNKNOWN);
            } else {
                this._playCallback(connector.CODE_SUCCEED);
            }
            break;
        case "SDK_REWARDED_WATCH_COMPLETE": // can appear after COMPLETE
            this._playCallback(connector.CODE_SUCCEED);
            break;
    }
};

GDComAds.prototype._cacheAd = function (name, callback) {
    try {
        gdsdk.preloadAd(name).then(function (response) {
            console.log("GDCom ads: have ads!", name, response);
            callback(connector.CODE_SUCCEED, true);
        }).catch(function (error) {
            console.log("GDCom ads: NO ads!", error);
            callback(AdsPlugin.ERROR_LOADING_NO_ADS);
        });
    } catch (e) {
        console.log("Error gdsdk.preloadAd", e);
        callback(AdsPlugin.ERROR_LOADING_UNKNOWN);
    }
};

GDComAds.prototype._playAd = function (name, ad, callback) {
    this._playCallback = connector.utils.once(callback);
    this._playCallbackDelayed = connector.utils.once(connector.utils.accumulate(3000, this._playCallback));

    var playCallback = this._playCallback;
    this._playCallbackErrorDelayed = connector.utils.postpone(5000, function (code) {
        if (!this.started) {
            playCallback(code);
        }
    }.bind(this));

    this.started = false;

    try {
        gdsdk.showAd(name)
            .then(function () {})
            .catch(function (error) {
                this._playCallback();
                console.log("GDCom showAd error:", error);
            }.bind(this));
    } catch (e) {
        console.log("GDCom showAd error:", e);
    }
};

GDComAds.AD_BANNER_SUCCESS_TIMEOUT = connector.utils.parseInterval("2 seconds");
GDComAds.AD_BANNER_ERROR_TIMEOUT = connector.utils.parseInterval("30 seconds");

GDComAds.AD_BANNER_HEIGHT = 90;
