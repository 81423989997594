/**
 * Created by iamso on 24.09.2021
 */

var MicrosoftPayments = function () {
    Payments.call(this, connector.MICROSOFT);
    Product.WAIT_NO_MORE = 20000;
};

MicrosoftPayments.prototype = Object.create(Payments.prototype);
MicrosoftPayments.prototype.constructor = MicrosoftPayments;

MicrosoftPayments.prototype.getProductId = function (config) {
    return config.id.microsoft;
};

MicrosoftPayments.prototype._connect = function (callback) {
    connector.platform.callNative("store.init", function (code, response) {
        console.log("MicrosoftPayments.onInit - " + JSON.stringify(response));
        if (response.error || code !== connector.CODE_SUCCEED) {
            callback(connector.STATUS_DISCONNECTED);

            console.log("Error requesting microsoft products", response.error);
            return;
        }

        var products = response.products;
        var currency;

        Object.values(this.products).forEach(function (ourProduct) {
            var msProduct = products[ourProduct.productId];
            if (msProduct) {
                ourProduct.textPrice = msProduct.price;
                ourProduct.currency = msProduct.currencyCode;

                currency = currency || msProduct.currencyCode;

                console.log("MicrosoftPayments product " + ourProduct.itemId + " " + ourProduct.productId + " " + ourProduct.textPrice);
            }
        });

        this.currency = currency;

        callback(connector.STATUS_CONNECTED);
    }.bind(this));
};

MicrosoftPayments.prototype.purchase = function (product, callback) {
    connector.platform.callNative("store.purchase", {
        productId: product.productId
    }, function (code, data) {
        if (data.canceled) {
            console.log("MicrosoftPayments.onPurchaseCancelled", product.itemId);

            callback(ERRORS.PURCHASE.CANCEL);
        } else if (data.error || code !== connector.CODE_SUCCEED) {
            console.log("MicrosoftPayments.onPurchaseFailure", product.itemId, data.error);

            this.bus.trigger("stream:error", "MicrosoftPayments.purchase productId:" + product.productId + " " + JSON.stringify(data.error));

            callback(ERRORS.PURCHASE.UNKNOWN);
        } else {
            console.log("MicrosoftPayments.onPurchaseSuccess", product.itemId, data);

            var purchase = this.createPurchase(data.paymentId, product);

            callback(connector.CODE_SUCCEED, purchase);
        }
    }.bind(this));
};

MicrosoftPayments.prototype.restore = function (callback) {
    connector.platform.callNative(
        "store.restore", 
        {},
        function (code, data) {
            var productId = data.productId;
            var paymentId = data.paymentId;

            if (!productId) {
                callback(connector.CODE_FAILED);
                return;
            }

            var product = this.getProduct(productId);
            if (!product) {
                console.log("[connector] Restore product not found productId:" + productId);

                this.consume({
                    paymentId: paymentId,
                    productId: productId
                }, function () {});

                callback(connector.CODE_FAILED);
                return;
            }

            var purchase = this.createPurchase(paymentId, product);

            callback(connector.CODE_SUCCEED, purchase);
        }.bind(this)
    );
};

MicrosoftPayments.prototype.consume = function (purchase, callback) {
    connector.platform.callNative("store.consume", {
        paymentId: purchase.paymentId,
        productId: purchase.productId
    }, function (code, data) {
        if (data.error) {
            this.bus.trigger("stream:error", "MicrosoftPayments.consume productId:" + purchase.productId + " " + JSON.stringify(data.error));

            callback(connector.CODE_FAILED);
        } else {
            callback(connector.CODE_SUCCEED);
        }
    }.bind(this));
};

MicrosoftPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.MICROSOFT);
};
