/**
 * Created by vladislav on 9/6/2022
 */

var AndroidBase = function (name, options) {
    Platform.call(this, name, options);

    connector.info.os = connector.OS_ANDROID;
    connector.social.needWindowForLogin = true;

    this.needsCountry = true;
};

AndroidBase.prototype = Object.create(Platform.prototype);
AndroidBase.prototype.constructor = AndroidBase;

AndroidBase.prototype._callNative = function (method, options) {
    AndroidNative.callNative(method, JSON.stringify(options));
};

AndroidBase.prototype._initialize = function (callback) {
    new ConnectorActionPlayer([
        function (f) {
            var attempt = 0;

            var waitAndroidNative = function () {
                if (typeof AndroidNative !== "undefined" && typeof AndroidNative.callNative === "function") {
                    f();
                    return;
                }

                setTimeout(waitAndroidNative, 100);

                attempt++;
                if (attempt === 60) {
                    this.bus.trigger("stream:error", "Wait AndroidNative 6 sec");
                }
            }.bind(this);

            waitAndroidNative();
        }.bind(this),

        function (f) {
            this.callNative("AppActivityPlugin.getAppSettings", function (code, settings) {
                connector.info.language = settings.defaultLanguage;

                connector.info.deviceName = settings.deviceName;
                connector.info.deviceVersion = settings.deviceVersion;
                if (settings.idfa && !settings.idfa.startsWith("0000")) {
                    connector.info.deviceID = settings.idfa;
                }

                connector.info.audioPluginAvailable = settings.audioPluginAvailable;

                f();
            });
        }.bind(this),

        function (f) {
            connector.dataLoader.localStorage = new WebViewLocalStorage();
            connector.dataLoader.localStorage.initialize(f);
        }
    ]).play(callback);
};

AndroidBase.prototype.closeApplication = function () {
    connector.platform.callNative("AppActivityPlugin.closeApplication");
};
