/**
 * Created by vladislav on 9/6/2022
 */

var MacOS = function () {
    Apple.call(this, connector.MACOS);

    connector.info.os = connector.OS_OSX;
};

MacOS.prototype = Object.create(Apple.prototype);
MacOS.prototype.constructor = MacOS;