/**
 * Created by vladislav on 9/6/2022
 *
 * https://assets.msn.com/staticsb/statics/latest/msstart-games-sdk/documentation/docs/sdk-apis/index.html
 *
 */

var MSStart = function () {
    Platform.call(this, connector.MSSTART, {
        sdk: SdkManager.SDK_MSSTART
    });

    this.needsCountry = true;
};

MSStart.prototype = Object.create(Platform.prototype);
MSStart.prototype.constructor = MSStart;

MSStart.prototype.getLocalStoragePrefix = function () {
    return "_msstart";
};

MSStart.prototype.parsePayload = function (callback) {
    connector.platform.whenConnected(function () {
        var data = $msstart.switchGamePayload || {};

        callback({
            channel: data.channel
        });
    });
};

MSStart.prototype.switchGame = function (url, data, callback) {
    $msstart.switchGameAsync({
        id: url,
        payloadData: data
    }).then(function () {
        // never happens - changes game on success
    }).catch(function (e) {
        callback(e.code === "SWITCH_GAME_DISMISSED" ? ERRORS.SWITCH_GAME.CANCEL : ERRORS.SWITCH_GAME.UNKNOWN);
    });
};
