/**
 * Created by vladislav on 9/6/2022
 */

var MyMailRu = function () {
    Platform.call(this, connector.MM, {
        sdk: SdkManager.SDK_MYMAILRU
    });

    var params = connector.utils.getRequestParameters();
    if (params.view === "app_mobile") {
        connector.info.isMobile = true;
        connector.info.isInApp = true;
    }
};

MyMailRu.prototype = Object.create(Platform.prototype);
MyMailRu.prototype.constructor = MyMailRu;

MyMailRu.prototype._connect = function (callback) {
    if (this.withHeader()) {
        if (window.outerHeight) {
            mailru.app.utils.setHeight(window.outerHeight - 250);
        }

        setTimeout(function () {
            callback(connector.STATUS_CONNECTED);
        }, 300);
    } else {
        callback(connector.STATUS_CONNECTED);
    }
};

MyMailRu.prototype.getLocalStoragePrefix = function () {
    return "_MM";
};

MyMailRu.prototype.listSuggestedLanguages = function () {
    return [connector.LANGUAGE_RUSSIAN];
};

MyMailRu.prototype.parsePayload = function (callback) {
    connector.platform.whenConnected(function () {
        mailru.events.listen(mailru.app.events.readHash, function (payload) {
            payload = payload || {};

            payload = Object.assign(connector.utils.getRequestParameters(), payload);

            if (document.referrer) {
                var url = new URL(document.referrer);
                payload = Object.assign(connector.utils.getRequestParameters(url.search), payload);
            }

            callback(payload);
        });
        mailru.app.utils.hash.read();
    });
};

MyMailRu.prototype.withHeader = function () {
    return !connector.info.isMobile;
};