/**
 * Created by vladislav on 9/6/2022
 */

var OKPlatform = function () {
    this.listeners = {};

    Platform.call(this, connector.OK, {
        sdk: SdkManager.SDK_OK
    });

    var params = connector.utils.getRequestParameters();

    if (["androidweb", "iosweb", "mobweb", "android", "ios"].includes(params.mob_platform)) {
        connector.info.isMobile = true;
    }

    if (["android", "ios"].includes(params.mob_platform)) {
        connector.info.isInApp = true;
    }

    if (params.mob_platform === "android") {
        connector.info.os = connector.OS_ANDROID;
    }

    if (params.mob_platform === "ios") {
        connector.info.os = connector.OS_IOS;
    }
};

OKPlatform.prototype = Object.create(Platform.prototype);
OKPlatform.prototype.constructor = OKPlatform;

OKPlatform.prototype.getLocalStoragePrefix = function () {
    return "_OK";
};

OKPlatform.prototype.listSuggestedLanguages = function () {
    return [connector.LANGUAGE_RUSSIAN];
};

OKPlatform.prototype._connect = function (callback) {
    this.listeners = {};

    if (connector.info.isInApp && connector.info.os === connector.OS_ANDROID) {
        this.initPushNotifications();
    }

    callback(connector.STATUS_CONNECTED);
};

OKPlatform.prototype._loadDataKeys = function () {
    return new Promise(function (resolve, reject) {
        FAPI.Client.call({
            method: "storage.getKeys",
            scope: "CUSTOM",
            count: 1000
        }, function (code, data, error) {
            if (code === "ok") {
                resolve(data.keys);
            } else {
                reject(error);
            }
        });
    });
};

OKPlatform.prototype.loadData = function () {
    return this._loadDataKeys().then(function (keys) {
        return new Promise(function (resolve, reject) {
            if (keys.length === 0) {
                resolve({});
                return;
            }

            FAPI.Client.call({
                method: "storage.get",
                scope: "CUSTOM",
                keys: keys
            }, function (code, data, error) {
                if (code === "ok") {
                    var res = {};

                    for (var key in data.data) {
                        res[key] = JSON.parse(data.data[key]);
                    }

                    resolve(res);
                } else {
                    reject(error);
                }
            });
        });
    });
};

OKPlatform.prototype.setData = function (key, data) {
    return new Promise(function (resolve, reject) {
        FAPI.Client.call({
            method: "storage.set",
            key: key,
            value: JSON.stringify(data)
        }, function (code, data, error) {
            if (code === "ok") {
                resolve(true);
            } else {
                reject(error);
            }
        });
    });
};

OKPlatform.prototype.parsePayload = function (callback) {
    var params = connector.utils.getRequestParameters();
    if (params.custom_args) {
        var customArgs = "";
        try {
            customArgs = decodeURIComponent(params.custom_args);
            Object.assign(params, connector.utils.getRequestParameters("?" + customArgs));
        } catch (e) {
            this.bus.trigger("stream:error", "calcChannel decodeURIComponent error, params.custom_args - " + params.custom_args);
        }
    }
    callback(params);
};

OKPlatform.prototype.parseOtherWays = function (callback) {
    this.parsePayload(function (params) {
        var filter = ["rb_17", "promo_shop", "recommended_apps", "game_campaign_portlet", "game_campaign", "vitrine_video", "eventcard", "eventpush"];
        var entryPoint = filter.includes(params.refplace) ? params.refplace : undefined;
        callback(entryPoint);
    });
};

OKPlatform.prototype.onSdkEvent = function (method, result, data) {
    if (this.listeners[method]) {
        var listener = this.listeners[method];
        this.listeners[method] = undefined;
        listener.call(this, result, data);
    }
};

OKPlatform.prototype.openUrl = function (url) {
    var link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("href", url);
    link.click();
};

OKPlatform.prototype.initPushNotifications = function () {
    this.listeners.getPushNotificationsStatus = function (code, data) {
        if (code !== "ok") {
            return;
        }

        data = JSON.parse(data);

        if (data.isGlobalEnabled === "true" && data.isSubscriptionEnabled === "true") {
            this.bus.trigger("pushPermission", true);
        } else {
            FAPI.UI.suggestToEnablePushNotifications();
        }
    }.bind(this);

    this.listeners.suggestToEnablePushNotifications = function (code) {
        this.bus.trigger("pushPermission", code === "ok");
    }.bind(this);

    FAPI.UI.getPushNotificationsStatus();
};

OKPlatform.prototype._canCreateShortcut = function () {
    return connector.info.isInApp && connector.info.os === connector.OS_ANDROID && !connector.dataLoader.load(SimpleDataLoader.TYPES.SHORTCUT_CREATED);
};

OKPlatform.prototype.createShortcut = function (callback) {
    this.listeners.requestDesktopIcon = connector.utils.once(function (result) {
        var code = result === "ok" ? connector.CODE_SUCCEED : connector.CODE_FAILED;

        if (callback) {
            callback(code);
        }

        if (code === connector.CODE_SUCCEED) {
            connector.dataLoader.save(SimpleDataLoader.TYPES.SHORTCUT_CREATED, 1);
        }
    });

    FAPI.invokeUIMethod("requestDesktopIcon");
};

OKPlatform.prototype.canReview = function () {
    return true;
};

OKPlatform.prototype._requestReview = function (callback) {
    this.listeners.showRatingDialog = connector.utils.once(function (result, data) {
        console.log("showRatingDialog", result, data);

        callback(result === "ok" ? connector.CODE_SUCCEED : connector.CODE_FAILED);
    });

    FAPI.UI.showRatingDialog();
};