/**
 * Created by Aleksandr on 15.06.2023
 */

var MSStartSocial = function () {
    Social.call(this, connector.MSSTART, {
        sdk: SdkManager.SDK_MSSTART
    });
};

MSStartSocial.prototype = Object.create(Social.prototype);
MSStartSocial.prototype.constructor = MSStartSocial;

MSStartSocial.prototype.isSupportsNativeShare = function () {
    return true;
};

MSStartSocial.prototype._connect = function (callback) {
    $msstart.getSignedInUserAsync()
        .then(function (player) {
            this.playerId = player.publisherPlayerId || player.playerId;
            console.log("MSStart getSignedInUserAsync", player);
            callback(connector.STATUS_CONNECTED);
        }.bind(this))
        .catch(function (error) {
            console.log("MSStart getSignedInUserAsync error", error);
            callback(connector.STATUS_CONNECTED);
        });
};

MSStartSocial.prototype.isLoggedIn = function () {
    return !!this.playerId;
};

MSStartSocial.prototype._getUserID = function () {
    return this.playerId;
};

MSStartSocial.prototype._login = function (callback) {
    $msstart.signInAsync().then(function (player) {
        this.playerId = player.publisherPlayerId || player.playerId;
        console.log("MSStart login", player);

        callback();
    }.bind(this)).catch(function (error) {
        console.log("MSStart login error", error);

        callback();
    });
};

MSStartSocial.prototype.share = function (data, callback) {
    console.log("MS Start share", data);

    $msstart.shareAsync({
        title: data.title,
        text: data.text,
        image: data.image
    }).then(function () {
        console.log("MSStart share: success!");
        callback(connector.CODE_SUCCEED);
    }).catch(function (error) {
        console.log("MSStart share error", error);
        callback(connector.CODE_FAILED);
    });
};

MSStartSocial.prototype._aboutMe = function (callback) {
    callback(connector.CODE_SUCCEED, {
        name: "MSN Player " + (connector.platform.getUserID() + "").substring(0, 18)
    });
};

MSStartSocial.prototype.getAccessToken = function () {
    return "msstart";
};