/**
 * Created by Aleksandr on 29.09.2022.
 */

var PlingaPayments = function () {
    RestPayments.call(this, connector.PLINGA, {
        sdk: SdkManager.SDK_PLINGA
    });
};

PlingaPayments.prototype = Object.create(RestPayments.prototype);
PlingaPayments.prototype.constructor = PlingaPayments;

PlingaPayments.prototype.purchaseOpen = function (product, callback) {
    plingaRpc.purchasePackage(product.itemId, function (data) {
        if (data.paymentStatus === "paid") {
            var purchase = this.createPurchase(undefined, product, {
                packageName: data.packageName
            });

            callback(connector.CODE_SUCCEED, purchase);
        } else {
            this.bus.trigger("stream:error", "PlingaPayments.purchase productId:" + product.productId + " " + JSON.stringify(data));

            callback(ERRORS.PURCHASE.UNKNOWN);
        }
    }.bind(this));
};

PlingaPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.PLINGA);
};
