/**
 * Created by Andrey Popov on 5/5/21
 */

var XsollaPayments = function () {
    RestPayments.call(this, connector.XSOLLA, {
        sdk: SdkManager.SDK_XSOLLA
    });

    if (connector.config.debugMode) {
        XsollaPayments.AUTH_TOKEN_LIFETIME = connector.utils.parseInterval("1 second");
    }

    this.sandbox = Boolean(connector.config.debugMode) && !connector.platform.oneOf(connector.CRAZY);

    this.route = connector.platform.oneOf(connector.CRAZY) ? "/crazypayments/" : "/xsollapayments/";
};

XsollaPayments.prototype = Object.create(RestPayments.prototype);
XsollaPayments.prototype.constructor = XsollaPayments;

XsollaPayments.prototype.stopPurchaseActions = function () {
    RestPayments.prototype.stopPurchaseActions.apply(this, arguments);

    this.stopStatusPinger();
};

XsollaPayments.prototype.purchase = function (product, callback) {
    this.getAuthToken(function (code, auth) {
        if (code !== connector.CODE_SUCCEED) {
            callback(ERRORS.PURCHASE.UNKNOWN);
            return;
        }

        this.getPaymentToken(auth, product, function (code, order) {
            if (code !== connector.CODE_SUCCEED) {
                callback(ERRORS.PURCHASE.UNKNOWN);
                return;
            }

            this.openWidget(product, order, callback);
        }.bind(this));
    }.bind(this));
};

XsollaPayments.prototype.getPaymentToken = function (auth, product, callback) {
    // https://developers.xsolla.com/ru/doc/pay-station/how-to/how-to-get-payment-token/
    ConnectorRestClient.post("https://store.xsolla.com/api/v2/project/" + auth.project + "/payment/item/" + product.itemId, {
        quantity: 1,
        sandbox: this.sandbox
    }, function (response) {
        console.log("payment token", response);

        if (!response || !response.token) {
            console.log("getPaymentToken emoty token");
            callback(connector.CODE_FAILED);
            this.bus.trigger("stream:logs", "xsolla_init_widget_failed");

            this.bus.trigger("stream:error", "XsollaPayments.getPaymentToken productId:" + product.productId, response);

            return;
        }

        callback(connector.CODE_SUCCEED, response.token);
    }.bind(this), function (error) {
        callback(connector.CODE_CANCELLED);
        this.bus.trigger("stream:logs", "xsolla_init_widget_failed");
        console.log("Failed to create Xsolla order: " + error);
    }.bind(this), {
        authorization: "Bearer " + auth.token
    });
};

XsollaPayments.prototype.getAuthToken = function (callback) {
    var data = {
        userid: connector.platform.getUserID(),
        name: connector.player.name
    };

    var auth = connector.dataLoader.load(SimpleDataLoader.TYPES.XSOLLA2_PAYMENTS_AUTH);

    if (auth && JSON.stringify(auth.data) === JSON.stringify(data)
        && auth.created + XsollaPayments.AUTH_TOKEN_LIFETIME > Date.now() && auth.created < Date.now()) {
        console.log("auth cache", auth);

        callback(connector.CODE_SUCCEED, auth);
        return;
    }

    ConnectorRestClient.post(
        this.route + "auth",
        data,
        function (response) {
            console.log("auth response", JSON.stringify(response.error || response.auth || response));

            if (response.error || !response.auth) {
                console.log("getAuthToken error - " + (response.error || "empty auth"));
                callback(connector.CODE_CANCELLED);
                this.bus.trigger("stream:logs", "xsolla_init_widget_failed");

                this.bus.trigger("stream:error", "XsollaPayments.getAuthToken " + JSON.stringify(response.error));

                return;
            }

            var auth = response.auth;
            auth.data = data;
            auth.created = Date.now();

            connector.dataLoader.save(SimpleDataLoader.TYPES.XSOLLA2_PAYMENTS_AUTH, auth);

            callback(connector.CODE_SUCCEED, auth);
        }.bind(this),
        function (error) {
            console.log("getAuthToken error", error);
            callback(connector.CODE_CANCELLED);
            this.bus.trigger("stream:logs", "xsolla_init_widget_failed");
        }.bind(this)
    );
};

XsollaPayments.prototype.openWidget = function (product, paymentToken, callback) {
    var result = false;

    var purchase = this.createPurchase(undefined, product);

    XPayStationWidget.init({
        lightbox: {
            spinner: "round",
            width: null,
            height: null
        },
        sandbox: this.sandbox,
        iframeOnly: true,
        access_token: paymentToken
    });

    var onOpen = connector.utils.once(function () {
        this.bus.trigger("stream:logs", "xsolla_open_widget");
    }.bind(this));
    XPayStationWidget.on(XPayStationWidget.eventTypes.OPEN, onOpen);

    XPayStationWidget.open();

    XPayStationWidget.on(XPayStationWidget.eventTypes.STATUS, function (event, data) {
        var paymentInfo = data && data.paymentInfo;
        if (!paymentInfo) {
            return;
        }

        if (connector.config.debugMode) {
            console.log("Xsolla payment event: " + JSON.stringify(paymentInfo));
        }

        var paymentStatus = paymentInfo.status;

        if (["invoice", "done", "delivering"].includes(paymentStatus) && paymentInfo.invoice) {
            purchase.paymentId = String(paymentInfo.invoice);
        }

        if (paymentStatus === "done") {
            result = true;
        } else if (paymentStatus === "troubled") {
            callback(ERRORS.PURCHASE.UNKNOWN);
        }
    });

    var onClose = connector.utils.once(function () {
        XPayStationWidget.off(XPayStationWidget.eventTypes.OPEN);
        XPayStationWidget.off(XPayStationWidget.eventTypes.CLOSE);
        XPayStationWidget.off(XPayStationWidget.eventTypes.STATUS);

        if (!result || !purchase.paymentId) {
            callback(ERRORS.PURCHASE.UNKNOWN);
            return;
        }

        if (this.getPurchaseState() === PaymentsManager.STATE_PURCHASE) {
            this.startStatusPinger(purchase, callback);
        }
    }.bind(this));
    XPayStationWidget.on(XPayStationWidget.eventTypes.CLOSE, onClose);
};

XsollaPayments.prototype.stopStatusPinger = function () {
    if (this.statusPinger) {
        this.statusPinger.stop();
        delete this.statusPinger;
    }
};

XsollaPayments.prototype.startStatusPinger = function (purchase, callback) {
    this.stopStatusPinger();

    this.statusPinger = new Pinger({
        action: function (callback) {
            console.log("statusCheck: " + purchase.paymentId);

            ConnectorRestClient.get(
                this.route + "get/" + purchase.paymentId,
                {}, 
                function (response) {
                    if (response.error || !response.payment) {
                        console.log(response.error || "no payment");
                        callback(ERRORS.PURCHASE.UNKNOWN);
                        return;
                    }

                    var payment = response.payment;

                    console.log("statusCheck success: " + JSON.stringify(payment));

                    if (["paid", "done"].includes(payment.payload.status)) {
                        callback(connector.CODE_SUCCEED, purchase);
                    } else {
                        callback(ERRORS.PURCHASE.UNKNOWN);
                    }
                }
            );
        }.bind(this),

        callback: function (code) {
            callback(code, purchase);
        }
    });
    this.statusPinger.start();
};

XsollaPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.MSSTART, connector.CLEVERAPPS, connector.CRAZY, connector.PWA);
};

XsollaPayments.AUTH_TOKEN_LIFETIME = connector.utils.parseInterval("1 hour");
