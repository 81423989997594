/**
 * Created by vladislav on 9/7/2022
 */

var Apple = function (name, options) {
    Platform.call(this, name, options);

    connector.social.needWindowForLogin = true;
};

Apple.prototype = Object.create(Platform.prototype);
Apple.prototype.constructor = Apple;

Apple.prototype._initialize = function (callback) {
    new ConnectorActionPlayer([
        function (f) {
            var attempt = 0;

            var waitAppleNative = function () {
                if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.AppleNative) {
                    f();
                    return;
                }

                setTimeout(waitAppleNative, 100);

                attempt++;
                if (attempt === 60) {
                    this.bus.trigger("stream:error", "Wait AppleNative 6 sec");
                }
            }.bind(this);

            waitAppleNative();
        }.bind(this),

        function (f) {
            this.callNative("ApplePlatformPlugin.getAppSettings", function (code, settings) {
                connector.info.language = settings.defaultLanguage;

                connector.info.deviceName = settings.deviceName;
                connector.info.deviceVersion = settings.deviceVersion;

                if (settings.advertisingId && !settings.advertisingId.startsWith("0000")) {
                    connector.info.deviceID = settings.advertisingId;
                }

                this.appleSocialAvailable = settings.appleSocialAvailable;

                f();
            }.bind(this));
        }.bind(this),

        function (f) {
            connector.dataLoader.localStorage = new WebViewLocalStorage();
            connector.dataLoader.localStorage.initialize(f);
        }
    ]).play(callback);
};

Apple.prototype._callNative = function (method, options) {
    options = Object.assign({}, options || {}, { method: method });

    var appleNative = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.AppleNative;

    if (!appleNative) {
        this.bus.trigger("stream:error", "Empty AppleNative");
        return;
    }

    try {
        appleNative.postMessage(JSON.stringify(options));
    } catch (e) {
        this.bus.trigger("stream:error", "Apple callNative " + method + " error " + e.message, e);
    }
};
