/**
 * Created by slava on 4/21/17.
 */

var FacebookCanvasSocial = function () {
    BaseFbSocial.call(this, connector.FACEBOOK, {
        sdk: SdkManager.SDK_FACEBOOK_CANVAS
    });
};

FacebookCanvasSocial.prototype = Object.create(BaseFbSocial.prototype);
FacebookCanvasSocial.prototype.constructor = FacebookCanvasSocial;

FacebookCanvasSocial.prototype.isSupportsNativeShare = function () {
    return true;
};

FacebookCanvasSocial.prototype.isSupportsNativeInvite = function () {
    return true;
};

FacebookCanvasSocial.prototype.isSupportsNativeRequest = function () {
    return true;
};

FacebookCanvasSocial.prototype.isLoggedIn = function () {
    return connector.social.isConnected() && plugin.FacebookAgent.getInstance().isLoggedIn();
};

FacebookCanvasSocial.prototype._getUserID = function () {
    if (!connector.social.isConnected()) {
        return;
    }

    return plugin.FacebookAgent.getInstance().getUserID();
};

FacebookCanvasSocial.prototype.getAccessToken = function () {
    if (!connector.social.isConnected()) {
        return;
    }

    return plugin.FacebookAgent.getInstance().getAccessToken();
};

FacebookCanvasSocial.prototype._login = function (callback) {
    plugin.FacebookAgent.getInstance().login(FacebookCanvasSocial.PERMISSIONS, function () {
        callback();
    });
};

FacebookCanvasSocial.prototype.invite = function (data, callback) {
    this.request(data, callback);
};

FacebookCanvasSocial.prototype.request = function (data, callback) {
    if (!connector.social.isConnected()) {
        if (callback) {
            callback(connector.CODE_FAILED);
        }
        return;
    }

    delete data.element;

    if (typeof data.to === "object") {
        data.to = data.to.join(",");
    }

    plugin.FacebookAgent.getInstance().appRequest({
        to: data.to,
        message: data.text,
        title: data.title,
        picture: data.image
    }, callback);
};

FacebookCanvasSocial.prototype.share = function (data, callback) {
    var link = this.getShareDialogLink(data);

    console.log("share link", link);

    var shareData = {
        dialog: "feedDialog",
        link: link,
        caption: data.text
    };

    plugin.FacebookAgent.getInstance().dialog(shareData, callback);
};

FacebookCanvasSocial.prototype.api = function (apiMethod, httpMethod, params, callback) {
    if (!connector.social.isConnected()) {
        if (callback) {
            callback(connector.CODE_FAILED);
        }

        return;
    }

    params = params || {};
    if (typeof params === "function") {
        callback = params;
        params = {};
    }

    if (!this.isLoggedIn()) {
        console.log("Not logged in!");
        if (callback) {
            callback(connector.CODE_CANCELLED);
        }
        return;
    }

    if (httpMethod === undefined) {
        httpMethod = plugin.FacebookAgent.HttpMethod.GET;
        callback = function () {
        };
    } else if (typeof httpMethod === "function") {
        callback = httpMethod;
        httpMethod = plugin.FacebookAgent.HttpMethod.GET;
    }
    try {
        plugin.FacebookAgent.getInstance().api(apiMethod, httpMethod, params, callback);
    } catch (e) {
        console.log(e);
        if (callback) {
            callback(connector.CODE_CANCELLED);
        }
    }
};

FacebookCanvasSocial.PERMISSIONS = "public_profile";