/**
 * Created by Vladislav on 19.06.2024.
 */

var SocialManager = function () {

};

SocialManager.prototype.share = function (data, callback) {
    var plugin = connector.social.getPlugin();

    data = this.formatShareData(data);

    var onDone = function (code) {
        var success = code === connector.CODE_SUCCEED;

        plugin.bus.trigger("share", success);

        callback(success);
    };

    if (!connector.social.isConnected()) {
        onDone(connector.CODE_FAILED);
        return;
    }

    if (!connector.social.isSupportsNativeShare) {
        onDone(connector.CODE_FAILED);
        return;
    }

    plugin.share(data, function (code) {
        onDone(code);
    });
};

SocialManager.prototype.invite = function (data, callback) {
    var plugin = connector.social.getPlugin();

    data = this.formatShareData(data);

    var onDone = function (code) {
        var success = code === connector.CODE_SUCCEED;

        plugin.bus.trigger("invite", success);

        callback(success);
    };

    if (!connector.social.isConnected()) {
        onDone(connector.CODE_FAILED);
        return;
    }

    if (this.isInviteViaPlatform()) {
        connector.platform.getPlugin().nativeInviteFriends(data, onDone);
    } else {
        if (!connector.social.isLoggedIn()) {
            onDone(connector.CODE_FAILED);
            return;
        }

        plugin.invite(data, onDone);
    }
};

SocialManager.prototype.joinCommunity = function (callback, nativeOnly) {
    var plugin = connector.social.getPlugin();

    var onDone = function (code) {
        var success = code === connector.CODE_SUCCEED;

        plugin.bus.trigger("joinCommunity", success);

        if (callback) {
            callback(success);
        }
    };

    if (!connector.social.isSupportsNativeCommunityJoin) {
        if (nativeOnly) {
            onDone(connector.CODE_FAILED);
        } else {
            this.openCommunityUrl();

            onDone(connector.CODE_SUCCEED);
        }

        return;
    }

    if (!connector.social.isConnected()) {
        onDone(connector.CODE_FAILED);
        return;
    }

    plugin.canJoinCommunity(function (canJoin) {
        if (canJoin) {
            plugin.joinCommunity(onDone);
        } else if (nativeOnly) {
            onDone(connector.CODE_FAILED);
        } else {
            this.openCommunityUrl();

            onDone(connector.CODE_SUCCEED);
        }
    }.bind(this));
};

SocialManager.prototype.openCommunityUrl = function () {
    connector.platform.openUrl(Platform.getGroupUrl());
};

SocialManager.prototype.request = function (data, callback) {
    var plugin = connector.social.getPlugin();

    var onDone = function (code) {
        var success = code === connector.CODE_SUCCEED;

        if (code) {
            plugin.bus.trigger("error:request", code);
        }

        plugin.bus.trigger("request", success);

        callback(success);
    };

    if (!connector.social.isConnected()) {
        onDone(connector.CODE_FAILED);
        return;
    }

    if (!connector.social.isSupportsNativeRequest) {
        onDone(connector.CODE_FAILED);
        return;
    }

    plugin.request(data, function (code) {
        onDone(code);
    });
};

SocialManager.prototype.formatShareData = function (data) {
    if (!data) {
        data = {};
    }

    if (!data.title) {
        data.title = document.title;
    }

    return data;
};

SocialManager.prototype.isInviteViaPlatform = function () {
    return connector.platform.oneOf(connector.MACOS, connector.IOS, connector.ANDROID, connector.AMAZON);
};