/**
 * Created by razial on 25.08.2024.
 */

var DiscordSocial = function () {
    Social.call(this, connector.DISCORD, {
        sdk: SdkManager.SDK_DISCORD
    });
};

DiscordSocial.prototype = Object.create(Social.prototype);
DiscordSocial.prototype.constructor = DiscordSocial;

DiscordSocial.prototype._connect = function (callback) {
    this.authorize(function () {
        callback(connector.STATUS_CONNECTED);
    });
};

DiscordSocial.prototype._login = function (callback) {
    this.authorize(callback);
};

DiscordSocial.prototype.authorize = function (callback) {
    discordSdk.commands.authorize({
        client_id: connector.config.discord.appId,
        response_type: "code",
        state: "",
        prompt: "none",
        scope: ["identify", "guilds", "applications.commands"]
    }).then(function (result) {
        ConnectorRestClient.post("/discord/token", { code: result.code }, function (data) {
            discordSdk.commands.authenticate({
                access_token: data.access_token
            }).then(function (result) {
                if (result && result.user && result.user.id) {
                    this.accessToken = data.access_token;
                    this.user = DiscordSocial.formatUserData(result.user);

                    callback();
                    return;
                }

                console.log("[connector] discord authentication rejected");
                callback();
            }.bind(this)).catch(function (error) {
                console.log("[connector] discord authentication failed", error);
                callback();
            });
        }.bind(this), function (response) {
            console.log("[connector] can't load discord access token", response.status, response.errorMessage);
            callback();
        });
    }.bind(this)).catch(function (error) {
        console.log("[connector] discord authorization failed", error);
        callback();
    });
};

DiscordSocial.prototype.getAccessToken = function () {
    return "DISCORD_" + this.accessToken;
};

DiscordSocial.prototype.isLoggedIn = function () {
    return Boolean(this.user) && Boolean(this.accessToken);
};

DiscordSocial.prototype._getUserID = function () {
    return this.user && this.user.id;
};

DiscordSocial.prototype._aboutMe = function (callback) {
    if (this.user) {
        callback(connector.CODE_SUCCEED, this.user);
    } else {
        callback(connector.CODE_FAILED);
    }
};

DiscordSocial.prototype.isSupportsNativeInvite = function () {
    return true;
};

DiscordSocial.prototype.invite = function (data, callback) {
    discordSdk.commands.openInviteDialog().then(function () {
        callback(connector.CODE_SUCCEED);
    }).catch(function () {
        callback(connector.CODE_FAILED);
    });
};

DiscordSocial.formatUserData = function (user) {
    // user: {
    //     avatar: null
    //     discriminator: "0"
    //     global_name: "Full User Name"
    //     id: "399150507756617730"
    //     public_flags: 0
    //     username: "r4zi4l"
    // }

    var userid = "" + user.id;
    var avatar = user.avatar;
    var name = user.global_name;

    if (!name) {
        name = user.username + "#" + user.discriminator;
    }

    if (avatar) {
        avatar = "https://cdn.discordapp.com/avatars/" + userid + "/" + avatar + ".png?size=256";
    } else {
        avatar = "https://cdn.discordapp.com/embed/avatars/" + parseInt(userid.slice(-7)) % 6 + ".png";
    }

    return {
        id: userid,
        name: name,
        avatar: avatar
    };
};