/**
 * https://support.google.com/adsense/answer/10762946?hl=ru
 * https://developers.google.com/ad-placement/docs/placement-types
 *
 * Created by Ivan on 01.09.22
 */

var GoogleWebAds = function () {
    AdsPlugin.call(this, connector.GOOGLE_ADS, {
        sdk: SdkManager.SDK_GOOGLE_ADS,
        cantLoadAndPlayInParallel: true
    });
};

GoogleWebAds.prototype = Object.create(AdsPlugin.prototype);
GoogleWebAds.prototype.constructor = GoogleWebAds;

GoogleWebAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.CLEVERAPPS, connector.XIAOMI, connector.PWA, connector.MSSTART);
};

GoogleWebAds.prototype._cacheAd = function (name, callback) {
    if (name === AdsPlugin.INTERSTITIAL) {
        callback(connector.CODE_SUCCEED, true);
        return;
    }

    var code = ERRORS.SHOW_REWARDED.UNKNOWN;
    window.adsbygoogle.push({
        name: name,
        type: "reward",
        beforeReward: function (showAdFn) {
            callback(connector.CODE_SUCCEED, showAdFn);
        },
        adViewed: function () {
            code = connector.CODE_SUCCEED;
        },
        adDismissed: function () {
            code = ERRORS.SHOW_REWARDED.CLOSED_BY_USER;
        },
        adBreakDone: function () {
            if (this._callback) {
                this._callback(code);
                this._callback = undefined;
            } else {
                callback();
            }
        }.bind(this)
    });
};

GoogleWebAds.prototype._playAd = function (name, ad, callback) {
    if (name === AdsPlugin.INTERSTITIAL) {
        window.adsbygoogle.push({
            name: name,
            type: "start",
            beforeAd: function () {
                console.log("Interstitial started");
            },
            adBreakDone: function () {
                console.log("Interstitial finished");
                callback && callback(connector.CODE_SUCCEED);
            }
        });
        return;
    }

    if (typeof ad !== "function") {
        callback && callback(ERRORS.SHOW_REWARDED.NO_ADS);
        return;
    }

    this._callback = callback;
    ad();
};

GoogleWebAds.prototype.isStickySupported = function () {
    if (connector.platform.oneOf(connector.MSSTART)) {
        return (["CN"].includes(connector._countryManager.country) || connector.config.debugMode);
    }

    return true;
};

GoogleWebAds.prototype.isStickyAvailable = function () {
    return window.innerWidth >= GoogleWebAds.AD_BANNER_WIDTH;
};

GoogleWebAds.prototype.isStickyConfigured = function () {
    return connector.config.adsense && connector.config.adsense.slot;
};

GoogleWebAds.prototype.addBannerContainer = function () {
    if (this.bannerContainer) {
        return;
    }

    var container = this.bannerContainer = document.createElement("div");
    container.style.display = "block";
    container.style.position = "absolute";
    container.style.bottom = "0";
    container.style.margin = "0 auto";
    container.style.right = "0";
    container.style.left = "0";
    container.style.height = GoogleWebAds.AD_BANNER_HEIGHT + "px";
    container.style.width = GoogleWebAds.AD_BANNER_WIDTH + "px";
    container.style.zIndex = "2";
    container.style.backgroundColor = "white";

    var ins = this.bannerIns = document.createElement("ins");
    ins.id = "google-ad-banner-container";
    ins.className = "adsbygoogle";
    ins.style.zIndex = "2";
    ins.setAttribute("data-ad-client", connector.config.adsense.client);
    ins.setAttribute("data-ad-slot", connector.config.adsense.slot);

    // if (connector.config.debugMode) {
    //     ins.setAttribute("data-adbreak-test", "on");
    // }

    ins.style.display = "inline-block";
    ins.style.position = "relative";
    ins.style.margin = "0 auto";
    ins.style.height = GoogleWebAds.AD_BANNER_HEIGHT + "px";
    ins.style.width = GoogleWebAds.AD_BANNER_WIDTH + "px";

    document.body.appendChild(container);
    container.appendChild(ins);

    (window.adsbygoogle = window.adsbygoogle || []).push({});
};

GoogleWebAds.prototype.removeBannerContainer = function () {
    if (this.bannerObserver) {
        this.bannerObserver.disconnect();
        this.bannerObserver = undefined;
    }
    if (this.bannerIns) {
        this.bannerIns.remove();
        this.bannerIns = undefined;
    }
    if (this.bannerContainer) {
        this.bannerContainer.remove();
        this.bannerContainer = undefined;
    }
};

GoogleWebAds.prototype.showSticky = function (callback) {
    this.addBannerContainer();

    callback(connector.CODE_SUCCEED, GoogleWebAds.AD_BANNER_HEIGHT);
};

GoogleWebAds.prototype.closeSticky = function (callback) {
    this.removeBannerContainer();

    callback(connector.CODE_SUCCEED);
};

GoogleWebAds.AD_BANNER_WIDTH = 728;
GoogleWebAds.AD_BANNER_HEIGHT = 90;
