/**
 * Created by andrey on 13.10.2023
 */

var PaymentsManager = function (productsConfig) {
    this.pending = [];
    this.consumed = [];
    this.load();

    this.productsConfig = productsConfig;

    this.restoreThrottled = connector.utils.timeredThrottle(10000, this.restore.bind(this));
    this.tryLoadProductsThrottled = connector.utils.timeredThrottle(10000, this.tryLoadProducts.bind(this));

    connector.platform.on("pause", this._updateRefreshInterval.bind(this));
    connector.platform.on("resume", this._updateRefreshInterval.bind(this));

    this._updateRefreshInterval();

    connector.payments.on("changeStatus", function (status) {
        if (status === connector.STATUS_CONNECTED) {
            this.tryLoadProducts();
        }
    }.bind(this));

    connector._countryManager.on("changed", function () {
        this.whenIdle(function () {
            connector.payments._refreshPlugin();
        });
    }.bind(this));
};

PaymentsManager.prototype._updateRefreshInterval = function () {
    if (connector.platform.isPaused) {
        if (this._tryLoadProductsInterval) {
            clearInterval(this._tryLoadProductsInterval);
            this._tryLoadProductsInterval = undefined;
        }

        if (this._restoreInterval) {
            clearInterval(this._restoreInterval);
            this._restoreInterval = undefined;
        }
    } else {
        if (!this._tryLoadProductsInterval) {
            this._tryLoadProductsInterval = setInterval(this.tryLoadProductsThrottled.bind(this), connector.utils.parseInterval("2 minutes"));
        }

        if (!this._restoreInterval) {
            this._restoreInterval = setInterval(this.restoreThrottled.bind(this), connector.utils.parseInterval(connector.config.debugMode ? "1 minute" : "5 minutes"));
        }
    }
};

PaymentsManager.prototype.whenIdle = function (callback) {
    var plugin = connector.payments.getPlugin();

    if (!plugin) {
        callback();
        return;
    }

    if (plugin.isIdle()) {
        callback();
    } else {
        plugin.bus.once("paymentClosed", callback);
    }
};

PaymentsManager.prototype.stop = function () {
    this.stopLoadProductsPlayer();
    this.stopRestorePlayer();
    this.stopPurchasePlayer();
};

PaymentsManager.prototype.stopRestorePlayer = function () {
    if (this.restorePlayer) {
        this.restorePlayer.stop();
        delete this.restorePlayer;
    }
};

PaymentsManager.prototype.restore = function () {
    if (!connector.payments.isConnected() || !connector.payments.getPlugin().isRestoreAvailable()
        || this.isPurchaseRunning() || this.isRestoreRunning()) {
        return;
    }

    var purchase;

    var player = this.restorePlayer = new ConnectorActionPlayer({
        actionTimeout: connector.utils.parseInterval("1 minute"),
        actions: [
            function (f, stop) {
                connector.payments.getPlugin().restore(function (code, restorePurchase) {
                    if (code === connector.CODE_SUCCEED) {
                        restorePurchase.restore = true;
                        purchase = restorePurchase;

                        console.log("[connector] Restore purchase:", restorePurchase);
                        
                        f();
                    } else {
                        stop();
                    }
                });
            },

            function (f, stop) {
                if (this.isConsumed(purchase.paymentId)) {
                    this.consume(purchase);
                    stop();
                    return;
                }

                connector.payments.getPlugin().validate(purchase, function (code) {
                    if (code === connector.CODE_SUCCEED) {
                        f();
                    } else {
                        this.consume(purchase);
                        stop();
                    }
                }.bind(this));
            }.bind(this),

            function (f) {
                var context = this.findPendingPurchaseContext(purchase.productId);

                var restorePurchase = Object.assign({}, context, purchase);

                connector.payments.getPlugin().bus.trigger("restore", restorePurchase);

                f();
            }.bind(this)
        ]
    });

    player.play();
};

PaymentsManager.prototype.load = function () {
    var info = connector.dataLoader.load(SimpleDataLoader.TYPES.PAYMENTS);
    if (info) {
        this.pending = (info.pending || []).slice();
        this.consumed = (info.consumed || []).slice();
    }
};

PaymentsManager.prototype.save = function () {
    connector.dataLoader.save(SimpleDataLoader.TYPES.PAYMENTS, {
        pending: this.pending.slice(-PaymentsManager.PENDING_STORAGE_LIMIT),
        consumed: this.consumed.slice(-PaymentsManager.CONSUMED_STORAGE_LIMIT)
    });
};

PaymentsManager.prototype.savePendingPurchaseContext = function (productId, context) {
    this.pending.push({
        productId: productId,
        context: context
    });
    this.save();
};

PaymentsManager.prototype.indexOfPendingPurchaseContext = function (productId) {
    for (var i = this.pending.length - 1; i >= 0; --i) {
        if (this.pending[i].productId === productId) {
            return i;
        }
    }
};

PaymentsManager.prototype.removePendingPurchaseContext = function (productId) {
    var index = this.indexOfPendingPurchaseContext(productId);
    if (index !== -1) {
        this.pending.splice(index, 1);
        this.save();
    }
};

PaymentsManager.prototype.findPendingPurchaseContext = function (productId) {
    var index = this.indexOfPendingPurchaseContext(productId);
    if (index !== -1) {
        return this.pending[index];
    }
};

PaymentsManager.prototype.isConsumed = function (paymentId) {
    return paymentId && this.consumed.includes(paymentId);
};

PaymentsManager.prototype.consume = function (purchase, callback) {
    callback = callback || function () {};

    this._consumeInner(purchase, function (code) {
        var plugin = connector.payments.getPlugin();

        var product = connector.payments.getProduct(purchase.productId);

        var result = {
            purchase: purchase,
            product: product
        };

        if (code) {
            plugin.bus.trigger("error:consume", code);

            callback();
        } else {
            plugin.bus.trigger("consume", result);

            callback(result);
        }
    });

    if (purchase.restore) {
        this.restoreThrottled();
    }
};

PaymentsManager.prototype._consumeInner = function (purchase, callback) {
    purchase.state = purchase.restore ? 4 : 2;
    delete purchase.restore;

    callback = connector.utils.once(callback);

    if (purchase.paymentId && !this.consumed.includes(purchase.paymentId)) {
        this.removePendingPurchaseContext(purchase.productId);

        this.consumed.push(purchase.paymentId);
        this.save();
    }

    var product = connector.payments.getProduct(purchase.productId);
    if (product.type === "nonConsumable" && connector.payments.oneOf(connector.SAMSUNG)) {
        callback(ERRORS.CONSUME.UNKNOWN);
    } else {
        connector.payments.getPlugin().consume(purchase, callback);
    }

    if (product.type !== "subscription" && !(connector.payments.getPlugin() instanceof RestPayments)) {
        var path = "/payments/add/" + connector.info.source + "/" + encodeURIComponent(connector.platform.getUserID());

        ConnectorRestClient.post(path, {
            purchase: purchase,
            version: 2
        }, function () {}, function (error) {
            var plugin = connector.payments.getPlugin();
            plugin.bus.trigger("stream:error", "Error adding payments " + error.status + " " + error.errorMessage + " " + JSON.stringify(purchase));
        });
    }
};

PaymentsManager.prototype.getLocalizedProduct = function (productKey) {
    var product = connector.payments.getProduct(productKey);

    if (!product) {
        return;
    }

    var localizedProduct = connector.utils.clone(product, true);

    localizedProduct.title = localizedProduct.title[connector.info.language] !== undefined ? localizedProduct.title[connector.info.language] : localizedProduct.title.en;
    localizedProduct.description = localizedProduct.description[connector.info.language] !== undefined ? localizedProduct.description[connector.info.language] : localizedProduct.description.en;

    return localizedProduct;
};

PaymentsManager.prototype.isLoginRequired = function () {
    if (connector.payments.oneOf(connector.XSOLLA) && connector.platform.oneOf(connector.CRAZY)) {
        return true;
    }

    return !connector.payments.oneOf(
        connector.MICROSOFT,
        connector.PLINGA,
        connector.IAP,
        connector.RUSTORE,
        connector.YANDEX,
        connector.TEST,
        connector.YOOKASSA,
        connector.XSOLLA
    );
};

PaymentsManager.prototype.purchase = function (options, callback) {
    this._purchaseInner(options, function (code, result) {
        var plugin = connector.payments.getPlugin();

        if (code) {
            plugin.bus.trigger("error:purchase", code, options);
        } else {
            plugin.bus.trigger("purchase", result);
        }

        callback(result);
    });
};

PaymentsManager.prototype._purchaseInner = function (options, callback) {
    options = options || {};

    callback = connector.utils.once(callback);

    var product = this.getLocalizedProduct(options.id || options.tag);

    if (!product || !product.productId) {
        callback(ERRORS.PURCHASE.PRODUCT_NOT_FOUND);
        return;
    }

    var purchase;

    if (this.isPurchaseRunning()) {
        callback(ERRORS.PURCHASE.ALREADY_OPEN);
        return;
    }

    if (!connector.payments.isConnected()) {
        callback(ERRORS.NOT_CONNECTED);
        return;
    }

    if (connector.payments.isLoginRequired && !connector.social.isLoggedIn({ strict: true })) {
        callback(ERRORS.PURCHASE.LOGIN_REQUIRED);
        return;
    }

    if (connector.payments.isEmailRequired && !options.email) {
        callback(ERRORS.PURCHASE.EMAIL_REQUIRED);
        return;
    }

    var player = this.purchasePlayer = new ConnectorActionPlayer([
        function (f, stop) {
            console.log("PaymentsManager purchase start", product.productId, product.itemId);

            this.savePendingPurchaseContext(product.productId, options.context);

            connector.payments.getPlugin().setPurchaseState(PaymentsManager.STATE_PURCHASE);

            connector.payments.getPlugin().purchase(product, connector.utils.once(function (code, receivedPurchase) {
                purchase = receivedPurchase;

                console.log("PaymentsManager purchase result", product.productId, product.itemId, code);
                console.log("PaymentsManager purchase", JSON.stringify(purchase));

                if (connector.config.debugMode && connector.config.testRestore) {
                    code = connector.CODE_FAILED;
                }

                if (code === connector.CODE_CANCELLED) {
                    this.removePendingPurchaseContext(product.productId);
                }

                if (code === connector.CODE_SUCCEED && !player.isRunning()) {
                    setTimeout(function () {
                        this.restoreThrottled();
                    }.bind(this), 15000);
                    return;
                }

                if (code !== connector.CODE_SUCCEED) {
                    stop(code);
                    return;
                }

                if (this.isConsumed(purchase.paymentId)) {
                    this.consume(purchase);
                    stop();
                    return;
                }

                f();
            }.bind(this)), {
                email: options.email
            });
        }.bind(this),

        function (f, stop) {
            connector.payments.getPlugin().setPurchaseState(PaymentsManager.STATE_VALIDATE);

            console.log("PaymentsManager validate start", product.productId, product.itemId);

            connector.payments.getPlugin().validate(purchase, function (code) {
                console.log("PaymentsManager validate result", product.productId, product.itemId, code);

                if (code !== connector.CODE_SUCCEED) {
                    this.consume(purchase);
                    stop(code);
                    return;
                }

                f();
            }.bind(this));
        }.bind(this)
    ]);

    player.onComplete(function () {
        connector.payments.getPlugin().setPurchaseState(undefined);
    });

    player.onSuccess(function () {
        callback(connector.CODE_SUCCEED, {
            purchase: purchase,
            product: product
        });
    });

    player.onFailure(function (code) {
        code = code || ERRORS.PURCHASE.UNKNOWN;

        callback(code);
    });

    player.play();
};

PaymentsManager.prototype.isPurchaseRunning = function () {
    return this.purchasePlayer && this.purchasePlayer.isRunning();
};

PaymentsManager.prototype.isRestoreRunning = function () {
    return this.restorePlayer && this.restorePlayer.isRunning();
};

PaymentsManager.prototype.isSubscriptionsAvailable = function () {
    if (connector.platform.oneOf(connector.ANDROID) && connector.payments.oneOf(connector.YOOKASSA)) {
        return false;
    }

    var plugin = connector.payments.getPlugin();
    if (plugin.isSubscriptionsAvailable) {
        return plugin.isSubscriptionsAvailable();
    }

    return connector.platform.oneOf(connector.OK, connector.ANDROID, connector.IOS, connector.MACOS, connector.TEST);
};

PaymentsManager.prototype.stopPurchasePlayer = function () {
    if (this.purchasePlayer) {
        this.purchasePlayer.stop();
        delete this.purchasePlayer;
    }
};

PaymentsManager.prototype.stopLoadProductsPlayer = function () {
    if (this.loadProductsPlayer) {
        this.loadProductsPlayer.stop();
        delete this.loadProductsPlayer;
    }
};

PaymentsManager.prototype.tryLoadProducts = function () {
    if (!connector.payments.isConnected()) {
        return;
    }

    if (this.loadProductsPlayer && this.loadProductsPlayer.isRunning()) {
        return;
    }

    this.loadProductsPlayer = new ConnectorActionPlayer({
        actionTimeout: connector.utils.parseInterval("15 seconds"),
        actions: [
            function (f, stop) {
                connector.payments.getPlugin().loadProducts(function (code) {
                    if (code === connector.CODE_SUCCEED) {
                        this.restore();

                        f();
                    } else {
                        stop();
                    }
                }.bind(this));
            }.bind(this),

            function (f, stop) {
                connector.payments.getPlugin().loadSubscriptions(function (code) {
                    if (code === connector.CODE_SUCCEED) {
                        f();
                    } else {
                        stop();
                    }
                });
            },

            function (f, stop) {
                connector.payments.getPlugin().loadSubscriptionsTokens(function (code) {
                    if (code === connector.CODE_SUCCEED) {
                        f();
                    } else {
                        stop();
                    }
                });
            }
        ]
    });

    this.loadProductsPlayer.play();
};

PaymentsManager.STATE_PURCHASE = "purchase";
PaymentsManager.STATE_VALIDATE = "validate";

PaymentsManager.PENDING_STORAGE_LIMIT = 30;
PaymentsManager.CONSUMED_STORAGE_LIMIT = 30;
