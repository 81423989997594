/**
 * Created by andrey on 25.11.19.
 */

var AdMobAds = function () {
    AdsPlugin.call(this, connector.AD_MOB, {
        cantLoadAndPlayInParallel: true,
        customImpressionEvent: true
    });

    this.onAdLoadedCallback = {};
    this._playCallback = function () {};
};

AdMobAds.prototype = Object.create(AdsPlugin.prototype);
AdMobAds.prototype.constructor = AdMobAds;

AdMobAds.prototype._connect = function (callback) {
    console.log("AdMobAds.init");

    var adMob = connector.config.debugMode ? connector.config.debug.adMob : AdMobAds.getConfig();
    var testDevices = connector.config.debugMode && adMob.devices && adMob.devices[connector.info.source] || [];

    connector.platform.callNative("AdMobPlugin.init", {
        adMob: adMob,
        isTest: Boolean(connector.config.debugMode),
        testDevices: testDevices.join(",")
    }, function () {
        console.log("AdMobAds.onInit");
        connector.platform.subscribeNative("AdMobAds", this.onNativeEvent.bind(this));
        callback(connector.STATUS_CONNECTED);
    }.bind(this));
};

AdMobAds.prototype.isRewardedConfigured = function () {
    var config = (connector.config.debugMode ? connector.config.debug.adMob : AdMobAds.getConfig()) || {};

    return config.rewarded;
};

AdMobAds.prototype.isInterstitialSupported = function () {
    var config = (connector.config.debugMode ? connector.config.debug.adMob : AdMobAds.getConfig()) || {};

    return config.interstitial;
};

AdMobAds.prototype._cacheAd = function (name, callback) {
    this.onAdLoadedCallback[name] = callback;

    if (name === AdsPlugin.REWARDED) {
        connector.platform.callNative("AdMobPlugin.cacheRewarded");
    } else {
        connector.platform.callNative("AdMobPlugin.cacheInterstitial");
    }
};

AdMobAds.prototype._playAd = function (name, ad, callback) {
    console.log("AdMobAds._playAd");
    this.success = false;
    this._playCallback = callback;

    connector.platform.callNative("AdMobPlugin.show", { name: name });
};

AdMobAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.AMAZON, connector.ANDROID, connector.IOS) && AdMobAds.getConfig();
};

AdMobAds.getConfig = function (source) {
    source = source || connector.info.source;

    if (connector.config[source] && connector.config[source].adMob) {
        return connector.config[source].adMob;
    }

    if (source === "amazon") {
        return AdMobAds.getConfig("android");
    }
};

AdMobAds.prototype.onNativeEvent = function (event, options) {
    console.log("AdMobAds.onNativeEvent - " + event);

    switch (event) {
        case "onPaidEvent":
            this.onImpressionEvent(options.name, options.cost || (connector.config.debugMode ? 0.000001 : 0));
            break;
        case "onAdOpened":
            break;
        case "onAdAlreadyRunning":
            if (options.name === AdsPlugin.REWARDED) {
                this.bus.trigger("error:showRewarded", ERRORS.SHOW_REWARDED.AD_ALREADY_RUNNING);
            } else {
                this.bus.trigger("error:showInterstitial", ERRORS.SHOW_INTERSTITIAL.AD_ALREADY_RUNNING);
            }
            break;
        case "onAdClosed":
            if (options.name === AdsPlugin.INTERSTITIAL) {
                this._playCallback(connector.CODE_SUCCEED);
            } else {
                this._playCallback(this.success ? connector.CODE_SUCCEED : ERRORS.SHOW_REWARDED.UNKNOWN);
            }

            break;
        case "onAdLoaded":
            this.onAdLoadResult(options.name, connector.CODE_SUCCEED);
            break;
        case "onAdAlreadyLoading":
            this.bus.trigger(options.name + ":cached", AdsPlugin.ERROR_LOADING_ALREADY_LOADING, this.pluginName);
            break;
        case "onAdFailedToLoad":
            this.onAdLoadResult(options.name, AdsPlugin.ERROR_LOADING_FAILED);
            break;
        case "onReward":
            this.success = true;
            break;
    }
};

AdMobAds.prototype.onAdLoadResult = function (name, code) {
    if (this.onAdLoadedCallback[name]) {
        this.onAdLoadedCallback[name](code, true);
        this.onAdLoadedCallback[name] = undefined;
    }
};

AdMobAds.prototype._askPermission = function (callback) {
    connector.platform.callNative("GDPRPlugin.askPermission", function (code) {
        console.log("GDPR setStatus " + code);
        callback();
    });
};
