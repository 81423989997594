/**
 * Created by andrey on 19.12.19.
 */

var NoPayments = function () {
    Payments.call(this, connector.NONE);
};

NoPayments.prototype = Object.create(Payments.prototype);
NoPayments.prototype.constructor = NoPayments;

NoPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.PLIEGA, connector.GPG, connector.TON, connector.GDCOM, connector.COOLMATHGAMES, connector.XIAOMI, connector.YOUTUBE, connector.GAMESNACK)
        || connector.platform.oneOf(connector.MOBAGE, connector.SP_MOBAGE) && ["mbga.jp:63100202", "sb.mbga.jp:63100202"].includes(connector.platform.getUserID());
};

NoPayments.prototype._connect = function (callback) {
    callback(connector.STATUS_DISABLED);
};
