/**
 * Created by Vladislav on 08.09.2024.
 */

/**
 * You can use connector.storage to save data to platform's cloud storage.
 *
 * ### Supported Platforms
 * <ul class="platforms-list">
 *   <li>
 *       ✅ <img src="images/vk.png"> VK Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/ok.png"> OK Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/yandex.png"> Yandex Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/instant.png"> Facebook Instant Games
 *   </li>
 * </ul>
 *
 * Check for support
 * ```
 * if (connector.storage.isSupported) {
 *   // storage supported
 * }
 * ```
 *
 * Storage data is loaded automatically, you can wait for readiness:
 * ```
 * await connector.storage.ready;
 *
 * // or via event;
 * connector.storage.on("ready", () => {});
 * ```
 *
 * ### Setting data
 * ```
 * // data you save must be a serializable object
 * const data = {
 *   foo: "bar"
 * }
 * // you can wait for data to be saved
 * const success = await connector.storage.set("key", data);
 *
 * if (success) {
 *   // data saved successfully to platform's cloud storage
 * }
 * ```
 *
 * ### Getting data
 * ```
 * const data = connector.storage.get("key");
 * ```
 *
 * ### List all saved keys
 * ```
 * const keys = connector.storage.getKeys();
 * ```
 *
 * ### Loading data
 * You can force loading from cloud storage, overriding current in-memory data
 * ```
 * const success = await connector.storage.load();
 * if (success) {
 *   // successfully loaded from cloud storage
 * }
 *
 * // or via event;
 * connector.storage.on("load", (success) => {});
 * ```
 *
 * @namespace Storage
 */

var StorageInterface = function () {
    return {
        /**
         * @type {Promise}
         * @memberof Storage
         * @desc Returns promise you can use to wait for storage init
         * @return {Promise}
         * */
        get ready() {
            return new Promise(function (resolve) {
                connector._platformStorage.whenReady(resolve);
            });
        },

        on: function (eventName, callback) {
            return connector._platformStorage.bus.on(eventName, callback);
        },

        once: function (eventName, callback) {
            return connector._platformStorage.bus.once(eventName, callback);
        },

        /**
         * @type {boolean}
         * @memberof Platform
         * @desc Whether cloud storage is supported by the platform
         * */
        get isSupported() {
            return connector._platformStorage.isSupported();
        },

        /**
         * @function set
         * @memberof Storage
         * @desc Set data for key
         * @param {string} key - Key
         * @param {object} data - An object to be saved to cloud storage. The object must contain only serializable values.
         * @return {Promise<boolean>}
         * */
        set: function (key, data) {
            return connector._platformStorage.set(key, data);
        },

        /**
         * @function get
         * @memberof Storage
         * @desc Get data for key
         * @param {string} key - Key
         * @return {object}
         * */
        get: function (key) {
            return connector._platformStorage.get(key);
        },

        /**
         * @function getKeys
         * @memberof Storage
         * @desc Returns list of all currently saved keys
         * @return {Array<string>}
         * */
        getKeys: function () {
            return connector._platformStorage.getKeys();
        },

        /**
         * @function load
         * @memberof Storage
         * @desc Load data from server and override local data
         * @return {Promise<boolean>}
         * */
        load: function () {
            return connector._platformStorage.load();
        }
    };
};