/**
 * Created by anatoly on 22/08/2023
 */

var TonSocial = function () {
    Social.call(this, connector.TON);
};

TonSocial.prototype = Object.create(Social.prototype);
TonSocial.prototype.constructor = TonSocial;

TonSocial.prototype._connect = function (callback) {
    connector.platform.callNative("getUser", {}, function (code, options) {
        this.id = "" + options.value.id;

        callback(code === connector.CODE_SUCCEED ? connector.STATUS_CONNECTED : connector.STATUS_DISCONNECTED);
    }.bind(this));
};

TonSocial.prototype._getUserID = function () {
    return this.id;
};

TonSocial.prototype._login = function (callback) {
    callback();
};

TonSocial.prototype.isLoggedIn = function () {
    return true;
};

TonSocial.prototype.getAccessToken = function () {
    return "ton";
};