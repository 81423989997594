/**
 * Created by Aleksandr on 01.02.2023
 */

var Kongregate = function () {
    Platform.call(this, connector.KONGREGATE, {
        sdk: SdkManager.SDK_KONGREGATE
    });
};

Kongregate.prototype = Object.create(Platform.prototype);
Kongregate.prototype.constructor = Kongregate;

Kongregate.prototype.getLocalStoragePrefix = function () {
    return "_kongregate";
};

Kongregate.prototype.callNative = function (apiMethod, params, callback) {
    console.log("[connector] callNative", apiMethod);

    ConnectorRestClient.get("https://api.kongregate.com/api/" + apiMethod, params, function (response) {
        if (response.error) {
            console.log("[connector] callNative error", apiMethod, response);
            callback(connector.CODE_FAILED, response);
        } else {
            console.log("[connector] callNative success", apiMethod, response);
            callback(connector.CODE_SUCCEED, response);
        }
    });
};
