/**
 * Created by slava on 4/21/17.
 */

var VKPayments = function () {
    RestPayments.call(this, connector.VK);
};

VKPayments.prototype = Object.create(RestPayments.prototype);
VKPayments.prototype.constructor = VKPayments;

VKPayments.prototype.getDefaultCurrency = function () {
    return "VK_GOLOS";
};

VKPayments.prototype.getTextPrice = function (price) {
    var ld = price % 10;
    if (ld === 1 && (price < 10 || price > 20)) {
        return price + " голос";
    }
    if (ld > 1 && ld < 5 && (price < 10 || price > 20)) {
        return price + " голоса";
    }
    return price + " голосов";
};

VKPayments.prototype.purchaseOpen = function (product, callback) {
    connector.platform.callNative("VKWebAppShowOrderBox", {
        type: "item",
        item: product.itemId + "_v2"
    }, function (code, data) {
        if (code === connector.CODE_SUCCEED) {
            var purchase = this.createPurchase(data.order_id, product);

            callback(connector.CODE_SUCCEED, purchase);
        } else if (data && data.error_data && data.error_data.error_reason === "User denied") {
            callback(ERRORS.PURCHASE.CANCEL);
        } else {
            callback(ERRORS.PURCHASE.UNKNOWN);

            this.bus.trigger("stream:error", "VKPayments.purchase productId: " + product.productId + " " + JSON.stringify(data));
        }
    }.bind(this));
};

VKPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.VK);
};