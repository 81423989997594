/**
 * Created by slava on 4/21/17.
 */

var MobileMyMailRuPayments = function () {
    MyMailRuPayments.call(this);
};

MobileMyMailRuPayments.prototype = Object.create(MyMailRuPayments.prototype);
MobileMyMailRuPayments.prototype.constructor = MobileMyMailRuPayments;

MobileMyMailRuPayments.prototype._connect = function (callback) {
    callback(connector.STATUS_CONNECTED);
};

MobileMyMailRuPayments.prototype.stopPurchaseActions = function () {
    RestPayments.prototype.stopPurchaseActions.apply(this, arguments);

    this.closeWidgetWindow();
    this.stopWidgetChecker();
};

MobileMyMailRuPayments.prototype.purchaseOpen = function (product, callback) {
    var request = connector.utils.getRequestParameters();
    var params = {
        appid: connector.config.mm.appId,
        session_key: request.session_key,
        service_id: product.productId,
        service_name: product.title,
        mailiki_price: this.getPriceToBePaid(product),
        mob: 1
    };

    var query = [];
    for (var key in params) {
        query.push(key + "=" + encodeURIComponent(params[key]));
    }

    this.openWidgetWindow("//m.my.mail.ru/cgi-bin/app/paymentm?" + query.join("&"));
    this.startWidgetChecker(product, callback);
};

MobileMyMailRuPayments.prototype.closeWidgetWindow = function () {
    if (this.widgetWindow) {
        this.widgetWindow.close();
        delete this.widgetWindow;
    }
};

MobileMyMailRuPayments.prototype.openWidgetWindow = function (query) {
    this.closeWidgetWindow();

    this.widgetWindow = window.open(query);
};

MobileMyMailRuPayments.prototype.stopWidgetChecker = function () {
    clearInterval(this.widgetChecker);
    delete this.widgetChecker;
};

MobileMyMailRuPayments.prototype.startWidgetChecker = function (product, callback) {
    this.stopWidgetChecker();

    var onWidgetProcessed = function () {
        this.closeWidgetWindow();
        this.stopWidgetChecker();

        if (this.getPurchaseState() === PaymentsManager.STATE_PURCHASE) {
            var purchase = this.createPurchase(undefined, product);

            this.startPurchasePinger(purchase, function (code) {
                callback(code ? ERRORS.PURCHASE.UNKNOWN : connector.CODE_SUCCEED, purchase);
            }, { timeout: 15000 });
        }
    }.bind(this);

    this.widgetChecker = setInterval(function () {
        try {
            var widgetWindow = this.widgetWindow;
            if (widgetWindow && widgetWindow.location
                && (!widgetWindow.location.href || widgetWindow.location.href.indexOf("vid=") >= 0)) {
                onWidgetProcessed();
            }
            // eslint-disable-next-line no-empty
        } catch (e) {
        }
    }.bind(this), 500);
};

MobileMyMailRuPayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.MM) && connector.info.os === connector.OS_IOS;
};
