/**
 * Created by slava on 4/21/17.
 */

var OKSocial = function () {
    Social.call(this, connector.OK, {
        sdk: SdkManager.SDK_OK
    });
};

OKSocial.prototype = Object.create(Social.prototype);
OKSocial.prototype.constructor = OKSocial;

OKSocial.prototype.isSupportsNativeShare = function () {
    return true;
};

OKSocial.prototype.isSupportsNativeInvite = function () {
    return true;
};

OKSocial.prototype.isSupportsNativeRequest = function () {
    return true;
};

OKSocial.prototype.isSupportsNativeCommunityJoin = function () {
    return true;
};

OKSocial.prototype.isLoggedIn = function () {
    return connector.social.isConnected();
};

OKSocial.prototype._getUserID = function () {
    if (!connector.social.isConnected()) {
        return;
    }

    return FAPI.Util.getRequestParameters().logged_user_id;
};

OKSocial.prototype.getAccessToken = function () {
    if (!connector.social.isConnected()) {
        return;
    }

    return "OK_" + FAPI.Util.getRequestParameters().session_key + "_" + FAPI.Util.getRequestParameters().auth_sig;
};

OKSocial.prototype._login = function (callback) {
    callback();
};

OKSocial.prototype.invite = function (data, callback) {
    connector.platform.getPlugin().listeners.showInvite = function (code, data) {
        // data === "noUsers"

        if (code !== OKSocial.CODE_SUCCEED) {
            callback(connector.CODE_CANCELLED, data);
            return;
        }

        callback(connector.CODE_SUCCEED);
    };

    FAPI.UI.showInvite(data.text);
};

OKSocial.prototype.request = function (data, callback) {
    if (typeof data.to === "object") {
        data.to = data.to.join(";");
    }

    connector.platform.getPlugin().listeners.showNotification = function (code, data) {
        if (code === "error" && data === "noUsers") {
            callback(connector.CODE_SUCCEED, []);
            return;
        }

        if (code !== OKSocial.CODE_SUCCEED) {
            callback(connector.CODE_CANCELLED, data);
            return;
        }

        data = data.split(",");

        callback(connector.CODE_SUCCEED, data);
    };

    FAPI.UI.showNotification(data.text, "", data.to);
};

OKSocial.prototype.share = function (data, callback) {
    var media = [
        {
            "type": "app",
            "text": data.text,
            "images": [{
                "url": data.image,
                "title": data.title
            }],
            "actions": [
                {
                    "text": data.title,
                    "mark": data.title
                }
            ]
        }
    ];

    // if (connector.config.debugMode) {
    //     console.log(JSON.stringify(media));
    // }

    connector.platform.getPlugin().listeners.postMediatopic = function (code, data) {
        if (code !== OKSocial.CODE_SUCCEED) {
            callback(connector.CODE_CANCELLED, data);
            return;
        }
        callback(connector.CODE_SUCCEED);
    };

    FAPI.UI.postMediatopic({
        "media": media
    }, true);
};

OKSocial.prototype.loadFriendsPerPage = function (ids, callback) {
    var MAX_FRIENDS = 1000;
    ids = ids.slice(0, MAX_FRIENDS);

    var chunkSize = 100;

    var chunks = [];
    for (var i = 0; i < ids.length; i += chunkSize) {
        chunks.push(ids.slice(i, i + chunkSize));
    }

    if (chunks.length === 0) {
        callback(connector.CODE_SUCCEED, []);
        return;
    }

    var waiting = chunks.length;
    var data = [];

    var onLoaded = function (code, dataChunk) {
        if (code === connector.CODE_SUCCEED) {
            data = data.concat(dataChunk);
        }
        waiting--;

        if (waiting === 0) {
            callback(connector.CODE_SUCCEED, data);
        }
    };

    chunks.forEach(function (chunk) {
        this.aboutUser(chunk, onLoaded);
    }.bind(this));
};

OKSocial.prototype._listFriends = function (callback) {
    FAPI.Client.call({
        method: "friends.getAppUsers"
    }, function (code, data, error) {
        if (code !== OKSocial.CODE_SUCCEED) {
            console.log("list friends error: " + error);
            callback(connector.CODE_CANCELLED);
            return;
        }

        var ids = data.uids;

        this.loadFriendsPerPage(ids, callback);
    }.bind(this));
};

OKSocial.prototype._listInvitableFriends = function (callback) {
    FAPI.Client.call({
        method: "friends.get",
        sort_type: "PRESENT"
    }, function (code, data, error) {
        if (code !== OKSocial.CODE_SUCCEED) {
            console.log("list ivitable friends error: " + error);
            callback(connector.CODE_CANCELLED);
            return;
        }

        var allIds = data;

        FAPI.Client.call({
            method: "friends.getAppUsers"
        }, function (code, data, error) {
            if (code !== OKSocial.CODE_SUCCEED) {
                console.log("list friends error: " + error);
                callback(connector.CODE_CANCELLED);
                return;
            }

            var inAppIds = data.uids;

            this.loadFriendsPerPage(connector.utils.substract(allIds, inAppIds), callback);
        }.bind(this));
    }.bind(this));
};

OKSocial.formatUserData = function (user) {
    // Replace https://i.mycdn.me/res/stub_128x128.gif & https://itest.mycdn.me/res/stub_128x128.gif for our own stub image
    if (user.pic128x128.indexOf("stub_128x128.gif") !== -1) {
        user.pic128x128 = "";
    }
    // https://api.ok.ru/img/stub/user/male/128.png
    if (user.pic128x128.indexOf("stub/user") !== -1) {
        user.pic128x128 = "";
    }

    return {
        id: user.uid,
        name: user.first_name + " " + user.last_name,
        firstName: user.first_name,
        lastName: user.last_name,
        avatar: user.pic128x128
    };
};

OKSocial.prototype._aboutUser = function (userId, callback) {
    var isSingle = !Array.isArray(userId);

    var onLoadedUser = function (code, data) {
        if (code !== OKSocial.CODE_SUCCEED) {
            callback(connector.CODE_FAILED);
            return;
        }

        var users = data.map(function (userData) {
            return OKSocial.formatUserData(userData);
        });

        if (isSingle) {
            callback(connector.CODE_SUCCEED, users[0]);
        } else {
            callback(connector.CODE_SUCCEED, users);
        }
    };

    if (!isSingle) {
        userId = userId.join(",");
    }

    FAPI.Client.call({
        uids: userId,
        fields: "first_name,last_name,uid,pic128x128",
        method: "users.getInfo"
    }, onLoadedUser);
};

OKSocial.prototype._aboutMe = function (callback) {
    // console.log('about me: ' + connector.platform.getUserID())
    this.aboutUser(connector.platform.getUserID(), callback);
};

OKSocial.prototype.canJoinCommunity = function (callback) {
    this.isInGroup(function (inGroup) {
        if (inGroup) {
            this.isMessagesAllowed(function (allowed) {
                callback(!allowed);
            });
        } else {
            callback(true);
        }
    }.bind(this));
};

OKSocial.prototype.joinCommunity = function (callback) {
    connector.platform.getPlugin().listeners.joinGroup = function (result) {
        callback(result === "ok" ? connector.CODE_SUCCEED : connector.CODE_FAILED);
    };

    FAPI.UI.joinGroup(connector.config.ok.groupId, true);
};

OKSocial.prototype.isMessagesAllowed = function (callback) {
    FAPI.Client.call({
        method: "group.isMessagesAllowed",
        gid: connector.config.ok.groupId
    }, function (code, data) {
        if (code !== "ok") {
            callback(false);

            return;
        }

        callback(data.allowed);
    });
};

OKSocial.prototype.isInGroup = function (callback) {
    FAPI.Client.call({
        method: "group.getUserGroupsV2"
    }, function (code, data) {
        var groups = code === "ok" && data && data.groups || [];

        var inGroup = false;
        for (var i = 0; i < groups.length; i++) {
            var group = groups[i];

            if (group.groupId === connector.config.ok.groupId) {
                inGroup = true;

                break;
            }
        }

        callback(inGroup);
    });
};

OKSocial.CODE_SUCCEED = "ok";
