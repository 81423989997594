/**
 * Created by Aleksandr on 02.02.2023
 */

var KongregatePayments = function () {
    RestPayments.call(this, connector.KONGREGATE, {
        sdk: SdkManager.SDK_KONGREGATE
    });
};

KongregatePayments.prototype = Object.create(RestPayments.prototype);
KongregatePayments.prototype.constructor = KongregatePayments;

KongregatePayments.prototype.getDefaultCurrency = function () {
    return "KG";
};

KongregatePayments.prototype.purchaseOpen = function (product, callback) {
    kongregate.mtx.purchaseItemsRemote(product.itemId, function (result) {
        if (result.success) {
            var purchase = this.createPurchase(undefined, product);

            callback(connector.CODE_SUCCEED, purchase);
        } else {
            this.bus.trigger("stream:error", "KongregatePayments.purchase productId:" + product.productId + " " + JSON.stringify(result));

            callback(ERRORS.PURCHASE.UNKNOWN);
        }
    }.bind(this));
};

KongregatePayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.KONGREGATE);
};
