/**
 * https://discord.com/developers/docs/activities/building-an-activity
 * https://discord.com/developers/docs/developer-tools/embedded-app-sdk
 * https://discord.com/developers/docs/interactions/application-commands
 *
 * Created by razial on 24.08.2024.
 */

var Discord = function () {
    Platform.call(this, connector.DISCORD, {
        sdk: SdkManager.SDK_DISCORD
    });
};

Discord.prototype = Object.create(Platform.prototype);
Discord.prototype.constructor = Discord;

Discord.prototype.openUrl = function (url) {
    discordSdk.commands.openExternalLink({
        url: url
    });
};

Discord.prototype.getSafePadding = function () {
    if (!this.safeAreaDiv) {
        this.safeAreaDiv = document.createElement("div");
        this.safeAreaDiv.style.position = "absolute";
        this.safeAreaDiv.style.top = "var(--discord-safe-area-inset-top, env(safe-area-inset-top))";
        this.safeAreaDiv.style.bottom = "var(--discord-safe-area-inset-bottom, env(safe-area-inset-bottom))";
        this.safeAreaDiv.style.left = "var(--discord-safe-area-inset-left, env(safe-area-inset-left))";
        this.safeAreaDiv.style.right = "var(--discord-safe-area-inset-right, env(safe-area-inset-right))";
        document.body.appendChild(this.safeAreaDiv);
    }

    var getStyle = function (prop) {
        if (typeof window.getComputedStyle !== "undefined") {
            return parseInt(window.getComputedStyle(this.safeAreaDiv).getPropertyValue(prop)) || 0;
        }
        if (typeof this.safeAreaDiv.currentStyle !== "undefined") {
            return parseInt(this.safeAreaDiv.currentStyle[prop]) || 0;
        }
        return 0;
    }.bind(this);

    var paddings = {
        top: getStyle("top"),
        bottom: getStyle("bottom"),
        left: getStyle("left"),
        right: getStyle("right")
    };

    if (connector.info.isMobile && connector.config.orientation === "horizontal" && [connector.OS_ANDROID, connector.OS_LINUX].indexOf(connector.info.os) !== -1) {
        paddings.top = 0;
        paddings.bottom = 0;
    }

    return paddings;
};