/**
 * Created by iamso on 24.01.19.
 */

var SPMobagePayments = function () {
    RestPayments.call(this, connector.SP_MOBAGE, {
        sdk: SdkManager.SDK_SPMOBAGE
    });
};

SPMobagePayments.prototype = Object.create(RestPayments.prototype);
SPMobagePayments.prototype.constructor = SPMobagePayments;

SPMobagePayments.prototype.getDefaultCurrency = function () {
    return "MC";
};

SPMobagePayments.prototype.getProductId = function (product) {
    return product.id.mbga;
};

SPMobagePayments.prototype.getTextPrice = function (price) {
    return connector.utils.formatAmount(price) + " MC";
};

SPMobagePayments.prototype.purchaseOpen = function (product, callback) {
    this.createTransaction(product, function (payment) {
        mobage.ui.open(
            "payment",
            {
                "transactionId": payment.transactionId,
                "orderId": payment.orderId
            },
            function (errorObj) {
                if (errorObj) {
                    this.reportPaymentResult(payment, errorObj);
                    mobage.bank.clearPaymentBacklog(payment.transactionId);

                    if (errorObj.error === "user_canceled") {
                        callback(ERRORS.PURCHASE.CANCEL);
                    } else {
                        this.bus.trigger("stream:error", "SPMobagePayments.purchase productId:" + product.productId + " " + JSON.stringify(errorObj));

                        callback(ERRORS.PURCHASE.UNKNOWN);
                    }
                } else {
                    this.reportPaymentResult(payment);

                    var purchase = this.createPurchase(payment.transactionId, product);

                    callback(connector.CODE_SUCCEED, purchase);
                }
            }.bind(this)
        );
    }.bind(this));
};

SPMobagePayments.prototype.createTransaction = function (product, callback) {
    connector.social.getPlugin().checkAccessToken(function () {
        var queryParams = {
            userId: connector.platform.getUserID().substring(connector.platform.getUserID().indexOf(":") + 1),
            id: product.productId,
            name: product.title,
            price: this.getPriceToBePaid(product),
            description: connector.info.language === "ja" ? product.description : product.description.substring(0, product.description.lastIndexOf("!")),
            imageUrl: connector.config.deployment + product.image,
            accessToken: connector.social.getPlugin().clientData.token.accessToken
        };

        ConnectorRestClient.get(
            "/spmbgapayments",
            {}, 
            function (response) {
                callback(response);
            }, 
            function () {},
            {
                queryParams: queryParams
            }
        );
    }.bind(this));
};

SPMobagePayments.prototype.reportPaymentResult = function (payment, error) {
    var queryParams = {
        orderId: payment.orderId,
        transactionId: payment.transactionId
    };

    if (error) {
        queryParams.error = true;
    }

    ConnectorRestClient.post("/spmbgapayments/old", {}, function (response) {
        if (response && response.transactionId) {
            mobage.bank.clearPaymentBacklog(response.transactionId);
        }
    }, function (error) {
        console.error(error);
    }, {
        queryParams: queryParams
    });
};

SPMobagePayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.SP_MOBAGE);
};
