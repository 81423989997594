/**
 * Created by vladislav on 9/6/2022
 */

var Mobage = function () {
    Platform.call(this, connector.MOBAGE);
};

Mobage.prototype = Object.create(Platform.prototype);
Mobage.prototype.constructor = Mobage;

Mobage.prototype.getLocalStoragePrefix = function () {
    return "_mbga";
};

Mobage.prototype.listSuggestedLanguages = function () {
    return [connector.LANGUAGE_JAPANESE];
};