/**
 * Created by Aleksandr on 06.10.2022.
 */

var Plinga = function () {
    Platform.call(this, connector.PLINGA, {
        sdk: SdkManager.SDK_PLINGA
    });
};

Plinga.prototype = Object.create(Platform.prototype);
Plinga.prototype.constructor = Plinga;

Plinga.prototype._connect = function (callback) {
    // eslint-disable-next-line no-unused-vars, camelcase
    plingaRpc_pauseGame = this.pause.bind(this);

    // eslint-disable-next-line no-unused-vars, camelcase
    plingaRpc_resumeGame = this.resume.bind(this);

    plingaRpc.setAllowFullscreen();

    callback(connector.STATUS_CONNECTED);
};

Plinga.prototype.getLocalStoragePrefix = function () {
    return "_plinga";
};