/**
 * Created by andrey on 31.01.2024
 */

var MultiAdsPlugin = function () {
    this.plugins = {
        msstart: new MSStartAds(),
        google: new GoogleWebAds()
    };

    AdsPlugin.call(this, connector.MULTI_ADS, {
        cantLoadAndPlayInParallel: true
    });

    Object.values(this.plugins).forEach(function (plugin) {
        AdsPlugin.TYPES.forEach(function (type) {
            plugin.bus.on(type + ":caching", function (plugin) {
                this.bus.trigger(type + ":caching", plugin);
            }.bind(this));

            plugin.bus.on(type + ":cached", function (code, plugin) {
                this.bus.trigger(type + ":cached", code, plugin);
            }.bind(this));

            plugin.bus.on(type + ":impression", function (impressionCost, plugin) {
                this.bus.trigger(type + ":impression", impressionCost, plugin);
            }.bind(this));

            plugin.bus.on(type + ":refresh", function () {
                this.bus.trigger(type + ":refresh");
            }.bind(this));
        }.bind(this));
    }.bind(this));
};

MultiAdsPlugin.prototype = Object.create(AdsPlugin.prototype);
MultiAdsPlugin.prototype.constructor = MultiAdsPlugin;

MultiAdsPlugin.prototype.code = MultiAdsPlugin.code = "MultiAdsPlugin";

MultiAdsPlugin.prototype._connect = function (callback) {
    callback = connector.utils.once(callback);

    var plugins = Object.values(this.plugins);
    var counter = plugins.length;

    var onStatusChanged = function (status) {
        counter--;

        if (status === connector.STATUS_CONNECTED) {
            callback(status);
            return;
        }

        if (counter === 0) {
            callback(connector.STATUS_DISCONNECTED);
        }
    };

    plugins.forEach(function (plugin) {
        plugin.bus.once("changeStatus", onStatusChanged);
        plugin.askPermission(function () {
            plugin.connect();
        });
    });
};

MultiAdsPlugin.prototype.cacheAdInner = function (name, callback) {
    this.setLoaderState(name, AdsPlugin.LOADER_STATE.LOADING);

    var plugins = Object.values(this.plugins);
    var counter = plugins.length;

    var onCompleted = connector.utils.once(function (code) {
        if (code === connector.CODE_SUCCEED) {
            this.cached[name] = true;
            this.setLoaderState(name, AdsPlugin.LOADER_STATE.READY);
        } else {
            this.cachedErrorTime[name] = Date.now();
            this.setLoaderState(name, AdsPlugin.LOADER_STATE.IDLE);
        }

        callback(code);
    }.bind(this));

    Object.values(this.plugins).forEach(function (plugin) {
        plugin.cacheAd(name, function (code) {
            counter--;

            if (code === connector.CODE_SUCCEED) {
                onCompleted(code);
                return;
            }

            if (counter === 0) {
                onCompleted(connector.CODE_FAILED);
            }
        });
    });
};

MultiAdsPlugin.prototype.playAd = function (name, callback) {
    callback = callback || function () {};

    var pluginNames = this.getPlayReadyPluginCodes(name);
    if (pluginNames.length === 0) {
        if (name === AdsPlugin.REWARDED) {
            callback(ERRORS.SHOW_REWARDED.NO_ADS);
        } else {
            callback(ERRORS.SHOW_INTERSTITIAL.NO_ADS);
        }
        return;
    }

    this.cached[name] = undefined;
    this.cachedErrorTime[name] = undefined;

    this.setLoaderState(name, AdsPlugin.LOADER_STATE.IDLE);
    this.setVideoState(name, AdsPlugin.VIDEO_STATE.PLAYING);

    this.bus.trigger(name + ":start", this.pluginName);

    var playNext = function () {
        var plugin = this.plugins[pluginNames.shift()];

        console.log("Play " + plugin.pluginName);

        this.playCallbacks[name] = connector.utils.once(function (code) {
            this.playCallbacks[name] = undefined;

            if (code !== connector.CODE_SUCCEED && pluginNames.length > 0) {
                playNext();
                return;
            }

            this.finishTime[name] = Date.now();

            this.setVideoState(name, AdsPlugin.VIDEO_STATE.IDLE);

            var success = code === connector.CODE_SUCCEED;

            this.bus.trigger(name + ":close", success, plugin.pluginName);
            if (name === AdsPlugin.REWARDED && success) {
                this.bus.trigger("rewarded:reward", plugin.pluginName);
            }

            callback(code);
        }.bind(this));

        plugin.playAd(name, this.playCallbacks[name]);
    }.bind(this);

    playNext();
};

MultiAdsPlugin.prototype.getPlayReadyPluginCodes = function (name) {
    return Object.keys(this.plugins).filter(function (code) {
        var plugin = this.plugins[code];
        return plugin.isConnected() && plugin.isAdCached(name);
    }, this);
};

MultiAdsPlugin.prototype.isStickySupported = function () {
    return this.plugins.google.isStickySupported();
};

MultiAdsPlugin.prototype.isStickyAvailable = function () {
    return this.plugins.google.isStickyAvailable();
};

MultiAdsPlugin.prototype.showSticky = function (callback) {
    this.plugins.google.showSticky(callback);
};

MultiAdsPlugin.prototype.closeSticky = function (callback) {
    this.plugins.google.closeSticky(callback);
};

MultiAdsPlugin.isAppropriate = function () {
    return MSStartAds.isAppropriate() && GoogleWebAds.isAppropriate();
};
