/**
 * Created by vladislav on 9/6/2022
 */

var SPMobage = function () {
    Platform.call(this, connector.SP_MOBAGE, {
        sdk: SdkManager.SDK_SPMOBAGE
    });

    connector.info.isInApp = true;
    connector.info.isMobile = true;

    connector.platform.whenConnected(this.createHeaderAndFooter.bind(this));
};

SPMobage.prototype = Object.create(Platform.prototype);
SPMobage.prototype.constructor = SPMobage;

SPMobage.prototype.getLocalStoragePrefix = function () {
    return "_sp_mbga";
};

SPMobage.prototype.listSuggestedLanguages = function () {
    return [connector.LANGUAGE_JAPANESE];
};

SPMobage.prototype.createHeaderAndFooter = function () {
    var getContentHtml = function () {
        var styles = "style='display: table-cell;\n"
            + "width: 25%;\n"
            + "text-align: center;\n"
            + "vertical-align: middle;\n"
            + "border-right: 1px solid #5f5f5f;\n"
            + "font-size: 80%;\n"
            + "color: #5f5f5f;\n"
            + "line-height: normal;\n"
            + "font-weight: bold;'";

        return "<div " + styles + " onclick='mobage.ui.show(\"portal\", {});'>Mobage</div>"
            + "<div " + styles + " onclick='mobage.ui.show(\"inquiry\", {});'>お問い合わせ</div>"
            + "<div " + styles + " onclick='mobage.ui.open(\"client_configuration\", {}, function() {});'>設定変更</div>"
            + "<div " + styles + " onclick='mobage.ui.show(\"commercial_transaction\");'>特定商取引法</div>";
    };

    var footer = document.createElement("div");
    footer.style.position = "absolute";
    footer.style.zIndex = "2";
    footer.style.bottom = "0";
    footer.style.height = "27px";
    footer.style.width = "100%";
    footer.style.background = "#e3e3e3";
    footer.style.display = "table";
    footer.innerHTML = getContentHtml();

    document.body.appendChild(footer);
};
