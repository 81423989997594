/**
 * Created by slava on 4/21/17.
 */

var MobagePayments = function () {
    RestPayments.call(this, connector.MOBAGE);
};

MobagePayments.prototype = Object.create(RestPayments.prototype);
MobagePayments.prototype.constructor = MobagePayments;

MobagePayments.prototype.getDefaultCurrency = function () {
    return "MC";
};

MobagePayments.prototype.getProductId = function (product) {
    return product.id.mbga;
};

MobagePayments.prototype.getTextPrice = function (price) {
    return connector.utils.formatAmount(price) + " MC";
};

MobagePayments.prototype.purchaseOpen = function (product, callback) {
    var purchase = this.createPurchase(undefined, product);

    var itemParams = {};
    itemParams[opensocial.BillingItem.Field.SKU_ID] = product.productId;
    itemParams[opensocial.BillingItem.Field.PRICE] = purchase.price;
    itemParams[opensocial.BillingItem.Field.COUNT] = 1;
    itemParams[mbga.BillingItem.Field.NAME] = product.title;
    itemParams[mbga.BillingItem.Field.IMAGE_URL] = connector.platform.getExternalUrl(product.image);
    var item = opensocial.newBillingItem(itemParams);

    var params = {};
    params[opensocial.Payment.Field.ITEMS] = [item];
    params[opensocial.Payment.Field.AMOUNT] = itemParams[opensocial.BillingItem.Field.PRICE] * itemParams[opensocial.BillingItem.Field.COUNT];
    var payment = opensocial.newPayment(params);

    opensocial.requestPayment(payment, function (response) {
        if (response.hadError()) {
            callback(ERRORS.PURCHASE.UNKNOWN);
        } else {
            console.log("opensocial.requestPayment response", response.getData());
            callback(connector.CODE_SUCCEED, purchase);
        }
    });
};

MobagePayments.isAppropriate = function () {
    return connector.platform.oneOf(connector.MOBAGE);
};