/**
 * Created by Andrey Popov on 03.11.2023
 */

var Crazy = function () {
    Platform.call(this, connector.CRAZY, {
        sdk: SdkManager.SDK_CRAZY
    });

    connector.social.needWindowForLogin = true;
};

Crazy.prototype = Object.create(Platform.prototype);
Crazy.prototype.constructor = Crazy;

Crazy.prototype._connect = function (callback) {
    CrazyGames.SDK.game.sdkGameLoadingStart();

    CrazyGames.SDK.user.isUserAccountAvailable(function (error) {
        if (error) {
            callback(connector.STATUS_DISCONNECTED);
        } else {
            callback(connector.STATUS_CONNECTED);
        }
    });
};

Crazy.prototype.startGame = function (callback) {
    connector.platform.whenConnected(function () {
        CrazyGames.SDK.game.sdkGameLoadingStop();
    });

    callback();
};

Crazy.prototype.gameplayStart = function () {
    connector.platform.whenConnected(function () {
        CrazyGames.SDK.game.gameplayStart();
    });
};

Crazy.prototype.gameplayStop = function () {
    connector.platform.whenConnected(function () {
        CrazyGames.SDK.game.gameplayStop();
    });
};

Crazy.prototype.getLocalStoragePrefix = function () {
    return "_crazy";
};