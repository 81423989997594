/**
 * Created by vladislav on 4/4/2024
 */

/**
 * Information about system and platform
 *
 * @namespace Info
* */

var InfoInterface = function () {
    return {
        on: function (eventName, callback) {
            Info.bus.on(eventName, callback);
        },
        /**
         * @type {String}
         * @memberof Info
         * @desc Current platform type
         * */
        source: "",
        /**
         * @type {Boolean}
         * @memberof Info
         * @desc Whether on mobile device
         * */
        isMobile: false,
        /**
         * @type {Boolean}
         * @memberof Info
         * @desc Whether on native
         * */
        isNative: false,
        /**
         * @type {String}
         * @memberof Info
         * @desc OS name
         * */
        os: Info._detectOS(),
        /**
         * @type {Boolean}
         * @memberof Info
         * @desc Whether in app
         * */
        isInApp: false,
        /**
         * @type {String}
         * @memberof Info
         * @desc Player's current language
         * */
        language: Info._detectLanguage(),
        /**
         * @type {String}
         * @memberof Info
         * @desc Device name
         * */
        deviceName: "",
        /**
         * @type {String}
         * @memberof Info
         * @desc Device version
         * */
        deviceVersion: "",
        /**
         * @type {String}
         * @memberof Info
         * @desc Device ID
         * */
        deviceID: "",
        /**
         * @function changeLanguage
         * @memberof Info
         * @param {String} language - Language code, e.g. "en", "ru"
         * @desc Change player's current language
         * */
        changeLanguage: function (language) {
            connector.info.language = language;
        }
    };
};

connector.OS_UNKNOWN = "Unknown";
connector.OS_WINDOWS = "Windows";
connector.OS_IOS = "iOS";
connector.OS_OSX = "OS X";
connector.OS_UNIX = "Unix";
connector.OS_ANDROID = "Android";
connector.OS_LINUX = "Linux";

connector.LANGUAGE_ENGLISH = "en";
connector.LANGUAGE_CHINESE = "zh";
connector.LANGUAGE_FRENCH = "fr";
connector.LANGUAGE_ITALIAN = "it";
connector.LANGUAGE_GERMAN = "de";
connector.LANGUAGE_SPANISH = "es";
connector.LANGUAGE_DUTCH = "nl";
connector.LANGUAGE_RUSSIAN = "ru";
connector.LANGUAGE_KOREAN = "ko";
connector.LANGUAGE_JAPANESE = "ja";
connector.LANGUAGE_HUNGARIAN = "hu";
connector.LANGUAGE_PORTUGUESE = "pt";
connector.LANGUAGE_ARABIC = "ar";
connector.LANGUAGE_NORWEGIAN = "no";
connector.LANGUAGE_POLISH = "pl";
connector.LANGUAGE_LATVIAN = "lv";
connector.LANGUAGE_TURKISH = "tr";